import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(private authService: AuthService) {}

  getSocket(namespace: string) {
    const url = `${environment.socketUrl}${namespace}`;
    const authTokenString = `Bearer ${this.authService.getAuthorizationToken()}`;
    return io(url, {
      transports: ['websocket'],
      withCredentials: true,
      auth: {
        token: authTokenString,
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: authTokenString,
          },
        },
      },
    });
  }
}

import { AppointmentReasonType } from './appointment';

export enum AssignmentStatus {
  assignedToMe = 'Assigned to me',
  assignedToOther = 'Assigned to other',
  unassigned = 'Unassigned',
  completed = 'Completed',
}
export enum ScheduleRequestTabs {
  open = 'Open',
  assigned = 'Assigned',
  myRequests = 'My Requests',
  unassigned = 'Unassigned',
  completed = 'Completed',
  rejected = 'Rejected',
}
export enum VisitPriority {
  ACUTE_0_24_HOURS = 'ACUTE_0_24_HOURS',
  ACUTE_24_72_HOURS = 'ACUTE_24_72_HOURS',
  CHRONIC_4_7_DAYS = 'CHRONIC_4_7_DAYS',
  CHRONIC_7_14_PLUS_DAYS = 'CHRONIC_7_14_PLUS_DAYS',
  UNSPECIFIED = 'UNSPECIFIED',
}
export const VisitPriorityMapping = {
  [VisitPriority.ACUTE_0_24_HOURS]: 'Acute 1 (0-24 hours)',
  [VisitPriority.ACUTE_24_72_HOURS]: 'Acute 2 (24-72 hours)',
  [VisitPriority.CHRONIC_4_7_DAYS]: 'Chronic 1 (4-7 days)',
  [VisitPriority.CHRONIC_7_14_PLUS_DAYS]: 'Chronic 2 (7-14+ days)',
  [VisitPriority.UNSPECIFIED]: 'Unspecified',
};
export function determineVisitType(visitPriority: VisitPriority): AppointmentReasonType {
  if (visitPriority === VisitPriority.CHRONIC_4_7_DAYS) {
    return AppointmentReasonType.chronic;
  } else if (visitPriority === VisitPriority.CHRONIC_7_14_PLUS_DAYS) {
    return AppointmentReasonType.chronic;
  } else {
    return AppointmentReasonType.acute;
  }
}

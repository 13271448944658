import { Margins } from 'pdfmake/interfaces';
import { Alignment, FontSize, TableColumnWidthOption, TextObject, TextStyleObject } from './assets';
import { NicePdfGenerator, NicePdfGenerator as pdf } from './nice-pdf-generator';
import { Allergy, Icd10Entry, Imaging, Lab, MedHistoryMedication, PatientAddressDto } from '../../dto';
import { AllergyReaction, AllergySeverity, AllergyType, ReferralTypeKeys, Urgency } from '../../enums';

export interface ReferralPayload {
  patient: {
    firstName: string;
    lastName: string;
    dob: string;
    address: PatientAddressDto;
    phone: string;
    email: string;
    id?: number;
  };
  provider: {
    firstName: string;
    lastName: string;
    credentials: string[];
    npiNumber: string;
    id?: number;
  };
  todaysDate: string;
  referralType: ReferralTypeKeys;
  referralReason: string;
  assessments: Icd10Entry[];
  assessmentNote: string;
  labs: Lab[];
  labsNote: string;
  imaging: Imaging[];
  imagingNote: string;
  medications: MedHistoryMedication[];
  medicationNote: string;
  allergies: Allergy[];
  allergyNote: string;
  additionalInformation: string;

  signatureBase64?: string;
}

export class ReferralPdf extends pdf {
  /**
   * Defines the referral document
   * @param data
   * patient (firstName, lastName, dob, address, phone, email),
   * provider(firstName, lastName, credentials, npiNumber)
   * todaysDate, referralReason, assessments assessmentNote,
   * labs, labsNote, imaging, imagingNote, medications,
   * medicationNote, allergies, allergyNotes,
   * additionalInformation, signatureBase64
   */
  public define(data: ReferralPayload): void {
    const referralDefaultMargins: {
      subheads: [number, number, number, number];
      detailBlocks: [number, number, number, number];
    } = {
      subheads: [0, 18, 0, 0],
      detailBlocks: [0, 0, 0, 18],
    };

    const tableHeaderStyle = {
      fontSize: FontSize.Default,
    };
    const tableBodyStyle = {
      fontSize: FontSize.Default,
    };

    const patientName = `${data.patient.firstName} ${data.patient.lastName}`;
    const providerName = `${data.provider.firstName} ${data.provider.lastName}`;
    const credentialsString =
      data.provider.credentials && data.provider.credentials.length ? `, ${data.provider.credentials.join(', ')}` : '';
    let providerSignatureArray: (string | TextObject)[] = [`${providerName}${credentialsString}\n`];
    if (data.provider.npiNumber) {
      providerSignatureArray = providerSignatureArray.concat([{ text: 'NPI: ', bold: true }, data.provider.npiNumber]);
    }

    const referralFor = data.referralType;
    const address = data.patient.address;
    const patientAddressArray: (string | TextObject)[] = [];
    if (address) {
      patientAddressArray.push({ text: 'Home Street:', bold: true });
      patientAddressArray.push(` ${address.address1}\n`);
      if (address.address2) {
        patientAddressArray.push({ text: 'Home Address 2:', bold: true });
        patientAddressArray.push(` ${address.address2}\n`);
      }
      patientAddressArray.push({ text: 'City, State, ZIP:', bold: true });
      patientAddressArray.push(` ${address.city}, ${address.state} ${address.zip}`);
    }

    let formattedAllergies: {
      allergySource?: string | null;
      allergyType?: string | null;
      reaction?: string | null;
      severity?: string | null;
    }[] = [];
    if (data.allergies.length) {
      formattedAllergies = data.allergies.map((allergy: Allergy) => {
        const allergyType = allergy.allergyType ? (<any>AllergyType)[allergy.allergyType] : undefined;
        const reaction = allergy.reaction ? (<any>AllergyReaction)[allergy.reaction] : undefined;
        const severity = allergy.severity ? (<any>AllergySeverity)[allergy.severity] : undefined;
        return {
          ...allergy,
          allergyType,
          reaction,
          severity,
        };
      });
    }

    let formattedImaging: {
      label?: string | null;
      cptCode?: string | null;
      urgency?: string | null;
    }[] = [];
    if (data.imaging.length) {
      formattedImaging = data.imaging.map((imagingOrder) => {
        const urgency = imagingOrder.urgency ? (<any>Urgency)[imagingOrder.urgency] : undefined;
        return {
          ...imagingOrder,
          urgency,
        };
      });
    }

    const titleText = `ReferralFor${referralFor}_${patientName}_${data.todaysDate}`.replace(' ', '');

    this.setInfo({
      title: titleText,
      author: providerName,
      subject: 'Referral',
    });

    this.docConfiguration.footer = NicePdfGenerator.getDynamicFooterContent(patientName, data.patient.dob);

    // Add Nice contact info
    this.addHeaderContent({
      text: 'Nice Healthcare',
      alignment: Alignment.Left,
      fontSize: FontSize.Label,
      margin: referralDefaultMargins.subheads,
    });
    const mixedContent: (string | TextObject)[] = [
      { text: 'Phone:', bold: true },
      ' 763-412-1993\n',
      { text: 'Fax:', bold: true },
      ' 888-972-8341\n',
      'www.nice.healthcare',
    ];
    this.addMixedStyleParagraphContent(mixedContent, referralDefaultMargins.detailBlocks);

    // Add date line
    this.addParagraphContent({
      text: data.todaysDate,
    });

    // Add main header
    this.addHeaderContent({
      text: 'Referral Order',
      alignment: Alignment.Left,
      fontSize: FontSize.Medium,
    });

    // Add patient information
    this.addHeaderContent({
      text: 'Patient information',
      alignment: Alignment.Left,
      fontSize: FontSize.Label,
      margin: referralDefaultMargins.subheads,
    });
    const mixedPatientContent: (string | TextObject)[] = [
      { text: 'Name:', bold: true },
      ` ${patientName}\n`,
      { text: 'DOB:', bold: true },
      ` ${data.patient.dob}\n`,
      { text: 'Phone:', bold: true },
      ` ${data.patient.phone}\n`,
      { text: 'Email:', bold: true },
      ` ${data.patient.email}\n`,
      ...patientAddressArray,
    ];
    this.addMixedStyleParagraphContent(mixedPatientContent, referralDefaultMargins.detailBlocks);

    // Add referral reason
    this.addHeaderContent({
      text: 'Reason for referral',
      alignment: Alignment.Left,
      fontSize: FontSize.Label,
      margin: referralDefaultMargins.subheads,
    });
    this.addParagraphContent({
      text: data.referralReason,
    });

    // Add assessments
    if (data.assessments.length || data.assessmentNote) {
      this.addHeaderContent({
        text: 'Assessment',
        alignment: Alignment.Left,
        fontSize: FontSize.Label,
        margin: referralDefaultMargins.subheads,
      });
    }
    if (data.assessments.length) {
      this.addTableContent({
        columnHeaders: ['ICD-10 Code', 'Description'],
        columnKeys: ['code', 'description'],
        columnWidths: ['*', 'auto'],
        tableData: data.assessments,
        headerStyle: tableHeaderStyle,
        bodyStyle: tableBodyStyle,
      });
    }
    if (data.assessmentNote) {
      this.addParagraphContent({
        text: data.assessmentNote,
      });
    }

    // Add lab orders
    if (data.labs.length || data.labsNote) {
      this.addHeaderContent({
        text: 'Lab orders',
        alignment: Alignment.Left,
        fontSize: FontSize.Label,
        margin: referralDefaultMargins.subheads,
      });
    }
    if (data.labs.length) {
      this.addTableContent({
        columnHeaders: ['Lab Name', 'Quest Code'],
        columnKeys: ['label', 'code'],
        columnWidths: ['*', 'auto'],
        tableData: data.labs,
        headerStyle: tableHeaderStyle,
        bodyStyle: tableBodyStyle,
      });
    }
    if (data.labsNote) {
      this.addParagraphContent({
        text: data.labsNote,
      });
    }

    // Add imaging orders
    if (formattedImaging.length || data.imagingNote) {
      this.addHeaderContent({
        text: 'Imaging orders',
        alignment: Alignment.Left,
        fontSize: FontSize.Label,
        margin: referralDefaultMargins.subheads,
      });
    }
    if (formattedImaging.length) {
      this.addTableContent({
        columnHeaders: ['Imaging Name', 'CPT Code', 'Urgency'],
        columnKeys: ['label', 'cptCode', 'urgency'],
        columnWidths: ['*', 'auto', 'auto'],
        tableData: formattedImaging,
        headerStyle: tableHeaderStyle,
        bodyStyle: tableBodyStyle,
      });
    }
    if (data.imagingNote) {
      this.addParagraphContent({
        text: data.imagingNote,
      });
    }

    // Add patient medications
    if (data.medications.length || data.medicationNote) {
      this.addHeaderContent({
        text: 'Patient medications',
        alignment: Alignment.Left,
        fontSize: FontSize.Label,
        margin: referralDefaultMargins.subheads,
      });
    }
    if (data.medications.length) {
      const medsTableDef = this.buildMedicationTableDefinition(data.medications, tableHeaderStyle, tableBodyStyle);
      this.addTableContent(medsTableDef);
    }
    if (data.medicationNote) {
      this.addParagraphContent({
        text: data.medicationNote,
      });
    }

    // Add patient allergies
    if (formattedAllergies.length || data.allergyNote) {
      this.addHeaderContent({
        text: 'Patient allergies',
        alignment: Alignment.Left,
        fontSize: FontSize.Label,
        margin: referralDefaultMargins.subheads,
      });
    }
    if (formattedAllergies.length) {
      this.addTableContent({
        columnHeaders: ['Allergy', 'Type', 'Reaction', 'Severity'],
        columnKeys: ['allergySource', 'allergyType', 'reaction', 'severity'],
        columnWidths: ['*', 'auto', 'auto', 'auto'],
        tableData: formattedAllergies,
        headerStyle: tableHeaderStyle,
        bodyStyle: tableBodyStyle,
      });
    }
    if (data.allergyNote) {
      this.addParagraphContent({
        text: data.allergyNote,
      });
    }

    // Add additional information
    if (data.additionalInformation) {
      this.addHeaderContent({
        text: 'Additional information',
        alignment: Alignment.Left,
        fontSize: FontSize.Label,
        margin: referralDefaultMargins.subheads,
      });
      this.addParagraphContent({
        text: data.additionalInformation,
      });
    }

    const marginSet = [0, 8, 0, 20] as Margins;

    // Add sign off
    this.addParagraphContent({ text: 'Respectfully,' });

    // Add the signature
    if (data.signatureBase64) {
      this.addImageContent({
        base64: data.signatureBase64,
        maxHeight: 100,
        maxWidth: 300,
      });
    }

    // Text of provider name and credentials follows signature image
    this.addMixedStyleParagraphContent(providerSignatureArray);
  }

  buildMedicationTableDefinition(
    medications: MedHistoryMedication[],
    headerStyle: TextStyleObject,
    bodyStyle: TextStyleObject,
  ) {
    const columnHeaders = ['Medication', 'Refills', 'Start Date', 'End Date', 'Instructions'];
    const columnKeys = ['name', 'refills', 'startDate', 'endDate', 'instructions'];
    const columnWidths: TableColumnWidthOption[] = ['auto', 'auto', 'auto', 'auto', '*'];

    return {
      headerStyle,
      bodyStyle,
      columnHeaders,
      columnKeys,
      columnWidths,
      tableData: medications.map((medication: MedHistoryMedication) => ({
        ...medication,
        name: medication.name || '',
        refills: medication.refills || '',
        startDate: medication.startDate || '',
        endDate: medication.endDate || '',
        instructions: medication.sigNote || '',
      })),
    };
  }
}

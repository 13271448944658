import { MentalStatusConfiguration } from '../../../constants/chart-form-configuration';
import { PatientChartPayload } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';
import { sectionHasContent } from './treatment-plan-utility-functions';
import { ChartMentalStatusGroup } from '../../../dto';
import { get } from 'lodash';

interface SectionGroup {
  [key: string]: boolean;
}

export function treatmentPlanMentalStatus(pdf: TreatmentPlan, chart: PatientChartPayload) {
  if (
    !sectionHasContent(
      chart,
      'mentalStatus',
      ['isHealthy'],
      (mentalStatus: ChartMentalStatusGroup) => mentalStatus.isHealthy
    )
  ) {
    return;
  }
  const data = chart.mentalStatus;

  pdf.addHeaderContent({
    text: 'Mental Status',
  });

  if (data.isHealthy) {
    pdf.addParagraphContent({ text: 'Mental Status is Healthy/WNL' });
  } else {
    const tableData = MentalStatusConfiguration
      // Gets rid of sections not yet filled out
      .filter((config) => {
        const value = !!get(data, config.formControlName);
        const note = !!get(data, `${config.formControlName}Note`);
        return value || note;
      })
      .map((config) => {
        const section = (data as any)[config.formControlName] as SectionGroup;
        const value =
          typeof section !== 'object' || section === null
            ? null
            : Object.entries(section)
                // Filter out empty sections with no notes
                .filter(([key, value]) => {
                  return !!value;
                })
                // Return back the string value that is the name of the enum value key
                .map(([key, value]) => (config.options as any)[key])
                // Represent those keys as one joined value
                .join(', ');

        // Returning in shape that the pdf details section expects
        return {
          section: config.heading,
          observation: value,
          notes: get(data, `${config.formControlName}Note`) || null,
        };
      });

    if (tableData.length > 0) {
      pdf.addTableContent({
        columnHeaders: ['Section', 'Observation', 'Notes'],
        columnKeys: ['section', 'observation', 'notes'],
        columnWidths: ['auto', '*', 'auto'],
        tableData,
      });
    }

    if (data.other) {
      pdf.addParagraphContent({
        title: 'Other',
        text: data.other,
      });
    }
  }
}

export enum ChangeHealthcareActionCommandOptions {
  Search = 'Search',
}

export enum ChangeHealthcareTargetOptions {
  PatientLookup = 'jsp/lab/person/PatientLookup.jsp',
}

export enum ChangeHealthcareSAMLOptions {
  SAML = 'SAML',
  SAML2 = 'SAML2',
}

import React, { PropsWithChildren, useMemo } from 'react';
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { Check, Close, ContentCopy, Error } from '@mui/icons-material';
import { Patient } from '@models';
import { GenderName, Race, SexAssignedAtBirth } from '@enums';
import { AlertService } from '../../../core/services/alert.service';

export type PatientInfoSummaryProps = PropsWithChildren<{
  alertService: AlertService;
  patient: Patient;
  capsuleEligibilityCheckInProgress: boolean;
  capsuleDeliveryAvailable: boolean;
}>;

const standardDateFormat = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: 'UTC',
});

export function PatientInfoSummary({
  alertService,
  patient,
  capsuleEligibilityCheckInProgress,
  capsuleDeliveryAvailable,
}: PatientInfoSummaryProps): React.JSX.Element {
  const displayAge = useMemo((): string => {
    const birthDate = new Date(patient.dateOfBirth);
    if (!isDateValid(birthDate)) {
      return patient.dateOfBirth?.toString();
    }

    const stringArray = [];

    const currentDate = new Date();
    const years = currentDate.getUTCFullYear() - birthDate.getUTCFullYear();
    let hasHadBirthday = false;

    if (currentDate.getUTCMonth() > birthDate.getUTCMonth()) {
      hasHadBirthday = true;
    } else if (
      currentDate.getUTCMonth() === birthDate.getUTCMonth() &&
      currentDate.getUTCDate() >= birthDate.getUTCDate()
    ) {
      hasHadBirthday = true;
    }

    const yearNum = hasHadBirthday ? years : years - 1;
    if (yearNum > 0) {
      stringArray.push(`${yearNum} Year`);
    }

    const months =
      currentDate.getUTCMonth() <= birthDate.getUTCMonth()
        ? 11 - (birthDate.getUTCMonth() - currentDate.getUTCMonth())
        : currentDate.getUTCMonth() - birthDate.getUTCMonth();

    const monthNum = months === 11 && hasHadBirthday ? 0 : months;
    if (yearNum < 3) {
      stringArray.push(`${monthNum} Month`);
    }

    stringArray.push('Old');

    return stringArray.join(' ');
  }, [patient.dateOfBirth]);

  function displayStandardDate(dateToFormat: string | number | Date): string {
    const date = new Date(dateToFormat);
    if (!isDateValid(date)) {
      return dateToFormat?.toString();
    }
    return standardDateFormat.format(date);
  }

  function displayPhoneNumber(phoneNumber: string): string {
    if (phoneNumber) {
      let phone = phoneNumber.replace(/\D/g, '');
      const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
      }
      return phone;
    }

    return phoneNumber;
  }

  function handleCopyPhoneNumber(phoneNumber: string): () => void {
    return () => {
      copyPhoneNumber(phoneNumber);
    };
  }

  async function copyPhoneNumber(phoneNumber: string): Promise<void> {
    await navigator.clipboard.writeText(phoneNumber);
    alertService.success('Phone number copied to the clipboard');
  }

  function isDateValid(date: Date | string | number) {
    return !isNaN(new Date(date).getDate());
  }

  function get65PlusInfoMessage(): string {
    // eslint-disable-next-line max-len
    return 'Patient is 65+ \n\n• Limit Orders to services that are 100% Nice paid \n• Limit Prescriptions to the Nice Free Drug List \n• Not eligible for Diabetes Education';
  }

  return (
    <>
      <Box>
        {patient?.is65Plus && (
          <Tooltip
            title={get65PlusInfoMessage()}
            componentsProps={{
              tooltip: {
                sx: {
                  'white-space': 'pre',
                },
              },
            }}
          >
            <IconButton sx={{ paddingTop: 0, paddingRight: '8px', paddingBottom: 0, paddingLeft: 0 }}>
              <Error className='icon-warn' />
            </IconButton>
          </Tooltip>
        )}
        <Typography className='header' display='inline'>
          {displayAge} {Object.values(GenderName)[patient.gender]}
        </Typography>
      </Box>
      <Box>
        <Typography className='header'>
          Sex assigned at birth: {SexAssignedAtBirth[patient.sexAssignedAtBirth]?.valueOf()}
        </Typography>
      </Box>
      <Box>
        <Typography className='header'>DOB: {displayStandardDate(patient.dateOfBirth)}</Typography>
      </Box>
      {patient.race !== null && (
        <Box>
          <Typography className='header'>Race: {Race[patient.race]}</Typography>
        </Box>
      )}
      <Box>
        <Typography className='header'>Email: {patient.email}</Typography>
      </Box>
      <Box className='content-copy'>
        <Typography onClick={handleCopyPhoneNumber(patient.phone)}>
          Phone: {displayPhoneNumber(patient.phone)}
          <Tooltip title='Copy phone number'>
            <IconButton onClick={handleCopyPhoneNumber(patient.phone)} sx={{ padding: 0, marginLeft: '8px' }}>
              <ContentCopy sx={{ fontSize: '14px', color: 'white' }} />
            </IconButton>
          </Tooltip>
        </Typography>
      </Box>
      {patient.address && (
        <>
          <Box
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            <Box>
              {patient.inHomeVisitServiceRange && (
                <Tooltip title='In home visit service area'>
                  <IconButton sx={{ paddingLeft: 0 }}>
                    <Check className='icon-green' />
                  </IconButton>
                </Tooltip>
              )}
              {!patient.inHomeVisitServiceRange && (
                <Tooltip title='Not in home visit service area'>
                  <IconButton sx={{ paddingLeft: 0 }}>
                    <Close color='error' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box style={{ flexDirection: 'column' }}>
              <Box style={{ flexDirection: 'row' }}>
                <Typography>{patient.address.address1}</Typography>
                <Typography>{patient.address.address2}</Typography>
              </Box>
              <Box style={{ flexDirection: 'row' }}>
                <Typography>
                  {patient.address.city},&nbsp;{patient.address.state}&nbsp;{patient.address.zip}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            {capsuleEligibilityCheckInProgress && (
              <Box className='spinner-box'>
                <Tooltip title='Checking for Capsule delivery availability'>
                  <CircularProgress color='error' sx={{ marginTop: '8px', marginRight: '8px', marginBottom: '8px' }} />
                </Tooltip>
              </Box>
            )}
            {!capsuleEligibilityCheckInProgress && !capsuleDeliveryAvailable && (
              <Tooltip title='Capsule delivery is not avialable'>
                <IconButton sx={{ paddingLeft: 0 }}>
                  <Close className='icon-warn' color='error' />
                </IconButton>
              </Tooltip>
            )}
            {!capsuleEligibilityCheckInProgress && capsuleDeliveryAvailable && (
              <Tooltip title='Capsule delivery is avialable'>
                <IconButton sx={{ paddingLeft: 0 }}>
                  <Check className='icon-green' />
                </IconButton>
              </Tooltip>
            )}
            <Box
              style={{
                marginTop: '8px',
              }}
            >
              <Typography>Capsule Delivery Eligible</Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default PatientInfoSummary;

import { ObjectiveConfiguration } from '../../../constants/chart-form-configuration';
import { ChartObjectiveGroup, GroupWithIsHealthy, PatientChartPayload } from '../../../dto';
import { ChartSmokingStatus, SubstanceUse } from '../../../enums';
import { booleanToString } from '../../../utility';
import { TreatmentPlan } from '../treatment-plan';

interface SectionGroup {
  [key: string]: any;
  note: string;
  isHealthy: boolean;
}

export function treatmentPlanObjective(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const objective = chart.objective;
  if (!objective) {
    return;
  }

  const listSections: (keyof ChartObjectiveGroup)[] = [
    ...ObjectiveConfiguration.map((group) => {
      return group.formGroupName;
    }),
  ];

  // If no referenced fields have content, proceed without adding any content
  if (
    !listSections.some((field) => {
      const group = objective[field];
      if (typeof group === 'object') {
        return pdf.hasContent({
          ...group,
          isHealthy: null,
        });
      }
    })
  ) {
    return;
  }

  const columnHeaders = ['Section', 'Symptoms', 'Note'];
  const columnKeys = ['section', 'symptoms', 'note'];

  pdf.addHeaderContent({
    text: 'Objective',
  });

  const tableData = ObjectiveConfiguration
    // Gets rid of the non-iterable parts of the form and values that are empty
    .filter((config) => {
      const group: GroupWithIsHealthy = (objective as any)[config.formGroupName];
      const value = (group as any)[config.formControlName];

      return (
        // We want to keep sections are not empty OR are marked as healthy
        (typeof value === 'object' && value !== null) || group.isHealthy || (group.note && group.note.length > 0)
      );
    })
    .map((config) => {
      const group = (objective as any)[config.formGroupName];
      const formValue = group[config.formControlName];
      const value = group.isHealthy
        ? 'No symptoms reported'
        : formValue
        ? Object.entries(group[config.formControlName] as SectionGroup)
            // Get rid of sections indicated as false
            .filter(([key, value]) => value)
            // Return back the string value that is the name of the enum value key
            .map(([key, value]) => (config.options as any)[key])
            // Represent those keys as one joined value
            .join(', ')
        : null;

      return {
        section: config.heading,
        symptoms: value,
        note: group.note,
      };
    });

  if (tableData.length > 0) {
    pdf.addTableContent({
      columnHeaders,
      columnKeys,
      tableData,
      columnWidths: ['auto', '*', 'auto'],
    });
  }

  const substanceData = objective.substanceGroup.substance;

  const substanceDetails = [
    {
      key: 'Smoking Status',
      value: substanceData?.smokingStatus ? ChartSmokingStatus[substanceData.smokingStatus] : '',
    },
    {
      key: 'Vaping',
      value: substanceData?.vaping ? booleanToString(substanceData.vaping) : '',
    },
    {
      key: 'Alcohol Use',
      value: substanceData?.alcoholUse ? booleanToString(substanceData.alcoholUse) : '',
    },
    {
      key: 'Substance Use',
      value: substanceData?.substanceUse
        ? Object.entries(substanceData.substanceUse)
            .filter(([key, value]) => value)
            .map(([key, value]) => {
              return (SubstanceUse as any)[key];
            })
            .join(', ')
        : '',
    },
    {
      key: 'History of Substance Use Treatment',
      value: substanceData?.historyOfSubstanceUseTreatment ? substanceData.historyOfSubstanceUseTreatment : '',
    },
  ].filter((detailRow) => {
    return detailRow.value !== null;
  });

  pdf.addDetailsContent({
    title: {
      text: 'Substance Use',
    },
    details: substanceDetails,
  });

  if (objective.specificPhobiasGroup) {
    pdf.addParagraphContent({
      title: 'Specific Phobias',
      text: objective.specificPhobiasGroup,
    });
  }
}

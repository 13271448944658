import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApplicationType, ClientConfiguration } from '../../../../../core/version';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  // We have a separate client here because we can't use the same interceptors without a cyclical dependency
  // with AuthService.
  private httpClient: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async getVersionConfig(): Promise<ClientConfiguration> {
    return await this.httpClient
      .get<ClientConfiguration>(`${environment.apiUrl}/versionConfig/${ApplicationType.PROVIDER}`)
      .toPromise();
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

/**
 * Simple cache for http requests intended only for API routes that can be safely cached.
 * Routes that can be cached are ones where the response will NEVER change.
 * Example:
 * /api/v1/travel-time?origin=7312 Wooddale Ave S, Edina, MN&destination=11 4th Street NE, Minneapolis, MN&departureTime=2020-06-17T08:00:00
 * This URL will never change as the query parameters define 2 locations and a specific date/time
 * and time machines don't exist to change the past.
 *
 * URLS that should not be cached are things like /api/v1/messages/unread
 * which will change over time.
 *
 * Use the urlsToCache array to define urls that should be cached.
 */
@Injectable({
  providedIn: 'root',
})
export class RequestCacheService {
  cache = new Map();
  // Opt in list of URLs to cache.  Assumed to start with /api.
  // TODO add ttl value to expire cache items per path.  { path: '/v1/travel-time', ttl: 60000}
  private apiUrlsToCache: string[] = [
    '/v1/travel-time/',
    '/v1/service-area/timezone/', // /api/v1/service-area/timezone/2500%20Garfield%20Ave%20S,%20%20Minneapolis,%20MN,%2055404
  ];
  private cachePrefix = 'RequestCache:';

  constructor() {}

  /**
   * Fetch an item from localStorage cache if listed in the apiUrlsToCache
   * @param req  Http Request
   */
  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    if (!this.urlInCacheList(req)) {
      return; // returns undefined
    }
    const url = req.urlWithParams;
    const cached = this.cache.get(url);
    if (!cached) {
      return undefined;
    }
    const resp = new HttpResponse();
    Object.assign(resp, cached.response);
    return resp; // cached.response as HttpResponse<any>;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    if (!this.urlInCacheList(req)) {
      return;
    }
    const url = req.urlWithParams;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);
  }

  /**
   * Searches cache for a url.
   * @param req A http request
   */
  urlInCacheList(req: HttpRequest<any>): boolean {
    // Find matches for URLs with query parameters
    if (req.params.keys.length > 0) {
      if (this.apiUrlsToCache.indexOf(req.url.split('/api')[1]) < 0) {
        return false;
      }
    } else {
      // find matches for URLs without query parameters
      const apiPath = req.url.split('/api')[1];
      if (!apiPath) {
        return false;
      }
      const matchString = apiPath.substr(0, apiPath.lastIndexOf('/') + 1);
      if (this.apiUrlsToCache.indexOf(matchString) < 0) {
        return false;
      }
    }
    return true;
  }
}

export enum TimeZoneLength {
  Long = 'long',
  Medium = 'medium',
  Short = 'short',
}

const timeZones: {
  [key: string]: {
    name: string;
    abbreviation: string;
  };
} = {
  ['America/Los_Angeles']: {
    name: 'Pacific',
    abbreviation: 'PT',
  },
  ['America/Denver']: {
    name: 'Mountain',
    abbreviation: 'MT',
  },
  ['America/Chicago']: {
    name: 'Central',
    abbreviation: 'CT',
  },
  ['America/New_York']: {
    name: 'Eastern',
    abbreviation: 'ET',
  },
  ['America/Anchorage']: {
    name: 'Alaskan',
    abbreviation: 'AK',
  },
  ['Pacific/Honolulu']: {
    name: 'Hawaiian',
    abbreviation: 'HAST',
  },
};

/**
 * This method takes the city time zone string returned by Luxon and translates it
 * into a few more useful forms for presentation.
 */
export function translateTimeZone(
  inputTimeZone: string,
  length: TimeZoneLength = TimeZoneLength.Medium,
): string {
  const zone = timeZones[inputTimeZone];

  if (!zone) {
    return inputTimeZone;
  }

  switch (length) {
    case TimeZoneLength.Short:
      return zone.abbreviation;
    case TimeZoneLength.Medium:
      return zone.name;
    case TimeZoneLength.Long:
      return `${zone.name} Standard Time`;
  }
}

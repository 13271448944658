import { get, omit } from 'lodash';
import { PatientChartPayload } from '../../../dto';

export function sectionHasContent(
  chart: PatientChartPayload,
  path: string,
  omittedTableProperties: string[] = [],
  hasContentBypassFunction?: Function
) {
  const data = get(chart, path);
  if (!data) {
    return false;
  }

  const defaultTableProperties = ['createdOn', 'updatedOn', 'deleted', 'id'];

  const hasContentBypass = hasContentBypassFunction ? hasContentBypassFunction(data) : false;

  const hasContent = Object.entries(omit(data, [...defaultTableProperties, ...omittedTableProperties])).some(
    ([key, value]) => value !== null && value !== ''
  );

  if (!hasContent && !hasContentBypass) {
    return false;
  }

  return true;
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { isEqual, isNil, unionWith, uniqBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AutocompleteSearchInputComponent } from '../../autocomplete-search-input/autocomplete-search-input.component';
import { AlertService } from '../../../core/services/alert.service';
import { ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { TypeAheadSearchResult } from '../../../core/typeahead-search-method';

export interface Option<T = any> {
  label: string;
  value: string | number | Option[];
  searchValues?: (string | number)[];
  data?: T;
  description?: string;
}

export interface OptionCollection {
  label: string;
  value: (string | number)[];
}

@Component({
  selector: 'app-form-repeater',
  templateUrl: './form-repeater.component.html',
  styleUrls: ['./form-repeater.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRepeaterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormRepeaterComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRepeaterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formGroupConstructorFn: (option?: Option) => UntypedFormGroup;
  @Input() options: Option[] = [];
  @Input() stickyOptions: Option[] = [];
  @Input() uniqueRows = true;
  @Input() placeholder: string;
  @Input() usingTypeAheadComponent: boolean;
  @Input() stickyOptionValueAsLabel: boolean;
  @Input() showAddOtherButton = false;
  @Input() hideAutoComplete = false;
  @Input() multiLineOption = false;
  @Input() deleteConfig: {
    showDelete: boolean;
    confirmDelete: ConfirmDialogModel;
    deleteMethod?: (rowFormGroup: UntypedFormGroup) => any;
    softDeleteKey?: string;
  } = { showDelete: true, confirmDelete: null };

  @ViewChild('matAutoComplete') matAutoComplete: MatAutocomplete;
  @ViewChild('repeaterRowContainer') repeaterRowContainer: ElementRef<HTMLElement>;
  @ViewChild('autocompleteInput', { read: ElementRef }) autocompleteInput: ElementRef;

  @ContentChild(AutocompleteSearchInputComponent) autocompleteSearchInputComponent: AutocompleteSearchInputComponent;
  @ContentChild('searchResultTemplate') searchResultTemplate: TemplateRef<any>;
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;

  public autocompleteFormControl = new UntypedFormControl(null);
  public filteredOptions: Observable<Option[]>;
  public repeaterFormGroup = new UntypedFormGroup({
    repeaterFormArray: new UntypedFormArray([]),
  });
  public repeaterFormArray: UntypedFormArray;
  public selectedOptions: Option[] = [];
  public disabled = false;

  private destroyed: Subject<boolean> = new Subject<boolean>();

  constructor(private alertService: AlertService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    // Observable for filtering static options
    // This is not needed when using the auto complete component
    if (!this.usingTypeAheadComponent) {
      this.filteredOptions = this.autocompleteFormControl.valueChanges.pipe(
        takeUntil(this.destroyed),
        startWith(''),
        map((value) => {
          if (!value) {
            return '';
          }

          return typeof value === 'string' ? value : value.label;
        }),
        map((value) => this.filter(value))
      );
    }

    // Storing this in a variable makes it easier for the template
    this.repeaterFormArray = this.repeaterFormGroup.get('repeaterFormArray') as UntypedFormArray;

    // CVA observable subscription
    this.repeaterFormArray.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngAfterViewInit() {
    let autoCompleteTrigger = this.matAutoComplete;

    // We want to redirect some elements and our observer to our typeahead component
    if (this.usingTypeAheadComponent) {
      this.autocompleteInput = this.autocompleteSearchInputComponent.autocompleteInput;

      this.autocompleteInput.nativeElement.disabled = this.disabled;

      this.autocompleteFormControl = this.autocompleteSearchInputComponent.searchInput;

      autoCompleteTrigger = this.autocompleteSearchInputComponent.matAutoComplete;

      this.autocompleteSearchInputComponent.searchResults$
        .pipe(takeUntil(this.destroyed))
        .subscribe((results: TypeAheadSearchResult<string, string>[]) => {
          this.options = unionWith(this.options, results, isEqual);
        });
    }

    // Listens for a selection to be made
    if (autoCompleteTrigger) {
      autoCompleteTrigger.optionSelected
        .pipe(
          map((event: MatAutocompleteSelectedEvent) => event.option),
          takeUntil(this.destroyed)
        )
        .subscribe((option: MatOption) => {
          // Add new form control
          const newFormGroup = this.formGroupConstructorFnWrapper(option.value);
          const groupExists = this.repeaterFormArray.controls.find((formGroup) => {
            return Number(formGroup.value.allergyId) === Number(newFormGroup.value.allergyId);
          });

          if (this.uniqueRows) {
            if (!groupExists) {
              this.repeaterFormArray.push(newFormGroup);
            } else {
              if (groupExists.value.deleted) {
                groupExists.patchValue({
                  deleted: false,
                });
              }
            }
          } else {
            this.repeaterFormArray.push(newFormGroup);
          }

          // If we need unique rows, add to array to watch
          if (this.uniqueRows) {
            this.addToSelectedOptions(option.value);
          }

          // Deselect the option, reset the form control, and blur the element
          option.deselect();
          this.resetAutocompleteFormControl();
          this.cdr.detectChanges();
        });
    }
  }

  // Wrapper to add the 'key' form control to bind to our options
  formGroupConstructorFnWrapper(option?: Option): UntypedFormGroup {
    const formGroup = this.formGroupConstructorFn(option);
    const formControl = new UntypedFormControl(option ? option.value : '');
    formGroup.addControl('key', formControl);
    return formGroup;
  }

  // Fires when a sticky option is selected
  addStickyOption($event, option: Option): void {
    if (Array.isArray(option.value)) {
      this.addArrayOption(option);
    } else {
      this.addSingleOption(option);
    }

    this.resetAutocompleteFormControl();
  }

  addSingleOption(option: Option) {
    if (this.uniqueRows) {
      this.addToSelectedOptions(option);
    }
    this.repeaterFormArray.push(this.formGroupConstructorFnWrapper(option));
  }

  /**
   * Add a collection of options
   * @param option Contains an array of Options as the option.value
   */
  addArrayOption(option: Option) {
    // Ensures it will be an array of Options
    if (Array.isArray(option.value)) {
      for (const subOption of option.value) {
        // Add each option to the selected options list (the method makes sure they are unique)
        if (this.uniqueRows) {
          this.addToSelectedOptions(subOption);
        }
        // Get all of the currently added options from the repeaterFormArray...
        const valueArray: { label: string }[] = this.repeaterFormArray.value;
        // ...and make sure we only push values that don't already exist in that formArray.
        if (!valueArray.find((controlValue) => controlValue?.label === subOption?.label)) {
          this.repeaterFormArray.push(this.formGroupConstructorFnWrapper(subOption));
        }
      }
    }
  }

  /**
   * Adds a new formgroup to the repeater form array without providing
   * any initial options (i.e. all fields having blank/default values).
   * This would used as an 'Other' option, for choices that are included
   * in the autocomplete or sticky option lists.
   */
  addEmptyGroup() {
    this.repeaterFormArray.push(this.formGroupConstructorFn());
  }

  // Fires when delete is selected
  async deleteCompletedPatientFormRow($event, index: number, key: string): Promise<void> {
    // Test for confirmation dialog
    if (this.deleteConfig.confirmDelete) {
      const confirmed = await this.alertService.confirm(this.deleteConfig.confirmDelete);

      if (!confirmed) {
        return;
      }
    }

    if (this.deleteConfig.deleteMethod) {
      this.deleteConfig.deleteMethod(this.repeaterFormArray.at(index) as UntypedFormGroup);
      this.cdr.detectChanges();
      return;
    }

    // If we are using unique rows, remove from our list of used options
    if (this.uniqueRows) {
      this.removeSelectedOption(key);
    }

    // Remove from the form array and reset the control
    this.repeaterFormArray.removeAt(index);
    this.resetAutocompleteFormControl();
    this.cdr.detectChanges();
  }

  // Helper function to update the option display
  displayFn(option: Option): string {
    return option ? option.label : '';
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(values) {
    if (values && values !== this.repeaterFormArray.value) {
      this.syncFormArray(values);
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.autocompleteFormControl.disable({ emitEvent: false });
      this.repeaterFormArray.disable();
      this.repeaterFormArray.patchValue(this.repeaterFormArray.getRawValue());
    } else {
      this.autocompleteFormControl.enable({ emitEvent: false });
      this.repeaterFormArray.enable();
      this.repeaterFormArray.patchValue(this.repeaterFormArray.getRawValue());
    }
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.repeaterFormGroup.valid ? null : { valid: false };
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  // Concat instead of push to trigger pipe to run
  private addToSelectedOptions(option: Option): void {
    // The use of a set makes sure there are ony unique options listed
    this.selectedOptions = uniqBy(this.selectedOptions.concat([option]), (selectedOption) => selectedOption?.value);
  }

  // Filter instead of splice to trigger pipe to run
  private removeSelectedOption(key: string): void {
    const selectedOption = this.selectedOptions.find((option) => {
      return key === option?.value;
    });

    this.selectedOptions = this.selectedOptions.filter((option) => option !== selectedOption);
  }

  // Reset form control value and blur
  private resetAutocompleteFormControl(): void {
    this.autocompleteFormControl.setValue('');

    if (this.autocompleteInput) {
      this.autocompleteInput.nativeElement.blur();
    }
  }

  // Method for filtering a static list of options
  private filter(value: string): Option[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option: Option) => {
      let optionMatches = false;

      if (option.searchValues && option.searchValues.length > 0) {
        optionMatches =
          option?.searchValues
            .map((searchValue) => (typeof searchValue === 'number' ? searchValue.toString() : searchValue))
            .filter((searchValue) => searchValue?.toLowerCase().includes(filterValue)).length > 0;
      } else {
        optionMatches = option?.label.toLowerCase().includes(filterValue);
      }

      const isUsed = !this.selectedOptions.includes(option);

      // If we are using unique rows, we want to filter out options from the search results
      if (this.uniqueRows) {
        return optionMatches && isUsed;
      }

      return optionMatches;
    });
  }

  // CVA sync to take in an array of values from the parent form control
  // This then will create form controls and set the value for the array
  private syncFormArray(values = []): void {
    if (this.repeaterFormArray) {
      const repeatedFormGroup: UntypedFormGroup[] = values.map((value) => {
        // Create a form group for this form using the constructor function
        const formGroup: UntypedFormGroup = this.formGroupConstructorFnWrapper();

        // Set the value from our parent
        formGroup.patchValue(value);

        // If using unique rows, add to selected options
        if (this.uniqueRows) {
          const option = [...this.stickyOptions, ...this.options].find(
            (singleOption) => singleOption.value === value.key
          );
          if (!isNil(option)) {
            this.addToSelectedOptions(option);
          }
        }

        return formGroup;
      });

      // Remove all form controls in form array, just in case
      this.repeaterFormArray.clear();

      // Push each form control to the form array
      repeatedFormGroup.forEach((formGroup: UntypedFormGroup): void => {
        this.repeaterFormArray.push(formGroup);
      });

      this.cdr.markForCheck();
    }
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { version } from '../../core/version';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://c3b53246507844e1b8414146b42d7932@o354533.ingest.sentry.io/5823500',
  ignoreErrors: ['jwt expired'],
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        environment.apiUrl,
        environment.apiV2Url,
        environment.apiV3Url,
        environment.niceServiceUrl,
        environment.returnToUrl,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new CaptureConsoleIntegration({
      levels: ['error', 'warn'],
    }),
  ],
  release: version.current,
  environment: environment.environmentLabel,
  // Set tracesSampleRate to 1.0 to capture 100% in dev, 20% in prod.
  tracesSampleRate: environment.production ? 0.2 : 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

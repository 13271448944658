export enum TaskTypes {
  diabeticEducation = 'Diabetic Education',
  imagingNeeded = 'Imaging Needed',
  imagingResults = 'Imaging Results',
  labResults = 'Lab Results',
  medicalRecords = 'Medical Records',
  medicationRequest = 'Medication Request',
  membershipVerification = 'Membership Verification',
  mobilePhlebotomyOrPsc = 'Mobile Phlebotomy or PSC',
  niceMentalHealthTherapyNeeded = 'Nice Mental Health Therapy Needed',
  physicalTherapyReferral = 'Physical Therapy Referral',
  primaryCareReferral = 'Primary Care Referral',
  referralNeeded = 'Referral Needed',
  reminder = 'Reminder',
}

export enum TaskTabs {
  active = 'Active Tasks',
  assigned = 'Assigned',
  completed = 'Completed',
  all = 'All Tasks',
}

export enum TaskSentStatus {
  all = 'All',
  sent = 'Sent',
  scheduled = 'Scheduled',
}

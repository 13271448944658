<mat-autocomplete
  #auto="matAutocomplete"
  #matAutoComplete
  autoActiveFirstOption
  (optionSelected)="selectOption($event)"
  [displayWith]="displayWithOptionLabel"
>
  <mat-option
    *ngFor="let result of searchResults$ | async"
    [value]="result"
    [matTooltip]="result.label"
    matTooltipPosition="after"
    multiLineOption
  >
    <div *ngIf="templateRef; else defaultOptionDisplay">
      <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: result }"></ng-template>
    </div>
    <ng-template #defaultOptionDisplay>
      {{ result.label }}
    </ng-template>
  </mat-option>
</mat-autocomplete>

<div fxLayout="row" fxLayoutAlign="start center">
  <div>
    <mat-form-field
      [style.width.px]="width"
      [floatLabel]="disableLabelFloat ? 'never' : 'auto'"
      [hidden]="searchInput.disabled"
    >
      <input
        type="text"
        [placeholder]="placeholderText"
        aria-label="Search"
        matInput
        [matTooltip]="tooltip"
        [formControl]="searchInput"
        [matAutocomplete]="auto"
        #autocompleteInput
      />
      <button matPrefix mat-icon-button *ngIf="!hideSearchIcon">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <mat-error *ngIf="error && !suppressError" [@inOutAnimation]>
      {{ error }}
    </mat-error>
  </div>
  <div>
    <mat-spinner
      class="loading-indicator"
      color="primary"
      diameter="18"
      [attr.aria-busy]="isLoading"
      [ngClass]="{ 'loading-indicator--display': isLoading }"
    >
    </mat-spinner>
  </div>
</div>

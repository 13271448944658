import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concat, delay, skip, switchMap, tap } from 'rxjs/operators';
import { OperatingSystem } from '@enums';
import { isSupported } from 'twilio-video';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private platform: Platform) {}

  public get os() {
    if (navigator.platform.match('Mac')) {
      return OperatingSystem.Mac;
    }
    if (navigator.platform.match('Win')) {
      return OperatingSystem.Win;
    }
    if (navigator.platform.match('Linux')) {
      return OperatingSystem.Linux;
    }
    return null;
  }

  public createNewPollingObservable<T>(request: () => Observable<T>, pollingRate: number): Observable<T> {
    const trigger = new BehaviorSubject(null);

    const whenToRefresh = of(null).pipe(
      delay(pollingRate),
      tap((_) => trigger.next(null)),
      skip(1)
    );

    return trigger.pipe(switchMap(() => request().pipe(concat(whenToRefresh))));
  }

  /**
   * JSON.stringify an object and download it a a .json file
   * Code taken from https://stackoverflow.com/a/30800715/7926620
   */
  public downloadObjectAsJson(exportObject: object, exportName: string) {
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObject, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', exportName + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  public checkIfPlatformSupported(): {
    isSupported: boolean;
    message?: string;
  } {
    // Skip for local environment or unit testing.
    if (environment.returnToUrl === 'http://localhost:4200') {
      return { isSupported: true };
    } else if (!isSupported) {
      if (this.platform.IOS) {
        return {
          isSupported: false,
          message: 'Video visits are not supported in your browser. Please use Safari on your iOS device.',
        };
      } else if (this.platform.ANDROID) {
        return {
          isSupported: false,
          message: 'Video visits are not supported in your browser. Please use Chrome on your Android device.',
        };
      } else {
        return {
          isSupported: false,
          message: 'Video visits are not supported in your browser. Please use Chrome or Safari.',
        };
      }
    } else {
      return { isSupported: true };
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { SessionLayoutComponent } from './layout/session-layout/session-layout.component';
import { LayoutContainerComponent } from './layout/layout-container/layout-container.component';

const routes: Routes = [
  {
    path: 'login',
    redirectTo: '/session/login',
    pathMatch: 'full',
  },
  {
    path: '404',
    redirectTo: '/session/404',
    pathMatch: 'full',
  },
  {
    path: 'callback',
    redirectTo: '/session/callback',
    pathMatch: 'full',
  },
  {
    path: 'video',
    canActivate: [AuthGuard],
    loadChildren: () => import('./video/video.module').then((m) => m.VideoModule),
  },
  {
    path: '',
    component: LayoutContainerComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./layout/dashboard-layout.module').then((m) => m.DashboardLayoutModule),
  },
  {
    path: 'session',
    component: SessionLayoutComponent,
    loadChildren: () => import('./session/session.module').then((m) => m.SessionModule),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { PatientChartPayload } from '../../../dto';
import {
  ChartPlanInHousePocLabs,
  ChartPlanInterventions,
  ChartPlanProceduresRequested,
  ChartTypeEnum,
  TreatmentPlanScope,
  Urgency,
} from '../../../enums';
import { TreatmentPlan } from '../treatment-plan';
import { FontSize } from '../assets';

export function treatmentPlanPlan(
  pdf: TreatmentPlan,
  chart: PatientChartPayload,
  pdfScope: TreatmentPlanScope | undefined,
) {
  const isFullScope = pdfScope === TreatmentPlanScope.full;

  const data = {
    imaging: chart.plan.imagingGroup,
    labs: chart.plan.labsGroup,
    inHouseLabs: chart.plan.inHousePocLabsGroup,
    medication: chart.plan.medicationGroup,
    physicalTherapy: chart.plan.physicalTherapyGroup,
    interventions: chart.plan.interventionsGroup,
    proceduresRequested: chart.plan.proceduresRequestedGroup,
  };

  let addedToPDF: boolean = false;

  switch (chart.chartType.type) {
    // Medical chart specific PDf sections
    case ChartTypeEnum.medical:
      if (isFullScope) {
        if (
          pdf.hasContent([
            data.imaging,
            data.labs,
            data.medication,
            data.physicalTherapy,
            data.inHouseLabs,
            data.proceduresRequested,
          ])
        ) {
          pdf.addHeaderContent({ text: 'Plan' });
          addImaging(pdf, chart);
          addLabs(pdf, chart, pdfScope);
          addProceduresRequested(pdf, chart);
          addInHousePocLabs(pdf, chart);
          addMedications(pdf, chart);
          addedToPDF = true;
        }
      }

      const physicalTherapy = chart.plan?.physicalTherapyGroup?.physicalTherapy;
      if (physicalTherapy) {
        pdf.addParagraphContent({
          title: 'Physical Therapy',
          text: physicalTherapy,
        });
        addedToPDF = true;
      }
      break;

    // Mental health chart specific PDF sections
    case ChartTypeEnum.mentalHealth:
      if (isFullScope) {
        if (pdf.hasContent([data.interventions])) {
          pdf.addHeaderContent({ text: 'Plan' });
          addInterventions(pdf, chart);
          addedToPDF = true;
        }
      }
      break;
  }

  // This is on every chart PDF and every scope
  const education = chart.plan?.patientEducationGroup?.patientEducation;
  if (education) {
    pdf.addParagraphContent({
      title: 'Patient Education',
      text: education,
    });
    addedToPDF = true;
  }

  // These are on every full-scope PDF, regardless of type
  if (isFullScope) {
    const differentialsAndThoughts = chart.plan?.differentialsAndThoughtsGroup?.differentialsAndThoughts;
    if (differentialsAndThoughts) {
      pdf.addParagraphContent({
        title: 'Provider Plan/Differentials & Thoughts',
        text: differentialsAndThoughts,
      });
      addedToPDF = true;
    }

    const providerToProvider = chart.plan?.providerToProviderGroup?.providerToProvider;
    if (providerToProvider) {
      pdf.addParagraphContent({
        title: 'Provider to Provider Communication',
        text: providerToProvider,
      });
      addedToPDF = true;
    }
  }

  return addedToPDF;
}

export function addImaging(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const data = chart.plan?.imagingGroup;
  if (!data) {
    return;
  }

  if (data.imaging) {
    const imaging = data.imaging.map((imagingGroup) => {
      return {
        ...imagingGroup,
        urgency: (<any>Urgency)[imagingGroup.urgency as Urgency],
      };
    });

    pdf.addTableContent({
      title: 'Images',
      columnHeaders: ['Imaging', 'Urgency', 'Indications', '# of Stairs (if in-home)', 'Home Instructions'],
      columnKeys: ['label', 'urgency', 'instructions', 'numberOfStairs', 'homeInstructions'],
      columnWidths: ['auto', 'auto', 'auto', 'auto', '*'],
      tableData: imaging,
    });
  }

  if (data.note) {
    pdf.addNoteSubsection({
      text: data.note,
      title: 'Imaging Note',
    });
  }
}

export function addLabs(pdf: TreatmentPlan, chart: PatientChartPayload, pdfScope: TreatmentPlanScope) {
  const data = chart.plan?.labsGroup;
  if (!data) {
    return;
  }
  const isFullTreatmentPlan = pdfScope === TreatmentPlanScope.full;

  if (data.labs) {
    const labs = chart?.plan?.labsGroup?.labs?.map((labsGroup) => {
      return {
        ...labsGroup,
        urgency: (<any>Urgency)[labsGroup.urgency as Urgency],
      };
    });

    pdf.addTableContent({
      title: 'Labs',
      columnHeaders: isFullTreatmentPlan ? ['Lab', 'Urgency', 'Instructions'] : ['Lab', 'Urgency'],
      columnKeys: isFullTreatmentPlan ? ['label', 'urgency', 'instructions'] : ['label', 'urgency'],
      columnWidths: isFullTreatmentPlan ? ['auto', 'auto', '*'] : ['*', 'auto'],
      tableData: labs || [],
    });
  }

  if (data.note) {
    pdf.addNoteSubsection({
      text: data.note,
      title: 'Labs Note',
    });
  }
}

export function addProceduresRequested(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const data = chart.plan?.proceduresRequestedGroup;
  if (!data) {
    return;
  }

  if (data.proceduresRequested) {
    pdf.addListContent({
      title: 'Procedures Requested for Home Visit',
      listItems: Object.entries(data.proceduresRequested)
        .filter(([key, value]) => value)
        .map(([key, value]) => {
          return (<any>ChartPlanProceduresRequested)[key];
        }),
    });
  }

  if (data.note) {
    pdf.addNoteSubsection({
      text: data.note,
      title: 'Procedures Requested Note',
    });
  }
}

export function addInHousePocLabs(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const data = chart.plan?.inHousePocLabsGroup;
  if (!data) {
    return;
  }

  if (data.inHousePocLabs) {
    pdf.addListContent({
      title: 'In-House/POC Labs Needed',
      listItems: Object.entries(data.inHousePocLabs)
        .filter(([key, value]) => value)
        .map(([key, value]) => {
          return (<any>ChartPlanInHousePocLabs)[key];
        }),
    });

    if (data.note) {
      pdf.addNoteSubsection({
        text: data.note,
        title: 'In-House/POC Labs Needed Note',
      });
    }
  }
}

export function addMedications(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const data = chart.plan?.medicationGroup;
  if (!data) {
    return;
  }

  if (data.medications && data.medications.length > 0 && data.medications[0].drugName) {
    const medicationData = data.medications.map((medication) => ({
      ...medication,
      pharmacyAddress:
        [
          medication.pharmacyAddress1,
          medication.pharmacyAddress2,
          medication.pharmacyCity,
          medication.pharmacyState,
          medication.pharmacyZip,
        ]
          .filter((value) => !!value)
          .join(', ') || '',
      pharmacyPhone: medication.pharmacyPhone || '',
    }));

    pdf.addTableContent({
      title: 'Medications Prescribed',
      headerStyle: {
        fontSize: FontSize.Default,
      },
      bodyStyle: {
        fontSize: FontSize.Default,
      },
      columnHeaders: [
        'Medication',
        'Written Date',
        'Quantity',
        'Refills',
        'Days Supply',
        'SIG',
        'Pharmacy',
        'Pharmacy Address',
        'Pharmacy Phone',
      ],
      columnKeys: [
        'drugName',
        'writtenDate',
        'quantity',
        'refills',
        'daysSupply',
        'sig',
        'pharmacy',
        'pharmacyAddress',
        'pharmacyPhone',
      ],
      columnWidths: ['auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
      tableData: medicationData,
    });
  }
}

export function addInterventions(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const data = chart.plan.interventionsGroup;
  if (data) {
    const plannedInterventions = data.interventions
      ? Object.entries(data.interventions).filter(([key, value]) => value)
      : [];
    if (plannedInterventions.length > 0) {
      pdf.addListContent({
        title: 'Planned Interventions - Patient Participation',
        listItems: plannedInterventions.map(([key, value]) => {
          return (<any>ChartPlanInterventions)[key];
        }),
      });
    }

    if (!!data.note) {
      pdf.addNoteSubsection({
        text: data.note,
        title: 'Planned Interventions Note',
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AcuityAppointmentType, AcuityCalendar, AcuityTimeSlot, AppointmentDetails } from '@dto';
import {
  Appointment as CoreAppointment,
  AppointmentType,
  FollowUpAppointmentBookingDto,
} from '../../../../../core/dto/apiV2';

import { AuthService } from './auth.service';

export interface ASVResponse {
  label: string;
  result: string;
  responseFieldRef: string;
}
export interface AsvResponsesWithSubmissionDate {
  submissionDate: Date;
  labelledResponses: ASVResponse[];
}

@Injectable({
  providedIn: 'root',
})
export class LegacyAppointmentService {
  public minDate: string;
  public maxDate: string;
  /**
   * Emits event when an AppointmentService method should trigger an appointment refresh
   * e.g. when an appointment is booked or updated
   */
  public refreshTrigger: Observable<boolean>;
  public appointmentTypes: Observable<AppointmentType[]>;
  public selectedProviders: Observable<number[]>;
  public selectedProvidersByAcuityId: Observable<number[]>;

  private _selectedProviders: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  private _selectedProvidersByAcuityId: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  private _refreshTrigger: Subject<boolean> = new Subject<boolean>();
  private _appointmentTypes: BehaviorSubject<AppointmentType[]> = new BehaviorSubject<AppointmentType[]>([]);
  private _acuityAppointmentTypes: BehaviorSubject<AcuityAppointmentType[]> = new BehaviorSubject<
    AcuityAppointmentType[]
  >([]);

  constructor(private authService: AuthService, private httpClient: HttpClient) {
    this.refreshTrigger = this._refreshTrigger.asObservable();
    this.appointmentTypes = this._appointmentTypes.asObservable();
    this.selectedProviders = this._selectedProviders.asObservable();
    this.selectedProvidersByAcuityId = this._selectedProvidersByAcuityId.asObservable();

    this.minDate = DateTime.local().startOf('day').toISO();
    this.maxDate = DateTime.local().plus({ months: 1 }).endOf('day').toISO();

    this.authService.authState
      .pipe(
        switchMap((authState) => {
          if (authState === null) {
            return of([]);
          }

          return this.getAcuityAppointmentTypes();
        })
      )
      .subscribe((result) => this._acuityAppointmentTypes.next(result));

    this.authService.authState
      .pipe(
        switchMap((authState) => {
          if (authState === null) {
            return of([]);
          }

          return this.getAppointmentTypes();
        })
      )
      .subscribe((result) => this._appointmentTypes.next(result));
  }

  get currentSelectedProviders() {
    return this._selectedProviders.value;
  }

  get appointmentTypesSnapshot(): AppointmentType[] {
    return this._appointmentTypes.value;
  }

  get acuityAppointmentTypeSnapshot(): AcuityAppointmentType[] {
    return this._acuityAppointmentTypes.value;
  }

  /**
   * Get single appointment by appointment id
   */
  public getAppointmentById(id: string): Observable<CoreAppointment> {
    const url = environment.apiV2Url.concat(`/appointments/${id}`);
    return this.httpClient.get<CoreAppointment>(url);
  }

  /**
   * Gets an appointment and its details using the details ID
   */
  public getAppointmentByDetailsId(detailsId: string | number): Observable<AppointmentDetails[]> {
    const url = environment.apiV2Url.concat(`/appointments/${detailsId}/bydetails`);

    return this.httpClient.get<AppointmentDetails[]>(url);
  }

  /**
   * @deprecated Use {@link NiceUsersService#getProviders()}
   */
  public getCalendars(): Observable<AcuityCalendar[]> {
    const url = environment.apiUrl.concat('/appointments/calendars');
    const params: any = {
      excludeInactive: true,
    };
    return this.httpClient.get<AcuityCalendar[]>(url, { params });
  }

  public getAppointmentTypes(): Observable<AppointmentType[]> {
    const url = environment.apiUrl.concat('/appointment-types');
    return this.httpClient.get<AppointmentType[]>(url);
  }

  public getAcuityAppointmentTypes(): Observable<AcuityAppointmentType[]> {
    const url = environment.apiUrl.concat('/appointments/types');
    return this.httpClient.get<AcuityAppointmentType[]>(url);
  }

  /**
   * @deprecated
   */
  public getAppointmentAvailability(
    date: Date,
    acuityAppointmentTypeId: number,
    timezone: string,
    calendarId?: number
  ): Observable<Array<AcuityTimeSlot>> {
    const timezoneString = timezone.replace('/', '%2F');
    const url = `${environment.apiUrl}/appointments/availability/${acuityAppointmentTypeId}/${timezoneString}`;
    const dateString = DateTime.fromJSDate(date).toISODate();

    const params: any = {
      date: dateString,
    };

    if (calendarId) {
      params.providerId = String(calendarId);
    }

    return this.httpClient.get<Array<AcuityTimeSlot>>(url, { params });
  }

  public getAsvResponsesForAppointmentDetails(
    appointmentDetailsId: number
  ): Observable<AsvResponsesWithSubmissionDate> {
    const url = environment.apiV2Url.concat(`/appointments/asv/${appointmentDetailsId}`);

    const params = {
      responseType: 'text',
    };
    return this.httpClient.get<AsvResponsesWithSubmissionDate>(url, { params });
  }

  public refreshAppointments() {
    this._refreshTrigger.next(true);
  }

  public createBooking(booking: FollowUpAppointmentBookingDto) {
    return this.httpClient
      .post(`${environment.apiV2Url}/appointments/follow-up-visit`, booking)
      .pipe(tap(() => this.refreshAppointments()));
  }

  public updateSelectedProviders(providerIds: number[]) {
    this._selectedProviders.next(providerIds);
  }
}

import React from 'react';
import PatientInfoSummary, { PatientInfoSummaryProps } from './PatientInfoSummary';
import ThemedComponent from '../../theme/ThemedComponent';

export function PatientInfoSummaryWrapper({
  alertService,
  patient,
  capsuleEligibilityCheckInProgress,
  capsuleDeliveryAvailable,
}: PatientInfoSummaryProps): React.JSX.Element {
  return (
    <ThemedComponent>
      <PatientInfoSummary
        alertService={alertService}
        patient={patient}
        capsuleEligibilityCheckInProgress={capsuleEligibilityCheckInProgress}
        capsuleDeliveryAvailable={capsuleDeliveryAvailable}
      />
    </ThemedComponent>
  );
}

export default PatientInfoSummaryWrapper;

import { AfterContentInit, ContentChildren, Directive, ElementRef, HostBinding, QueryList } from '@angular/core';
import { MatLine, setLines } from '@angular/material/core';

// Directive from: https://stackblitz.com/edit/angular-material-multi-line-option
@Directive({
  selector: 'mat-option[multi-line-option], mat-option[multiLineOption]',
  exportAs: 'multiLineOption',
})
export class MultiLineOptionDirective implements AfterContentInit {
  @HostBinding('class') classes = 'multi-line-option';

  @ContentChildren(MatLine, { descendants: true, read: MatLine })
  public lines: QueryList<MatLine>;

  constructor(protected element: ElementRef<HTMLElement>) {}

  public ngAfterContentInit(): void {
    setLines(this.lines, this.element);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  /**
   * Transforms a date into an age
   * @param date Must by an ISO format of mm-dd-yyyy at least
   */
  transform(date: string): string {
    if (!DateTime.fromISO(date).isValid) {
      return date;
    }

    const stringArray = [];

    const years = DateTime.fromISO(date).diffNow('years').negate().toFormat('y', { floor: true });

    const yearNum = Number(years);
    if (yearNum !== 0) {
      stringArray.push(`${years} Year`);
    }

    const months = DateTime.fromISO(date).diffNow('months').negate().toFormat('M', { floor: true });

    const monthNum = Number(months) % 12;
    if (yearNum < 3) {
      stringArray.push(`${monthNum} Month`);
    }

    stringArray.push('Old');

    return stringArray.join(' ');
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProviderService } from '../../core/services/provider.service';
import { SrfaxService } from '../../core/services/srfax.service';
import { TaskService } from '../../core/services/task.service';
import { ChatService } from '../../core/services/chat.service';
import { HomeVisitRequestService } from '../../core/services/home-visit-request.service';
import { NavigationService } from '../../core/services/navigation.service';
import { InactiveNotificationDialogComponent } from '../inactive-notification-dialog/inactive-notification-dialog.component';
import { AuthService } from '../../core/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  public opened: Observable<boolean>;
  public taggedCount: BehaviorSubject<number>;
  public unreadCount: Observable<number>;
  public unviewedCount: Observable<number>;
  public faxCount: Observable<number>;
  public taskCount: Observable<number>;
  public isUsingIdentityService: boolean;

  private destroyed: Subject<boolean> = new Subject();

  constructor(
    private chatService: ChatService,
    private homeVisitRequestService: HomeVisitRequestService,
    private srfaxService: SrfaxService,
    public navigationService: NavigationService,
    private taskService: TaskService,
    private providerService: ProviderService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.opened = this.navigationService.sidenavOpened;
    this.taggedCount = this.chatService.taggedMessageCount;
    this.unreadCount = this.chatService.totalMessageCount;
    this.unviewedCount = this.homeVisitRequestService.unviewedHomeVisitRequestCount;
    this.faxCount = this.srfaxService.unreadFaxCount;
    this.taskCount = this.taskService.taskCount;
    this.isUsingIdentityService = this.authService.isUsingIdentityService();
  }

  ngOnInit() {
    this.providerService._providerInactive.pipe(takeUntil(this.destroyed)).subscribe((inactive) => {
      if (inactive) {
        this.dialog.open(InactiveNotificationDialogComponent, {
          disableClose: true,
          backdropClass: 'inactive-notification-dialog-backdrop',
        });
      }
    });
  }

  onUsersToolClicked(): void {
    window.open(environment.niceAdminUrl + '/users');
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }
}

<div class="container">
  <ng-content select="[repeaterHeader]"></ng-content>

  <div class="warn-message">
    <ng-content select="[warnMessageArea]"></ng-content>
  </div>

  <div class="button-box">
    <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
      <ng-content select="[autoCompleteTypeAhead]"></ng-content>

      <mat-form-field *ngIf="!usingTypeAheadComponent" [hidden]="hideAutoComplete">
        <button matPrefix mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
        <input
          type="text"
          [placeholder]="placeholder"
          matInput
          [formControl]="autocompleteFormControl"
          [matAutocomplete]="auto"
          #autocompleteInput
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" #matAutoComplete [displayWith]="displayFn">
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
            [matTooltip]="option.label"
            matTooltipPosition="after"
            multiLineOption
          >
            <div *ngIf="searchResultTemplate; else defaultOptionDisplay">
              <ng-template
                [ngTemplateOutlet]="searchResultTemplate"
                [ngTemplateOutletContext]="{ $implicit: option }"
              ></ng-template>
            </div>
            <ng-template #defaultOptionDisplay>
              {{ option.label }}
            </ng-template>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button
        mat-stroked-button
        [disabled]="disabled || (option | isOptionUsed : selectedOptions)"
        class="option-button"
        *ngFor="let option of stickyOptions"
        (click)="addStickyOption($event, option)"
      >
        {{ stickyOptionValueAsLabel ? option.value : option.label }}
        <mat-icon *ngIf="option | isOptionUsed : selectedOptions; else optionSelected" [inline]="true">check</mat-icon>
        <ng-template #optionSelected>
          <mat-icon [inline]="true">add</mat-icon>
        </ng-template>
      </button>

      <button
        mat-stroked-button
        color="primary"
        *ngIf="showAddOtherButton"
        [disabled]="disabled"
        class="option-button"
        (click)="addEmptyGroup()"
      >
        Add Other
        <mat-icon>post_add</mat-icon>
      </button>
    </div>
  </div>

  <div class="header-row">
    <ng-content select="[aboveRepeater]" *ngIf="repeaterFormArray.length > 0"></ng-content>
  </div>

  <div [formGroup]="repeaterFormGroup" class="repeater-row--container" #repeaterRowContainer>
    <ng-container formArrayName="repeaterFormArray">
      <div *ngFor="let item of repeaterFormArray.controls; let i = index" [formGroupName]="i">
        <div class="row" *ngIf="!deleteConfig.softDeleteKey || !item.value[deleteConfig.softDeleteKey]">
          <ng-template
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{
              $implicit: {
                formGroup: item,
                option: item.value.key | findOption : options,
                index: i
              }
            }"
          ></ng-template>
          <button
            mat-icon-button
            color="warn"
            class="delete"
            *ngIf="deleteConfig.showDelete"
            (click)="deleteCompletedPatientFormRow($event, i, item.value.key)"
            [disabled]="disabled"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="note-container">
    <ng-content select="[noteField]"></ng-content>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgTemplateOutlet } from '@angular/common';
import { TypeAheadSearchMethod, TypeAheadSearchResult } from '../../core/typeahead-search-method';

@Component({
  selector: 'app-autocomplete-search-input',
  templateUrl: './autocomplete-search-input.component.html',
  styleUrls: ['./autocomplete-search-input.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('.15s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.15s .25s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteSearchInputComponent implements OnInit, OnDestroy {
  @Input() searchServiceMethod: TypeAheadSearchMethod;
  @Input() width: string;

  @Input() placeholderText = 'Search';
  @Input() hideSearchIcon = false;
  @Input() disableLabelFloat = false;
  @Input() clearSearchAfterSelection = false;
  @Input() suppressError = false;
  @Input() tooltip: string;
  @Input() minimumCharLength = 1;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.searchInput.disable();
    } else {
      this.searchInput.enable();
    }
  }

  @ViewChild('matAutoComplete') public matAutoComplete: MatAutocomplete;
  @ViewChild('autocompleteInput', { read: ElementRef }) public autocompleteInput: ElementRef;

  @Output() resultSelected = new EventEmitter();

  @ContentChild(TemplateRef) templateRef: TemplateRef<NgTemplateOutlet>;

  public _disabled: boolean;
  public isLoading = false;
  public searchInput = new UntypedFormControl('');
  public error: string;
  public searchResults$: ReturnType<TypeAheadSearchMethod>;
  public debounceTime = 400;

  private searchItemSelected = false;
  private destroyed = new Subject<boolean>();

  constructor() {}

  get disabled(): boolean {
    return this._disabled;
  }

  ngOnInit() {
    this.searchResults$ = this.searchInput.valueChanges.pipe(
      startWith([null]),
      pairwise(),
      takeUntil(this.destroyed),
      switchMap(([prevValue, currentValue]: string[]) => {
        if (prevValue && prevValue.length >= this.minimumCharLength && currentValue.length === 0) {
          return of(currentValue);
        }

        return of(currentValue).pipe(filter((text: string) => text.length >= this.minimumCharLength));
      }),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      tap(this.showLoadingIndicator.bind(this)),
      switchMap((searchString: string): ReturnType<TypeAheadSearchMethod> => {
        return this.searchServiceMethod(searchString).pipe(
          catchError((): ReturnType<TypeAheadSearchMethod> | Observable<null> => {
            this.hideLoadingIndictor();
            this.error = 'An error occurred trying to get the options.';
            return EMPTY;
          })
        );
      }),
      tap((results) => {
        this.hideLoadingIndictor();
        if (results && results.length === 0) {
          this.error = 'No options found.';
        } else {
          this.error = null;
        }
      })
    );
  }

  displayWithOptionLabel(option: TypeAheadSearchResult) {
    return option.label;
  }

  showLoadingIndicator(): void {
    if (!this.searchItemSelected) {
      this.isLoading = true;
    } else {
      this.searchItemSelected = false;
    }
  }

  hideLoadingIndictor(): void {
    this.isLoading = false;
  }

  selectOption($event: MatAutocompleteSelectedEvent) {
    this.searchItemSelected = true;
    this.resultSelected.emit($event.option.value);

    if (this.clearSearchAfterSelection) {
      this.searchInput.setValue('', {
        emitEvent: false,
      });

      this.autocompleteInput.nativeElement.blur();
    }
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }
}

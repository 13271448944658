import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SessionRoutingModule } from './session-routing.module';
import { LoginComponent } from './login/login.component';
import { CallbackComponent } from './callback/callback.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [CallbackComponent, LoginComponent, NotFoundComponent],
  imports: [SharedModule, SessionRoutingModule],
  exports: [NotFoundComponent],
})
export class SessionModule {}

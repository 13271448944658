import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest(
      this.authService.authState,
      this.authService.initialized,
    ).pipe(
      take(1),
      map(([authState, init]) => authState),
      map((authState) => !authState),
      tap((isNotAuthenticated) => {
        const loggedIn = !isNotAuthenticated;
        if (loggedIn) {
          this.router.navigate(['/dashboard']);
        }
      }),
    );
  }
}

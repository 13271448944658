import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  FilterValueTypes,
  PaginationInformation,
} from '../../shared/server-side-paginated-table/datasources/generic.datasource';
import { TaskCounts, TaskCountSettings, TaskDto, TaskFormPayload } from '@dto';
import { Observable, ReplaySubject } from 'rxjs';
import { UtilityService } from './utility.service';
import { startWith } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { DateUtils } from '../date.utils';
import { PaginatedResponse } from '@models';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  public taskCount: Observable<number>;
  public userCount: Observable<number>;
  public unreadCount: Observable<number>;
  public urgentCount: Observable<number>;
  public activeCount: Observable<number>;

  private _taskCount = new ReplaySubject<number>();
  private _userCount = new ReplaySubject<number>();
  private _unreadCount = new ReplaySubject<number>();
  private _urgentCount = new ReplaySubject<number>();
  private _activeCount = new ReplaySubject<number>();

  private countSettings: TaskCountSettings = {
    patientId: null,
  };

  constructor(private httpClient: HttpClient, private utilityService: UtilityService) {
    this.taskCount = this._taskCount.asObservable().pipe(startWith(0));
    this.userCount = this._userCount.asObservable().pipe(startWith(0));
    this.unreadCount = this._unreadCount.asObservable().pipe(startWith(0));
    this.urgentCount = this._urgentCount.asObservable().pipe(startWith(0));
    this.activeCount = this._activeCount.asObservable().pipe(startWith(0));

    this.utilityService
      .createNewPollingObservable(this.getTaskCounts.bind(this), environment.taskCountPollingRate)
      .subscribe((counts: TaskCounts) => {
        this._taskCount.next(counts.displayCount);
        this._userCount.next(counts.userCount);
        this._unreadCount.next(counts.unreadCount);
        this._urgentCount.next(counts.urgentCount);
        this._activeCount.next(counts.activeCount);
      });
  }

  public async updateCountSettings(value: TaskCountSettings) {
    this.countSettings = value;

    const counts = await this.getTaskCounts().toPromise();

    this._taskCount.next(counts.displayCount);
    this._userCount.next(counts.userCount);
    this._unreadCount.next(counts.unreadCount);
    this._urgentCount.next(counts.urgentCount);
    this._activeCount.next(counts.activeCount);
  }

  public getTaskCounts() {
    const url = environment.apiV3Url.concat('/task/count');
    let params = new HttpParams();
    const { patientId } = this.countSettings;
    if (patientId) {
      params = params.append('patientId', patientId.toString());
    }

    const date = DateUtils.cleanTimestampString(DateTime.now().toISODate());
    params = params.append('dateLimit', date);

    return this.httpClient.get<TaskCounts>(url, { params });
  }

  public getTasksPaginated(
    queryParams: HttpParams,
    { filters }: PaginationInformation
  ): Observable<PaginatedResponse<TaskDto>> {
    const url = environment.apiV3Url.concat('/task');

    interface ParamListItem {
      value: FilterValueTypes;
      alias: string;
    }

    const paramList: ParamListItem[] = [
      { value: filters.showCompleted, alias: 'showCompleted' },
      {
        value: DateUtils.cleanTimestampString(filters.today as string),
        alias: 'today',
      },
      { value: filters.displayPage, alias: 'displayPage' },
      { value: filters.fixedPatientId, alias: 'patientIdFilter' },
      {
        value: DateUtils.cleanTimestampString(
          DateTime.fromJSDate(filters.startDate as Date)
            .startOf('day')
            .toISO()
        ),
        alias: 'startDate',
      },
      {
        value: DateUtils.cleanTimestampString(
          DateTime.fromJSDate(filters.endDate as Date)
            .startOf('day')
            .toISO()
        ),
        alias: 'endDate',
      },
      { value: filters.unreadOnly, alias: 'unreadOnly' },
      { value: filters.urgentOnly, alias: 'urgentOnly' },
      { value: filters.status, alias: 'status' },
    ];

    paramList.forEach((v: ParamListItem) => {
      if (v.value !== null && v.value !== 'null') {
        queryParams = queryParams.append(v.alias, v.value as string);
      }
    });

    if (filters.assigned) {
      (filters.assigned as string[]).forEach((userId) => {
        queryParams = queryParams.append('assigned[]', userId);
      });
    }

    if (filters.createdBy) {
      (filters.createdBy as string[]).forEach((userId) => {
        queryParams = queryParams.append('createdBy[]', userId);
      });
    }

    if (filters.taskType) {
      (filters.taskType as string[]).forEach((taskType) => {
        queryParams = queryParams.append('taskType[]', taskType);
      });
    }

    return this.httpClient.get<PaginatedResponse<TaskDto>>(url, {
      params: queryParams,
    });
  }

  public getTask(id: number) {
    const url = environment.apiV3Url.concat(`/task/${id}`);
    return this.httpClient.get<TaskDto>(url);
  }

  public getTasksForChart(chartId: number) {
    const url = environment.apiV3Url.concat(`/task/chart/${chartId}`);
    return this.httpClient.get<TaskDto[]>(url);
  }

  public createTask(payload: TaskFormPayload) {
    const url = environment.apiV3Url.concat('/task');
    return this.httpClient.post(url, payload);
  }

  public updateTask(id: number, payload: TaskFormPayload) {
    const url = environment.apiV3Url.concat(`/task/${id}`);
    return this.httpClient.put(url, payload);
  }

  public markComplete(taskIds: number[]) {
    const url = environment.apiV3Url.concat('/task/mark-complete');
    const completedDate = DateUtils.cleanTimestampString(DateTime.now().toISO());
    return this.httpClient.put(url, { taskIds, completedDate });
  }

  public markActive(taskIds: number[]) {
    const url = environment.apiV3Url.concat('/task/mark-active');

    return this.httpClient.put(url, { taskIds });
  }

  public deleteTasks(taskIds: number[]) {
    const url = environment.apiV3Url.concat('/task/delete');
    return this.httpClient.put(url, { taskIds });
  }

  public updateReadStatus(taskIds: number[], status: boolean) {
    const url = environment.apiV3Url.concat('/task/update-read-status');
    return this.httpClient.put(url, { taskIds, status });
  }

  public assignToSelf(taskIds: number[]) {
    const url = environment.apiV3Url.concat('/task/assign-to-self');
    return this.httpClient.put(url, { taskIds });
  }
}

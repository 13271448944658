import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { first, map } from 'rxjs/operators';

import { ConfirmDialogComponent, ConfirmDialogModel } from '../../shared/confirm-dialog/confirm-dialog.component';
import { TextDialogComponent, TextDialogModel } from '../../shared/text-dialog/text-dialog.component';
import { LoadingAlertComponent } from '../../shared/loading-alert/loading-alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialog: MatDialog, private snackbar: MatSnackBar) {}
  public async confirmWithCheckbox(
    data: ConfirmDialogModel
  ): Promise<{ confirmed: boolean; checkboxSelected: boolean }> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: data.maxWidth ? data.maxWidth : '300px',
      data,
    });

    return dialogRef
      .afterClosed()
      .pipe(
        first(),
        map((confirmed) => ({ confirmed: confirmed, checkboxSelected: dialogRef.componentInstance.checkboxSelected }))
      )
      .toPromise();
  }

  /**
   * @param data Title, message, textConfirm?, textDismiss?, warn?, maxWidth?
   */
  public async confirm(data: ConfirmDialogModel): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: data.maxWidth ? data.maxWidth : '300px',
      data,
    });

    return dialogRef.afterClosed().pipe(first()).toPromise();
  }

  /**
   * @param data Title?, textDismiss?, message
   */
  public async textbox(data: TextDialogModel) {
    const dialogRef = this.dialog.open(TextDialogComponent, {
      maxWidth: data.maxWidth ? data.maxWidth : '300px',
      data,
    });

    return dialogRef.afterClosed().pipe(first()).toPromise();
  }

  public error(message: string, action?: string, config?: Partial<MatSnackBarConfig>) {
    return this.snackbar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: 'background-warn',
      ...config,
    });
  }

  public success(message: string, action?: string, config?: Partial<MatSnackBarConfig>) {
    return this.snackbar.open(message, action, {
      duration: 2500,
      horizontalPosition: 'right',
      panelClass: 'background-primary',
      ...config,
    });
  }

  public loading(message: string, config?: Partial<MatSnackBarConfig>) {
    this.snackbar.openFromComponent(LoadingAlertComponent, {
      horizontalPosition: 'right',
      panelClass: 'background-primary',
      data: {
        message,
      },
      ...config,
    });
  }

  public dismiss() {
    return this.snackbar.dismiss();
  }

  public notification(title: string, body: string) {
    const notificationOptions = {
      body,
      icon: 'assets/images/logo.png',
      requireInteraction: true,
      image: 'assets/images/logo.png',
    };

    if (!('Notification' in window)) {
      return; // notifications unsupported
    } else if (Notification.permission === 'granted') {
      const notification = new Notification(title, notificationOptions);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(title, notificationOptions);
        }
      });
    }
  }
}

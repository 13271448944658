export const pageBreak = (currentNode: any, followingNodesOnPage: any[]) => {
  // If there is a table header and no follow table elements, insert a page break
  if (currentNode.headlineLevel === 1) {
    return (
      followingNodesOnPage.filter((node) => node.style === 'table-data')
        .length === 0
    );
  }
  // Break if node is a header and there are no other nodes on page
  if (
    currentNode.style === 'header' ||
    (currentNode.style instanceof Array && currentNode.style.includes('header'))
  ) {
    return (
      followingNodesOnPage.length === 0 ||
      currentNode.startPosition.verticalRatio > 0.8
    );
  }
  return false;
};

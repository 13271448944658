<h1 *ngIf="title" mat-dialog-title>
  {{ title }}
</h1>

<mat-dialog-content>
  <p class="message">{{ message }}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">{{ textDismiss }}</button>
</mat-dialog-actions>

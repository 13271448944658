import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '../../form-repeater/form-repeater.component';

@Pipe({
  name: 'isOptionUsed',
})
export class IsOptionUsedPipe implements PipeTransform {
  transform(option: Option, selectedOptions: Option[]): boolean {
    if (!option || !selectedOptions) {
      return false;
    }
    if (Array.isArray(option.value)) {
      // If the value of the option is an array of Options, we need to compare to see if any of those options
      // are missing from the selectedOptions. Returns false if any are missing and true if they are all found.
      return option.value.every((individualOption) => {
        return !!selectedOptions.find(
          (selectedOption) => selectedOption.value === individualOption.value,
        );
      });
    } else {
      // Standard option for single-value options
      return !!selectedOptions.find((selectedOption) => {
        if (!selectedOption) {
          return false;
        }
        return option.value === selectedOption.value;
      });
    }
  }
}

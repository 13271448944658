import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RouterModule } from '@angular/router';
import { LinkyModule } from 'ngx-linky';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialModule } from './material/material.module';
import { CoreModule } from '../core/core.module';
import { PatientTableComponent } from './tables/patient-table/patient-table.component';
import { EnumPipe } from './pipes/enum.pipe';
import { MembershipTableComponent } from './tables/membership-table/membership-table.component';
import { MembershipFormComponent } from './forms/membership-form/membership-form.component';
import { CompanyFormComponent } from './forms/company-form/company-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyTableComponent } from './tables/company-table/company-table.component';
import { MembershipTypeTableComponent } from './tables/membership-type-table/membership-type-table.component';
import { MembershipTypeFormComponent } from './forms/membership-type-form/membership-type-form.component';
import { ProgressButtonComponent } from './progress-button/progress-button.component';
import { PatientFormComponent } from './forms/patient-form/patient-form.component';
import { LoadingComponent } from './loading/loading.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { AddressFormComponent } from './forms/address-form/address-form.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { ScheduleTableComponent } from './tables/schedule-table/schedule-table.component';
import { NavComponent } from './nav/nav.component';
import { AppointmentFormComponent } from './forms/appointment-form/appointment-form.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';
import { BackgroundColorDirective } from './directives/background-color.directive';
import { StringToRgbPipe } from './pipes/string-to-rgb.pipe';
import { CalendarViewAppointmentComponent } from './calendar-view/calendar-view-appointment/calendar-view-appointment.component';
import { ScheduledMessagesTableComponent } from './tables/scheduled-messages-table/scheduled-messages-table.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { EligiblePatientListsTableComponent } from './tables/eligible-patient-lists-table/eligible-patient-lists-table.component';
import { EligiblePatientsTableComponent } from './tables/eligible-patients-table/eligible-patients-table.component';
import { UploadEligiblePatientButtonComponent } from './tables/company-table/upload-eligible-patient-list-button/upload-eligible-patient-list-button.component';
import { CompanyMessageDialogComponent } from './tables/company-table/company-message-dialog/company-message-dialog.component';
import { AddressComponent } from './address/address.component';
import { HomeVisitRequestFormComponent } from './forms/home-visit-request-form/home-visit-request-form.component';
import { TagSelectComponent } from './tag-select/tag-select.component';
import { ProviderTableComponent } from './tables/provider-table/provider-table.component';
import { ProviderFormComponent } from './forms/provider-form/provider-form.component';
import { PreventativeScreeningFormComponent } from './forms/preventative-screening-form/preventative-screening-form.component';
import { NoteModalComponent } from './note-modal/note-modal.component';
import { SubstanceUseHistoryFormComponent } from './forms/substance-use-history-form/substance-use-history-form.component';
import { ConditionTriStateComponent } from './forms/form-components/condition-tri-state/condition-tri-state.component';
import { MultiToggleComponent } from './multi-toggle/multi-toggle.component';
import { SocialHistoryFormComponent } from './forms/social-history-form/social-history-form.component';
import { ValueLabelPipe } from './pipes/value-label.pipe';
import { InputModalButtonComponent } from './forms/form-components/input-modal-button/input-modal-button.component';
import { InputModalComponent } from './forms/form-components/input-modal/input-modal.component';
import { AutocompleteSearchInputComponent } from './autocomplete-search-input/autocomplete-search-input.component';
import { SurgeryListFormComponent } from './forms/surgery-list-form/surgery-list-form.component';
import { ConditionListFormComponent } from './forms/condition-list-form/condition-list-form.component';
import { FamilyConditionsFormComponent } from './forms/family-conditions-form/family-conditions-form.component';
import { MultiConditionTriStateComponent } from './forms/form-components/multi-condition-tri-state/multi-condition-tri-state.component';
import { ChartRowComponent } from './layout/chart-row/chart-row.component';
import { LoadingAlertComponent } from './loading-alert/loading-alert.component';
import { AllergyListFormComponent } from './forms/allergy-list-form/allergy-list-form.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FormRepeaterComponent } from './layout/form-repeater/form-repeater.component';
import { IsOptionUsedPipe } from './layout/chart-row/pipes/is-option-used.pipe';
import { FindOptionPipe } from './layout/chart-row/pipes/find-option.pipe';
import { UrgencySelectComponent } from './urgency-select/urgency-select.component';
import { MedicalHistoryFormComponent } from './forms/medical-history-form/medical-history-form.component';
import { PregnancyFormComponent } from './forms/pregnancy-form/pregnancy-form.component';
import { MedicationListFormComponent } from './forms/medication-list-form/medication-list-form.component';
import { AssessmentComponent } from './forms/chart-forms/assessment/assessment.component';
import { ClinicalReportingComponent } from './forms/chart-forms/clinical-reporting/clinical-reporting.component';
import { InHouseLabResultsComponent } from './forms/chart-forms/in-house-lab-results/in-house-lab-results.component';
import { ProceduresPerformedComponent } from './forms/chart-forms/procedures-performed/procedures-performed.component';
import { PatientEducationComponent } from './forms/chart-forms/plan/patient-education/patient-education.component';
import { PhysicalExamComponent } from './forms/chart-forms/physical-exam/physical-exam.component';
import { PlanComponent } from './forms/chart-forms/plan/plan.component';
import { ReviewOfSystemsComponent } from './forms/chart-forms/review-of-systems/review-of-systems.component';
import { ImagingFormComponent } from './forms/chart-forms/plan/imaging-form/imaging-form.component';
import { LabsFormComponent } from './forms/chart-forms/plan/labs-form/labs-form.component';
import { AddTaskButtonComponent } from './add-task/add-task-button/add-task-button.component';
import { AddTaskModalComponent } from './add-task/add-task-modal/add-task-modal.component';
import { ProviderToProviderCommsModalComponent } from './calendar-view/provider-to-provider-comms-modal/provider-to-provider-comms-modal.component';
import { IntakeDisplayModalComponent } from './intake-display/intake-display-modal/intake-display-modal.component';
import { IntakeDisplayButtonComponent } from './intake-display/intake-display-button/intake-display-button.component';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { DividerComponent } from './divider/divider.component';
import { PhysicalTherapyFormComponent } from './forms/chart-forms/plan/physical-therapy-form/physical-therapy-form.component';
import { ProviderToProviderCommsComponent } from './forms/chart-forms/plan/provider-to-provider-comms/provider-to-provider-comms.component';
import { CreateInvoiceModalComponent } from './create-invoice-modal/create-invoice-modal.component';
import { InputModalReadonlyDisplayComponent } from './forms/form-components/input-modal-readonly-display/input-modal-readonly-display.component';
import { PrescriptionSyncTableComponent } from '../patient/chart/prescription-sync-table/prescription-sync-table.component';
import { TextDialogComponent } from './text-dialog/text-dialog.component';
import { HomeRequestDisplayPageComponent } from './home-request-display-page/home-request-display-page.component';
import { PrescriptionCardComponent } from './prescription-card/prescription-card.component';
import { TableLoadingIndicatorComponent } from './server-side-paginated-table/table-loading-indicator/table-loading-indicator.component';
import { MultiLineOptionDirective } from './directives/multi-line-option.directive';
import { Icd10ListPipe } from './pipes/icd10-list.pipe';
import { InvoicePatientCardComponent } from './invoice/invoice-patient-card/invoice-patient-card.component';
import { InvoiceDetailsCardComponent } from './invoice/invoice-details-card/invoice-details-card.component';
import { InvoiceChartCardComponent } from './invoice/invoice-chart-card/invoice-chart-card.component';
import { AgePipe } from './pipes/age.pipe';
import { PatientVitalsComponent } from './forms/patient-vitals/patient-vitals.component';
import { MessageSpinnerComponent } from './message-spinner/message-spinner.component';
import { CommaListPipe } from './pipes/comma-list.pipe';
import { KeyValueListComponent } from './key-value-list/key-value-list.component';
import { ChartNoteComponent } from './chart-note/chart-note.component';
import { SyncedPrescriptionTableComponent } from './forms/chart-forms/synced-prescription-table/synced-prescription-table.component';
import { ChangeHealthcareSsoButtonComponent } from './change-healthcare-sso/change-healthcare-sso-button/change-healthcare-sso-button.component';
import { DifferentialsAndThoughtsFormComponent } from './forms/chart-forms/plan/differentials-and-thoughts-form/differentials-and-thoughts-form.component';
import { ProviderNamePipe } from './pipes/provider-name.pipe';
import { ProceduresRequestedFormComponent } from './forms/chart-forms/plan/procedures-requested-form/procedures-requested-form.component';
import { InHousePocLabsFormComponent } from './forms/chart-forms/plan/in-house-poc-labs-form/in-house-poc-labs-form.component';
import { ImagePricePipe } from './pipes/image-price.pipe';
import { ApplyMonospaceToZeroDirective } from './directives/apply-monospace-to-zero.directive';
import { TimeOrDatePipe } from './pipes/timeOrDate.pipe';
import { TimeElapsedPipe } from './pipes/timeElapsed.pipe';
import { MinutesElapsedPipe } from './pipes/minutesElapsed.pipe';
import { ConversationSourcePipe } from './pipes/conversationSource.pipe';
import { InViewportNotifierDirective } from './directives/in-viewport-notifier.directive';
import { TypingIndicatorComponent } from './typing-indicator/typing-indicator.component';
import { ColumnDateDisplayComponent } from './column-date-display/column-date-display.component';
import { LastExamFormComponent } from './forms/last-exam-form/last-exam-form.component';
import { SelectionTableComponent } from './selection-table/selection-table.component';
import { ButtonModalComponent } from './button-modal/button-modal.component';
import { GenericFormModalComponent } from './generic-form-modal/generic-form-modal.component';
import { ViewRefDirective } from './directives/view-ref.directive';
import { FindInArrayPipe } from './pipes/find-in-array.pipe';
import { FilterDisplayDateRangePipe } from './pipes/filter-display-date-range.pipe';
import { FilterDisplayCompareToDefaultPipe } from './pipes/filter-display-compare-to-default.pipe';
import { EmptyTableMessageComponent } from './empty-table-message/empty-table-message.component';
import { DocumentNamePipe } from './pipes/document-name.pipe';
import { TaskFormComponent } from './add-task/task-form/task-form.component';
import { TableChipComponent } from './table-chips/table-chip/table-chip.component';
import { CounterBoxComponent } from './counter-box/counter-box.component';
import { CountTextPipe } from './pipes/count-text.pipe';
import { ReferralFormComponent } from './forms/referral-form/referral-form.component';
import { WarningMessageBoxComponent } from './warning-message-box/warning-message-box.component';
import { AllergyChecklistComponent } from './forms/referral-form/allergy-checklist/allergy-checklist.component';
import { MedicationChecklistComponent } from './forms/referral-form/medication-checklist/medication-checklist.component';
import { ImagingChecklistComponent } from './forms/referral-form/imaging-checklist/imaging-checklist.component';
import { LabChecklistComponent } from './forms/referral-form/lab-checklist/lab-checklist.component';
import { AssessmentChecklistComponent } from './forms/referral-form/assessment-checklist/assessment-checklist.component';
import { CheckboxTextareaComponent } from './forms/form-components/checkbox-textarea/checkbox-textarea.component';
import { TableFilterChipGroupComponent } from './table-filter-chip-group/table-filter-chip-group.component';
import { TooltipOnEllipsesDirective } from './directives/tooltip-on-ellipses.directive';
import { ReverseScrollDirective } from './directives/reverse-scroll.directive';
import { ScheduleTableFilterFormComponent } from './tables/schedule-table/schedule-table-filter-form/schedule-table-filter-form.component';
import { AppointmentHasChartPipe } from './pipes/appointment-has-chart.pipe';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { UserTagComponent } from './user-tag/user-tag.component';
import { JoinArrayPipe } from './pipes/join-array.pipe';
import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { MinutesToHoursAndMinutesPipe } from './pipes/minutes-to-hours-and-minutes.pipe';
import { AddressBookLookupPipe } from './pipes/address-book-lookup.pipe';
import { TableClickBackgroundComponent } from './table-click-background/table-click-background.component';
import { NoFocusOnClickDirective } from './directives/no-focus-on-click.directive';
import { RescheduleFormComponent } from './forms/reschedule-form/reschedule-form.component';
import { ProviderNameAsyncPipe } from './pipes/provider-name-async.pipe';
import { InterventionsFormComponent } from './forms/chart-forms/plan/interventions-form/interventions-form.component';
import { PaginatedAppointmentListComponent } from './calendar-view/paginated-appointment-list/paginated-appointment-list.component';
import { SubjectiveComponent } from './forms/chart-forms/subjective/subjective.component';
import { ObjectiveComponent } from './forms/chart-forms/objective/objective.component';
import { ChartInputSectionComponent } from './layout/chart-input-section/chart-input-section.component';
import { EpisodeOfCareComponent } from './forms/chart-forms/episode-of-care/episode-of-care.component';
import { MentalStatusComponent } from './forms/chart-forms/mental-status/mental-status.component';
import { PriorMentalHealthTreatmentComponent } from './forms/chart-forms/prior-mental-health-treatment/prior-mental-health-treatment.component';
import { TranslateTimezonePipe } from './pipes/translate-time-zone.pipe';
import { StandardDatePipe } from './pipes/standard-date.pipe';
import { ScheduleMessageButtonComponent } from './schedule-message-button/schedule-message-button.component';
import { IgnoreWheelDirective } from './directives/ignore-wheel.directive';
import { ProviderNameComponent } from './provider-name/provider-name.component';
import { LegacyProviderNameComponent } from './provider-name/legacy-provider-name.component';
import { PopoverDirective } from './directives/popover.directive';
import { PopoverComponent } from './popover/popover.component';
import { ReactComponentDirective } from '../react/react-component.directive';
import { MatDatepicker, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatSuffix } from '@angular/material/form-field';
import { ScheduledMessageCardComponent } from '../chat/scheduled-message-card/scheduled-message-card.component';
import { JoinVisitButtonComponent } from '../react/ui/buttons/JoinVisitButton/join-visit-button.component';
import { LoadingOutlinedButtonComponent } from '../react/ui/buttons/LoadingOutlinedButton/loading-outlined-button.component';

@NgModule({
  declarations: [
    AddressComponent,
    AddressFormComponent,
    AppointmentFormComponent,
    PopoverDirective,
    BackgroundColorDirective,
    MultiLineOptionDirective,
    PopoverComponent,
    CalendarViewAppointmentComponent,
    CalendarViewComponent,
    CompanyFormComponent,
    CompanyMessageDialogComponent,
    CompanyTableComponent,
    ConditionTriStateComponent,
    EligiblePatientListsTableComponent,
    EligiblePatientsTableComponent,
    EnumPipe,
    HomeVisitRequestFormComponent,
    InvoiceComponent,
    LoadingComponent,
    MembershipFormComponent,
    MembershipTableComponent,
    MembershipTypeFormComponent,
    MembershipTypeTableComponent,
    NavComponent,
    NoteModalComponent,
    PatientCardComponent,
    PatientFormComponent,
    PatientInfoComponent,
    PatientTableComponent,
    PhoneNumberPipe,
    PrescriptionCardComponent,
    ProgressButtonComponent,
    ProviderTableComponent,
    ScheduledMessageCardComponent,
    ScheduledMessagesTableComponent,
    ScheduleTableComponent,
    SocialHistoryFormComponent,
    StringToRgbPipe,
    CompanyMessageDialogComponent,
    TagSelectComponent,
    ProviderFormComponent,
    NoteModalComponent,
    SubstanceUseHistoryFormComponent,
    ConditionTriStateComponent,
    MultiToggleComponent,
    PreventativeScreeningFormComponent,
    UploadEligiblePatientButtonComponent,
    ValueLabelPipe,
    InputModalButtonComponent,
    InputModalComponent,
    AutocompleteSearchInputComponent,
    SurgeryListFormComponent,
    ConditionListFormComponent,
    FamilyConditionsFormComponent,
    MultiConditionTriStateComponent,
    ChartRowComponent,
    LoadingAlertComponent,
    AllergyListFormComponent,
    ConfirmDialogComponent,
    FormRepeaterComponent,
    IsOptionUsedPipe,
    FindOptionPipe,
    UrgencySelectComponent,
    MedicalHistoryFormComponent,
    PregnancyFormComponent,
    MedicationListFormComponent,
    AssessmentComponent,
    ClinicalReportingComponent,
    InHouseLabResultsComponent,
    ProceduresPerformedComponent,
    PatientEducationComponent,
    PhysicalExamComponent,
    PlanComponent,
    ReviewOfSystemsComponent,
    ImagingFormComponent,
    LabsFormComponent,
    AddTaskButtonComponent,
    AddTaskModalComponent,
    ProviderToProviderCommsModalComponent,
    IntakeDisplayModalComponent,
    IntakeDisplayButtonComponent,
    YesNoPipe,
    DividerComponent,
    PhysicalTherapyFormComponent,
    ProviderToProviderCommsComponent,
    CreateInvoiceModalComponent,
    InputModalReadonlyDisplayComponent,
    PrescriptionSyncTableComponent,
    TextDialogComponent,
    HomeRequestDisplayPageComponent,
    TableLoadingIndicatorComponent,
    Icd10ListPipe,
    InvoicePatientCardComponent,
    InvoiceDetailsCardComponent,
    InvoiceChartCardComponent,
    AgePipe,
    PatientVitalsComponent,
    MessageSpinnerComponent,
    CommaListPipe,
    KeyValueListComponent,
    ChartNoteComponent,
    SyncedPrescriptionTableComponent,
    ChangeHealthcareSsoButtonComponent,
    DifferentialsAndThoughtsFormComponent,
    ProviderNamePipe,
    ProceduresRequestedFormComponent,
    InHousePocLabsFormComponent,
    ImagePricePipe,
    ApplyMonospaceToZeroDirective,
    ConversationSourcePipe,
    TimeOrDatePipe,
    TimeElapsedPipe,
    MinutesElapsedPipe,
    InViewportNotifierDirective,
    TypingIndicatorComponent,
    ColumnDateDisplayComponent,
    LastExamFormComponent,
    SelectionTableComponent,
    ButtonModalComponent,
    GenericFormModalComponent,
    ViewRefDirective,
    FindInArrayPipe,
    FilterDisplayDateRangePipe,
    FilterDisplayCompareToDefaultPipe,
    EmptyTableMessageComponent,
    DocumentNamePipe,
    TaskFormComponent,
    TableChipComponent,
    CounterBoxComponent,
    CountTextPipe,
    ReferralFormComponent,
    WarningMessageBoxComponent,
    AllergyChecklistComponent,
    MedicationChecklistComponent,
    ImagingChecklistComponent,
    LabChecklistComponent,
    AssessmentChecklistComponent,
    CheckboxTextareaComponent,
    TableFilterChipGroupComponent,
    TooltipOnEllipsesDirective,
    ReverseScrollDirective,
    ScheduleTableFilterFormComponent,
    AppointmentHasChartPipe,
    FormatAddressPipe,
    UserTagComponent,
    JoinArrayPipe,
    ProviderSearchComponent,
    MinutesToHoursAndMinutesPipe,
    AddressBookLookupPipe,
    TableClickBackgroundComponent,
    JoinVisitButtonComponent,
    LoadingOutlinedButtonComponent,
    NoFocusOnClickDirective,
    RescheduleFormComponent,
    ProviderNameAsyncPipe,
    InterventionsFormComponent,
    PaginatedAppointmentListComponent,
    SubjectiveComponent,
    ObjectiveComponent,
    ChartInputSectionComponent,
    EpisodeOfCareComponent,
    MentalStatusComponent,
    PriorMentalHealthTreatmentComponent,
    TranslateTimezonePipe,
    StandardDatePipe,
    ScheduleMessageButtonComponent,
    IgnoreWheelDirective,
    ProviderNameComponent,
    LegacyProviderNameComponent,
    ReactComponentDirective,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FlexLayoutModule,
    FormsModule,
    LinkyModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    RouterModule,
    ImageCropperModule,
    ImageCropperModule,
    MatDatepicker,
    MatDatepickerToggle,
    MatSuffix,
    NgOptimizedImage,
    AsyncPipe,
  ],
  exports: [
    AddressComponent,
    AddressFormComponent,
    AppointmentFormComponent,
    PopoverDirective,
    BackgroundColorDirective,
    MultiLineOptionDirective,
    PopoverComponent,
    CalendarViewAppointmentComponent,
    CalendarViewComponent,
    CommonModule,
    CompanyFormComponent,
    CompanyTableComponent,
    ConditionTriStateComponent,
    EligiblePatientListsTableComponent,
    EligiblePatientsTableComponent,
    EnumPipe,
    FlexLayoutModule,
    ImageCropperModule,
    FormsModule,
    HomeVisitRequestFormComponent,
    InvoiceComponent,
    LinkyModule,
    LoadingComponent,
    MaterialModule,
    MembershipFormComponent,
    MembershipTableComponent,
    MembershipTypeFormComponent,
    MembershipTypeTableComponent,
    NavComponent,
    PatientCardComponent,
    PatientFormComponent,
    PatientInfoComponent,
    PatientTableComponent,
    PhoneNumberPipe,
    PrescriptionCardComponent,
    ProgressButtonComponent,
    ProviderFormComponent,
    ProviderTableComponent,
    ReactiveFormsModule,
    ReferralFormComponent,
    RouterModule,
    ScheduledMessagesTableComponent,
    ScheduleTableComponent,
    StringToRgbPipe,
    SubstanceUseHistoryFormComponent,
    TagSelectComponent,
    TypingIndicatorComponent,
    UploadEligiblePatientButtonComponent,
    SubstanceUseHistoryFormComponent,
    ConditionTriStateComponent,
    MultiToggleComponent,
    PreventativeScreeningFormComponent,
    ValueLabelPipe,
    InputModalComponent,
    AutocompleteSearchInputComponent,
    ConditionListFormComponent,
    FamilyConditionsFormComponent,
    MultiConditionTriStateComponent,
    ChartRowComponent,
    LoadingAlertComponent,
    AllergyListFormComponent,
    ConfirmDialogComponent,
    FormRepeaterComponent,
    UrgencySelectComponent,
    MedicalHistoryFormComponent,
    PregnancyFormComponent,
    MedicationListFormComponent,
    InputModalButtonComponent,
    AssessmentComponent,
    ClinicalReportingComponent,
    InHouseLabResultsComponent,
    ProceduresPerformedComponent,
    PatientEducationComponent,
    PhysicalExamComponent,
    PlanComponent,
    ReviewOfSystemsComponent,
    ImagingFormComponent,
    LabsFormComponent,
    AddTaskButtonComponent,
    ProviderToProviderCommsModalComponent,
    IntakeDisplayModalComponent,
    IntakeDisplayButtonComponent,
    YesNoPipe,
    DividerComponent,
    PhysicalTherapyFormComponent,
    ProviderToProviderCommsComponent,
    TextDialogComponent,
    HomeRequestDisplayPageComponent,
    TableLoadingIndicatorComponent,
    Icd10ListPipe,
    InvoicePatientCardComponent,
    InvoiceDetailsCardComponent,
    InvoiceChartCardComponent,
    AgePipe,
    PatientVitalsComponent,
    MessageSpinnerComponent,
    CommaListPipe,
    KeyValueListComponent,
    DifferentialsAndThoughtsFormComponent,
    ProviderNamePipe,
    ApplyMonospaceToZeroDirective,
    ChangeHealthcareSsoButtonComponent,
    ConversationSourcePipe,
    TimeOrDatePipe,
    TimeElapsedPipe,
    MinutesElapsedPipe,
    InViewportNotifierDirective,
    ColumnDateDisplayComponent,
    LastExamFormComponent,
    SelectionTableComponent,
    ButtonModalComponent,
    ViewRefDirective,
    FindInArrayPipe,
    FilterDisplayDateRangePipe,
    FilterDisplayCompareToDefaultPipe,
    EmptyTableMessageComponent,
    DocumentNamePipe,
    TaskFormComponent,
    TableChipComponent,
    CounterBoxComponent,
    CountTextPipe,
    WarningMessageBoxComponent,
    TableFilterChipGroupComponent,
    TooltipOnEllipsesDirective,
    ReverseScrollDirective,
    AppointmentHasChartPipe,
    FormatAddressPipe,
    UserTagComponent,
    JoinArrayPipe,
    ProviderSearchComponent,
    MinutesToHoursAndMinutesPipe,
    AddressBookLookupPipe,
    TableClickBackgroundComponent,
    JoinVisitButtonComponent,
    LoadingOutlinedButtonComponent,
    NoFocusOnClickDirective,
    RescheduleFormComponent,
    ProviderNameAsyncPipe,
    PaginatedAppointmentListComponent,
    SubjectiveComponent,
    ObjectiveComponent,
    ChartInputSectionComponent,
    EpisodeOfCareComponent,
    MentalStatusComponent,
    PriorMentalHealthTreatmentComponent,
    TranslateTimezonePipe,
    StandardDatePipe,
    ScheduleMessageButtonComponent,
    IgnoreWheelDirective,
    ProviderNameComponent,
    LegacyProviderNameComponent,
    ReactComponentDirective,
  ],
  providers: [ProviderNamePipe, AgePipe, StandardDatePipe, FormatAddressPipe, DocumentNamePipe, provideNgxMask()],
})
export class SharedModule {}

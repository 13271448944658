export enum PreferredContactMethod {
  Phone,
  Email,
}

export class Auth0PrimaryRoles {
  coordinator = 'Legacy-EMR-Coordinator';
  provider = 'Legacy-EMR-Provider';
  providerStudent = 'Legacy-EMR-Provider-Student';

  getAllRoles(): string[] {
    return [this.coordinator, this.provider, this.providerStudent];
  }
}

export function getAuth0PrimaryRoles(): Auth0PrimaryRoles {
  return new Auth0PrimaryRoles();
}

export class Auth0CoordinatorRoles {
  coordinatorAdmin = 'Legacy-EMR-Coordinator-Admin';
  coordinatorScheduling = 'Legacy-EMR-Coordinator-Scheduler';
  coordinatorRegisteredNurse = 'Legacy-EMR-Coordinator-RN';

  getAllRoles(): string[] {
    return [
      this.coordinatorAdmin,
      this.coordinatorScheduling,
      this.coordinatorRegisteredNurse,
    ];
  }

  getRoleGroupByRoleName(careCoordinatorGroup: string): string {
    let roleGroup = '';

    if (careCoordinatorGroup === this.coordinatorAdmin) {
      roleGroup = 'coordinatorAdmin';
    } else if (careCoordinatorGroup === this.coordinatorScheduling) {
      roleGroup = 'coordinatorScheduling';
    } else if (careCoordinatorGroup === this.coordinatorRegisteredNurse) {
      roleGroup = 'coordinatorRegisteredNurse';
    }

    return roleGroup;
  }
}

export function getAuth0CoordinatorRoles(): Auth0CoordinatorRoles {
  return new Auth0CoordinatorRoles();
}

// @todo: in the future this should be moved to the database rather than hardcoded in the front end
export const procedures = [
  {
    name: 'CARDIZEMCD.240MG: CARDIZEM CD 240MG',
    price: 13.99,
    category: 'RX DELIVERY',
    description: '',
  },
  {
    name: 'MED.RX.DELIVERY: RX DELIVERY',
    price: 0.0,
    category: 'RX DELIVERY',
    description: '',
  },
  {
    name: 'SHIP.PROCESSING: S/P NON-INCLUDED RX DELIVERIES',
    price: 10.0,
    category: 'RX DELIVERY',
    description: '',
  },
  {
    name: 'VENTOLIN.INHALER: ALBUTEROL HFA',
    price: 34.99,
    category: 'RX DELIVERY',
    description: '',
  },
  {
    name: 'ECG.INCLUDED: ECG.EKG',
    price: 0.0,
    category: 'PPX INCLUDED',
    description: '',
  },
  {
    name: 'XRAY.ADD.VIEWS: X-RAY ADDITIONAL VIEW',
    price: 0.0,
    category: 'PPX INCLUDED',
    description: '',
  },
  {
    name: 'XRAY.FIRST.VIEW: X-RAY INCLUDED FIRST VIEW',
    price: 0.0,
    category: 'PPX INCLUDED',
    description: '',
  },
  {
    name: 'BLD.DRAW.SUCCESS:',
    price: 0.0,
    category: 'Phlebotomy',
    description: '',
  },
  {
    name: 'BLDDRAW.UNSUCCES:',
    price: 0.0,
    category: 'Phlebotomy',
    description: '',
  },
  {
    name: 'ECG.SELF.PAY: ECG.PAY',
    price: 140.0,
    category: 'PAY PPX',
    description: '',
  },
  {
    name: 'ULTRASOUND.PAY:',
    price: 259.0,
    category: 'PAY PPX',
    description: '',
  },
  {
    name: 'XRAY.SELF.PAY: XRAY',
    price: 175.0,
    category: 'PAY PPX',
    description: '',
  },
  {
    name: '17.HYDROXYPROGES: 13358',
    price: 18.9,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'ABO.RH.TYPE: 6060',
    price: 15.54,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'ACUTE.HEP.PANEL: 696',
    price: 54.18,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'ANAPLASM.ERHLICH: 2785',
    price: 115.5,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'ANTI.TTG.ANTIBOD: 4888',
    price: 44.8,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'BETA.HYDROXY: 994',
    price: 56.42,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'BILIRUB.DIRECT.: QUEST',
    price: 2.98,
    category: 'PAY LAB',
    description: 'QUEST',
  },
  {
    name: 'BILIRUB.TOT.DIR.: 2366',
    price: 14.98,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'BILIRUB.TOTAL.QU: 287',
    price: 2.98,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'BILIRUBIN.DIRECT: 47',
    price: 12.32,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'BILIRUBIN.TOTAL: 46',
    price: 2.66,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'BNP.PEPTIDE: 5531',
    price: 64.12,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'C.PEPTIDE: 265',
    price: 15.54,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'CARBAMEZAPINE: 952',
    price: 17.78,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'CORTISOL.TOTAL: 8813',
    price: 13.3,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'CRP.CARDIAC.HS: 5664',
    price: 23.94,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'D.DIMER: 3433',
    price: 39.2,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'DHEA.S: 285',
    price: 32.34,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'DILANTIN.LEVEL: 91',
    price: 17.08,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'EBV.PANEL: 1809',
    price: 62.16,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'ESTRADIOL: 288',
    price: 24.22,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'FREE.T3: 388',
    price: 19.04,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'FSH: 104',
    price: 13.16,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'GONO.CHLAM.QUEST: 11363',
    price: 51.66,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'GONORR.CHLAMYDIA: 6746',
    price: 51.24,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'H.PYLORI.QUEST: 34838',
    price: 91.13,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'H.PYLORI.STOOL: 4767',
    price: 90.44,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'H.PYLORI.UBT.: 36558',
    price: 46.65,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'HEPATITIS.B.IMMU: 686',
    price: 12.88,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'HERPES.HSV: 2784A',
    price: 44.24,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'HETEROPHILE.QUES: 654',
    price: 32.67,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'HIV.AG.AB: 533',
    price: 21.42,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'HOMOCYSTEINE: 4775',
    price: 51.94,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'INR: 487',
    price: 6.44,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'INSULIN: 141',
    price: 17.78,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'INSULIN.ANTIBODY: 13402',
    price: 32.2,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'IONIZED.CALCIUM: 8141',
    price: 13.58,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'KOH.CX.YEAST: 4605',
    price: 85.81,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'LH: 171',
    price: 13.16,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'LIPOPROTEIN.A: 2630A',
    price: 24.22,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'LYME.W.B.QUEST: 6646',
    price: 40.54,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'LYME.WESTRN.BLOT: 659',
    price: 20.44,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'MICROSCOPIC.UA: 3399',
    price: 7.84,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'MMA: 13583',
    price: 23.8,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'MRSA.CULTURE: 6578',
    price: 16.0,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'MUMPS.IMMUNE: 524',
    price: 15.4,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'MYOGLOBIN: 7616',
    price: 17.08,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PERIPHER.SMEAR: 833',
    price: 61.35,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PERIPHERAL.SMEAR: 7931',
    price: 62.44,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PROCALCITONIN: 8558',
    price: 256.48,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PROGESTERONE: 257',
    price: 20.3,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PROLACTIN: 215',
    price: 19.95,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PROTEIN.CREATINI: 2805',
    price: 19.18,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PT.W.INR.QUEST: 8847',
    price: 8.2,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PTH.PARATHYROID: 2317',
    price: 18.48,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PTT: 490',
    price: 6.44,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'PTT.QUEST: 763',
    price: 8.54,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'QUEST.TTG.IGA: 8821',
    price: 45.92,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'RESP.ALRGY.PANEL: 12653',
    price: 146.72,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'REVERSE.T3: 13359',
    price: 20.3,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'RPR.QUEST: 799',
    price: 7.18,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'RUBELLA.IMMUNE: 713',
    price: 12.04,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'RUBEOLA.IMMUNE: 1812',
    price: 16.8,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'SERUM.HCG: 683',
    price: 14.28,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'SEX.HORMONE.BG: 13363',
    price: 12.6,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'STOOL.CX.QUEST: 10108',
    price: 126.07,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'SYPHILIS.SCREEN: 8325',
    price: 7.14,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'T3.TOTAL: 845',
    price: 15.68,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TB.QUANTIFERON: 12450',
    price: 92.54,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TEST.VITAMIN: vitamin test',
    price: 21.0,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TESTOS.FREE.QUES: 13510',
    price: 29.4,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TESTOS.TOTQUEST: 873',
    price: 30.8,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TESTOST.FREE.TOT: 646',
    price: 29.4,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TESTOSTERONE.TOT: 44',
    price: 16.8,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TPO.THYROID.PYRO: 4784',
    price: 9.38,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'TTG.IGA: 2993',
    price: 15.26,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'UA.REFLEX.MICRO: 399',
    price: 6.3,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'VAGINAL.DNA.SWAB: 7763',
    price: 130.9,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'VALPROIC.ACID: 949',
    price: 19.88,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'VARICELLA.IMMUNE: 6647',
    price: 18.62,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'VITAMIN.D: 113A',
    price: 34.16,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'VITAMIN.D.QUEST: 17306',
    price: 34.16,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'WOUND.AEROBIC.CX: 12423',
    price: 15.68,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'WOUND.ANAEROB.CX: 6554',
    price: 42.7,
    category: 'PAY LAB',
    description: '',
  },
  {
    name: 'ADVANCED.IMAGING: IMAGING',
    price: 0.0,
    category: 'PATIENT REFERRAL',
    description: '',
  },
  {
    name: 'REFER.SPECIALIST: REFERRAL TO SPECIALIST',
    price: 0.0,
    category: 'PATIENT REFERRAL',
    description: '',
  },
  {
    name: 'REFER.UC.OR.ER: REFERRED HIGHER LEVEL OF CARE',
    price: 0.0,
    category: 'PATIENT REFERRAL',
    description: '',
  },
  {
    name: 'A1C.QUEST: 496',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'AMYLASE.QUEST: 243',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'ANA.QUEST: 249',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'BMP.QUEST: 10165',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: 10165,
  },
  {
    name: 'CBC.QUEST: 1759',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'CMP.QUEST: 10231',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'CPK.QUEST: 374',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'CRP.QUEST: 4420',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'ESR.QUEST: 809',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'FERRITIN.QUEST: 457',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'FOLATE.QUEST: 466',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'FREE.T4.QUEST: 866',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'GGT.QUEST: 482',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'GLUCOSE.QUEST: 483',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'HEMOGLOBIN.QUEST: 510',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'IRON.QUEST: 571',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'LEAD.QUEST: 599',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'LIPASE.QUEST: 606',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'LIPIDS.QUEST: 14852',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'MAGNESIUM.QUEST: 622',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'PSA.QUEST: 5363',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'RHEUMA.FAC.QUEST: 4418',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'THROAT.CX.QUEST: 394',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'TIBC.QUEST: 7573',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'TSH.QUEST: 899',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'TSH.REFLEX.QUEST: 36127',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'URIC.ACID.QUEST: 905',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'URINE.CX.QUEST: 395',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'URINE.MICR.QUEST: 6517',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'VIT.B12.QUEST: 927',
    price: 0.0,
    category: 'Included Labs - Quest',
    description: '',
  },
  {
    name: 'A1C: 8761',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 8761,
  },
  {
    name: 'AMYLASE: 31',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 31,
  },
  {
    name: 'ANA: 12467',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 12467,
  },
  {
    name: 'ANA.TITER: reflex from ana screen',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: '',
  },
  {
    name: 'BMP: 7',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 7,
  },
  {
    name: 'CBC: 400',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 400,
  },
  {
    name: 'CK.TOTAL: 80',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 80,
  },
  {
    name: 'CMP: 95',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 95,
  },
  {
    name: 'CRP: 674',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 674,
  },
  {
    name: 'ESR: 12710',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 12710,
  },
  {
    name: 'FERRITIN: 102',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 102,
  },
  {
    name: 'FOLATE.FOLICACID: 103',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 103,
  },
  {
    name: 'FREE.T4: 251',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 251,
  },
  {
    name: 'GGT: 114',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 114,
  },
  {
    name: 'GLUCOSE: 109',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 109,
  },
  {
    name: 'HEMOGLOBIN: 450',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 450,
  },
  {
    name: 'ID.AEROBE: Strep culture reflex',
    price: 6.7,
    category: 'Included Labs - Allina',
    description: '',
  },
  {
    name: 'IRON: 1144',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 1144,
  },
  {
    name: 'IRON.TOTAL.TIBC: 100',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 100,
  },
  {
    name: 'LEAD.LEVEL: 147',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 147,
  },
  {
    name: 'LIPASE: 157',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 157,
  },
  {
    name: 'LIPID.PANEL: 160',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 160,
  },
  {
    name: 'MAGNESIUM: 175',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 175,
  },
  {
    name: 'MONO.HETEROPHILE: 701',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: '',
  },
  {
    name: 'MONO.SPOT: POC',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 'POC',
  },
  {
    name: 'PREGNANCY.URINE: POC',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 'POC',
  },
  {
    name: 'PSA: 1102',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 1102,
  },
  {
    name: 'RAPID.INFLUENZA: POC',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 'POC',
  },
  {
    name: 'RAPID.STREP: POC',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 'POC',
  },
  {
    name: 'RHEUMATOID.FACTO: 711',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 711,
  },
  {
    name: 'TSH: 258',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 258,
  },
  {
    name: 'TSH.REFLEX.FT4: 8960',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: '',
  },
  {
    name: 'URIC.ACID: 262',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 262,
  },
  {
    name: 'URINALYSIS: POC',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 'POC',
  },
  {
    name: 'URINE.CULTURE: 6564',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 6564,
  },
  {
    name: 'URINE.MICROA.POC: POC URINE MICROALBUMIN',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: '',
  },
  {
    name: 'URINE.MICROALBUM: 630',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 630,
  },
  {
    name: 'URINE.PID:',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: '',
  },
  {
    name: 'URINE.VITEX.SENS:',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: '',
  },
  {
    name: 'VITAMIN.B12: 272',
    price: 0.0,
    category: 'Included Labs - Allina',
    description: 272,
  },
  {
    name: 'BIOMETRIC: Biometric Visit',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'ERX.PROVIDED: PRESCRIPTION ORDERED',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'FULLY.SUPPLIED:',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'HOME.VISIT.ORDER: IN PERSON VISIT ORDERED',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'INSURANCE.LABS: LABS THROUGH INSURANCE',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'MISSING.SUPPLIES:',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'MOBILE.PHLEBOT:',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'VISIT.FEE: $5 PER VISIT FEE',
    price: 5.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'WAIVE.VISIT.FEE: NO FEE FOR VISIT',
    price: 0.0,
    category: 'BILLING NOTE',
    description: '',
  },
  {
    name: 'PSC.QUEST:',
    price: 0.0,
    category: 'BILLING CODE',
    description: '',
  },
];

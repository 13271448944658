import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProviderFormDto, ProviderProfileDto } from '@dto';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PaginationInformation } from '../../shared/server-side-paginated-table/datasources/generic.datasource';
import { ProvidersIndexed } from '@utility';
import { PaginatedResponse } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  public providers: Observable<ProviderProfileDto[]>;
  public _providerInactive = new BehaviorSubject<boolean>(false);

  public providerListByUserId = new BehaviorSubject<ProvidersIndexed>(null);
  public providerListById = new BehaviorSubject<ProvidersIndexed>(null);

  private _providers = new BehaviorSubject<ProviderProfileDto[]>([]);

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
  ) {
    this.providers = this._providers.asObservable();

    this.authService.authState
      .pipe(
        switchMap((authState) => {
          if (authState === null) {
            return of([]);
          }
          return this.getProviders();
        }),
      )
      .subscribe(async (providers) => {
        this._providers.next(providers);
        this.populatedIndexedLists(providers);
        if (authService.currentUserId) {
          const { deleted } = await this.requestProviderById(authService.currentUserId).pipe(take(1)).toPromise();
          this._providerInactive.next(deleted);
        }
      });
  }

  get currentProviderList(): ProviderProfileDto[] {
    return this._providers.getValue();
  }

  public searchForProviderByNameOrId(search: string) {
    let queryParams = new HttpParams({
      fromObject: {
        search,
        sortBy: 'firstName',
        sortDirection: 'ASC',
        limit: '10',
        page: '0',
      },
    });

    queryParams = queryParams.append('searchBy[]', 'id');
    queryParams = queryParams.append('searchBy[]', 'name');

    return this.getProvidersPaginated(queryParams).pipe(
      map((res) => {
        return res.data.map((providerProfile) => {
          return {
            ...providerProfile,
            label: `${providerProfile.user.firstName} ${providerProfile.user.lastName}${
              providerProfile.credentials && providerProfile.credentials.length > 0
                ? `, ${providerProfile.credentials.join(', ')}`
                : ''
            }`,
          };
        });
      }),
    );
  }

  // Has to access a new v3 api endpoint
  public getProvidersPaginated(queryParams: HttpParams, pagination?: PaginationInformation) {
    if (pagination) {
      queryParams = queryParams.append('showInactive', pagination?.filters.showInactive as string);
    }
    const url = environment.apiV3Url.concat('/providers');
    return this.httpClient.get<PaginatedResponse<ProviderProfileDto>>(url, {
      params: queryParams,
    });
  }

  public getProviderByProviderId(providerId: number): ProviderProfileDto {
    const listProvider = this.currentProviderList.find((provider) => provider.id === providerId);
    if (listProvider) {
      return listProvider;
    }
    return null;
  }

  public getProviderById(userId: string): ProviderProfileDto {
    const listProvider = this.currentProviderList.find((provider) => provider.user.id === userId);
    if (listProvider) {
      return listProvider;
    }
    return null;
  }

  public getProviderByAcuityCalendarId(acuityCalendarId: string): ProviderProfileDto {
    const targetProvider = this.currentProviderList.find((provider) => {
      return provider.acuityCalendarId === acuityCalendarId;
    });
    if (targetProvider) {
      return targetProvider;
    }
    return null;
  }

  public requestProviderById(userId: string) {
    const url = environment.apiV3Url.concat(`/providers/byUserId/${userId}`);
    return this.httpClient.get<ProviderProfileDto>(url);
  }

  getProviders() {
    const url = environment.apiUrl.concat('/provider-profiles');
    return this.httpClient.get<ProviderProfileDto[]>(url);
  }

  getProvider(providerId) {
    const url = environment.apiUrl.concat(`/provider-profiles/${providerId}`);
    return this.httpClient.get<ProviderProfileDto>(url);
  }

  createProvider(data: ProviderFormDto): Observable<ProviderProfileDto> {
    const url = environment.apiV3Url.concat('/providers');
    return this.httpClient.post<ProviderProfileDto>(url, data).pipe(tap(() => this.refreshProviders()));
  }

  editProvider(data, id) {
    const url = environment.apiUrl.concat(`/provider-profiles/${id}`);
    return this.httpClient.put(url, data, { responseType: 'text' }).pipe(tap(() => this.refreshProviders()));
  }

  refreshProviders() {
    return this.getProviders()
      .toPromise()
      .then((providers) => this._providers.next(providers));
  }

  toggleProviderSmsNotifications(profileId: number | string, smsNotificationsEnabled: boolean) {
    const url = environment.apiUrl.concat(`/provider-profiles/${profileId}/sms`);
    return this.httpClient.put<ProviderProfileDto>(url, {
      smsNotificationsEnabled,
    });
  }

  toggleProviderDeleted(profileId: number | string, deleted: boolean) {
    const url = environment.apiUrl.concat(`/provider-profiles/${profileId}/deleted`);
    return this.httpClient.put<ProviderProfileDto>(url, { deleted });
  }

  checkForDuplicateNpiNumber(npi_number: string) {
    const url = environment.apiV3Url.concat(`/providers/duplicate-check/npi-number/${npi_number}`);
    return this.httpClient.get<boolean>(url);
  }

  checkForDuplicateAcuityId(acuityCalendarId: string) {
    const url = environment.apiV3Url.concat(`/providers/duplicate-check/acuity-calendar-id/${acuityCalendarId}`);
    return this.httpClient.get<boolean>(url);
  }

  checkForDuplicateAuth0(auth0Id: string) {
    const url = environment.apiV3Url.concat(`/providers/duplicate-check/auth0-id/${auth0Id}`);
    return this.httpClient.get<boolean>(url);
  }

  checkForDuplicateChangeHealthcareId(changeHealthcareUserId: string) {
    const url = environment.apiV3Url.concat(
      `/providers/duplicate-check/change-healthcare-user-id/${changeHealthcareUserId}`,
    );
    return this.httpClient.get<boolean>(url);
  }

  private populatedIndexedLists(providers: ProviderProfileDto[]) {
    this.providerListByUserId.next(
      providers.reduce<ProvidersIndexed>((aggObject: ProvidersIndexed, profile) => {
        return Object.assign(aggObject, {
          [profile.user.id]: profile,
        });
      }, {}),
    );

    this.providerListById.next(
      providers.reduce<ProvidersIndexed>((aggObject: ProvidersIndexed, profile) => {
        return Object.assign(aggObject, {
          [profile.id.toString()]: profile,
        });
      }, {}),
    );
  }
}

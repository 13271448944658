import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { VersionInterceptor } from './version.interceptor';
import { RetryInterceptor } from './retry.interceptor';
import { CachingInterceptor } from './caching.interceptor';
import { ReplaceCharactersInterceptor } from './replace-characters.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ReplaceCharactersInterceptor,
    multi: true,
  },
];

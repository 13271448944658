import { DateTime } from 'luxon';
import { booleanToString } from '../../../utility';
import { PhysicalExamFormConfiguration } from '../../../constants';
import { PatientChartPayload, PhysicalExamFormGroup } from '../../../dto';
import { DetailsData } from '../assets';
import { TreatmentPlan } from '../treatment-plan';
import { PatientFormOptions } from '../../../enums';

export function treatmentPlanPhysicalExam(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const physicalExam = chart.physicalExam;

  const fieldsUsed: (keyof PhysicalExamFormGroup)[] = [
    ...PhysicalExamFormConfiguration.map((group) => group.formGroupName),
    'otherGroup',
    'formsCompletedGroup',
  ];

  // If no fields referenced have content, proceed without adding any content
  if (
    !fieldsUsed.some((field) => {
      const group = physicalExam[field];
      return (
        pdf.hasContent({
          ...group,
          deferred: null, // we check deferred field for content separately below
        }) ||
        ('deferred' in group && group.deferred === true)
      ); // only count the deferred field as having content if it is `true`
    })
  ) {
    return;
  }

  pdf.addHeaderContent({
    text: 'Physical Exam',
  });

  for (const section of PhysicalExamFormConfiguration) {
    const sectionGroup = physicalExam[section.formGroupName];
    if (
      pdf.hasContent([
        // @ts-ignore
        sectionGroup[section.formControlName],
        sectionGroup.note,
      ]) ||
      sectionGroup.isHealthy ||
      ('deferred' in sectionGroup && sectionGroup.deferred)
    ) {
      const title = section.heading;
      pdf.addSubSectionHeader(title);

      if ('deferred' in sectionGroup && sectionGroup.deferred) {
        pdf.addParagraphContent({
          text: 'Deferred',
        });
      } else if (sectionGroup.isHealthy) {
        pdf.addParagraphContent({
          text: 'Healthy/WNL',
        });
      }

      const details = pdf.mapOptionsToDetails(
        section.options,
        // @ts-ignore
        physicalExam[section.formGroupName][section.formControlName],
        (_, data) => booleanToString(data as boolean),
        (_, data) => pdf.hasContent(data),
      );

      pdf.addDetailsContent({
        details,
        style: 'table',
      });

      pdf.addGroupNote(`${section.heading} Notes`, sectionGroup);
    }
  }

  // Other Group
  const otherGroup = physicalExam.otherGroup;
  const hasOtherGroupFreeText = otherGroup?.other?.trim()?.length;
  const hasOtherGroupNote = otherGroup?.note?.trim()?.length;
  if (hasOtherGroupFreeText || hasOtherGroupNote) {
    const text = '';
    if (hasOtherGroupFreeText) {
      text.concat(otherGroup.other || '');
    }
    if (hasOtherGroupNote) {
      text.concat(text.length ? '\n' : ''); // Add break between text blocks
      text.concat(otherGroup.note || '');
    }
    pdf.addParagraphContent({
      title: 'Other',
      text: otherGroup.other || '',
    });
  }

  // Forms Completed Section
  const formsCompletedGroup = physicalExam.formsCompletedGroup;
  const formsCompleted = formsCompletedGroup?.formsCompleted;
  const hasCompletedForms = formsCompleted ? formsCompleted.length > 0 : false;
  const hasCompletedFormsNote = formsCompletedGroup?.note && formsCompletedGroup?.note?.trim().length;
  if (pdf.hasContent(physicalExam.formsCompletedGroup)) {
    const title = 'Completed Forms';
    // If only a top level note in pdf section just use a paragraph section
    if (!hasCompletedForms) {
      pdf.addParagraphContent({
        title,
        text: formsCompletedGroup.note || '',
      });
    } else if (formsCompleted) {
      // If any form completed item has notes we need to use a table
      // Otherwise we use a details list
      const hasNotes = formsCompleted.some((form) => {
        return form.note && form.note.trim().length;
      });

      formsCompleted.forEach((form) => {
        if (typeof form.date === 'string') {
          DateTime.fromISO(form.date).toISO;
        }
      });

      if (hasNotes) {
        const tableData = formsCompleted.map((form) => ({
          // @ts-ignore
          form: PatientFormOptions[form.key],
          date: form.date,
          note: form.note,
        }));

        // Add the top level note to the bottom of the table
        if (hasCompletedFormsNote) {
          tableData.push({
            form: 'n/a',
            date: '',
            note: formsCompletedGroup.note || '',
          });
        }

        pdf.addTableContent({
          title,
          columnHeaders: ['Form', 'Date Completed', 'Note'],
          columnKeys: ['form', 'date', 'note'],
          tableData,
        });
      } else {
        const details: DetailsData['details'] = formsCompleted.map((form) => ({
          // @ts-ignore
          key: PatientFormOptions[form.key],
          value: form.date,
        }));

        if (hasCompletedFormsNote) {
          details.push({
            key: 'Note',
            value: formsCompletedGroup.note || '',
          });
        }
        pdf.addDetailsContent({
          title: { text: title },
          details,
          style: 'table',
        });
      }
    }
  }
}

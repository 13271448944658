import { ProviderProfileDto } from '../dto/provider';

export type InvalidOptions = 'omitInvalid' | 'keepInvalid';
export type FormatOptions = 'withCredentials' | 'noCredentials' | 'initials';
export interface IndexedProviderLists {
  userIdList: ProvidersIndexed;
  providerIdList: ProvidersIndexed;
}

export type ProviderIdInput = (number | string) | (string | number)[];
export interface ProvidersIndexed {
  [key: string]: ProviderProfileDto;
}

export function transformUserIds(
  userIds: ProviderIdInput,
  format: FormatOptions,
  invalidHandling: InvalidOptions,
  lists: IndexedProviderLists
): (string | number)[] | undefined {
  let transformedUserIds: (string | number)[];
  // If omitting invalid ids, filter them here
  if (invalidHandling === 'omitInvalid') {
    // eslint-disable-next-line no-param-reassign
    transformedUserIds = filterInvalidIds(userIds, lists);
    transformedUserIds = transformedUserIds.map((id) => translateUserId(id, format, lists));
  } else {
    transformedUserIds = Array.isArray(userIds)
      ? userIds.map((id) => translateUserId(id, format, lists))
      : [translateUserId(userIds, format, lists)];
  }
  // Halt if no passable ids exist
  if (transformedUserIds.length <= 0) {
    return;
  }

  return transformedUserIds;
}

export function findProviderProfile(userId: string | number, lists: IndexedProviderLists) {
  if (userId === undefined || userId === null) {
    return;
  }
  switch (typeof userId) {
    case 'string':
      return lists.userIdList[userId];
    case 'number':
      return lists.providerIdList[userId.toString()];
  }
}

export function filterInvalidIds(userId: ProviderIdInput, lists: IndexedProviderLists): (string | number)[] {
  if (Array.isArray(userId)) {
    return userId.filter((id) => findProviderProfile(id, lists));
  } else {
    return findProviderProfile(userId, lists) ? [userId] : [];
  }
}

export function translateUserId(
  userId: string | number,
  format: FormatOptions,
  lists: IndexedProviderLists
): string | number {
  const providerProfile = findProviderProfile(userId, lists);
  if (providerProfile) {
    const { firstName, lastName } = providerProfile.user;
    switch (format) {
      case 'withCredentials':
        const credentials = providerProfile.credentials?.join(', ');
        return `${firstName} ${lastName}${credentials ? `, ${credentials}` : ''}`;

      case 'noCredentials':
        return `${firstName} ${lastName}`;

      case 'initials':
        const value = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
        return value;
    }
  } else {
    // Returns the plain userId if not profile exists
    return userId;
  }
}

import { MembershipTypeDto } from '../dto/membership-type';

export class MembershipType {
  public readonly id: number;
  public readonly membershipName: string;
  public readonly chatEligible: boolean;
  public readonly videoEligible: boolean;
  public readonly homeVisitEligible: boolean;
  public readonly imagingEligible: boolean;
  public readonly copayAmount: string;
  public readonly physicalTherapyEligible: boolean;
  public readonly rxCardEligible: boolean;
  public readonly labsEligible: boolean;
  public readonly mentalTherapyEligible: boolean;

  constructor(data: MembershipTypeDto) {
    Object.assign(this, data);
  }
}

<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxFlex
  fxFill
  class="background-primary"
>
  <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
    <img src="../../../assets/images/logo.png" class="nice-logo" />
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxFlex>
    <div fxLayout="column" fxLayoutAlign="start space-around">
      <h2>Nice Healthcare Team</h2>
      <button mat-raised-button color="accent" (click)="login()">LOG IN</button>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Patient } from '@models';
import { PatientService } from '../../core/services/patient.service';
import { AlertService } from '../../core/services/alert.service';

interface EligibilityOption {
  label: string;
  active: boolean;
}

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientInfoComponent {
  @Input() set patient(patient: Patient) {
    this._patient = patient;
    this.updateMembershipInfo();
  }
  @Input() opened: boolean;

  public submitting = false;
  public noteInitialValue: string;
  public eligibilityList: EligibilityOption[];

  private _patient: Patient;

  constructor(
    private patientService: PatientService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {}

  get patient() {
    return this._patient;
  }

  public async saveNote() {
    if (this.submitting) {
      return;
    }
    try {
      this.submitting = true;
      await this.patientService.addPatientNote(this.patient.patientNote, this.patient.id).toPromise();
      await this.alertService.success(`Successfully saved note for ${this.patient.firstName} ${this.patient.lastName}`);
      this.noteInitialValue = this.patient.patientNote;
    } catch (error) {
      this.alertService.error('Error saving patient note. Please try again.');
    } finally {
      this.submitting = false;
      this.cdr.markForCheck();
    }
  }

  private updateMembershipInfo() {
    this.noteInitialValue = this.patient.patientNote;

    const { verified, active } = this.patient.membership;
    const {
      chatEligible,
      videoEligible,
      homeVisitEligible,
      imagingEligible,
      physicalTherapyEligible,
      rxCardEligible,
      labsEligible,
      mentalTherapyEligible,
    } = this.patient.membership.membershipType || {};

    this.eligibilityList = [
      {
        label: 'Verified',
        active: verified,
      },
      {
        label: 'Active',
        active: active,
      },
      {
        label: 'Chat',
        active: chatEligible,
      },
      {
        label: 'Video',
        active: videoEligible,
      },
      {
        label: 'Home Visit',
        active: homeVisitEligible,
      },
      {
        label: 'Imaging',
        active: imagingEligible,
      },
      {
        label: 'Mental Health',
        active: mentalTherapyEligible,
      },
      {
        label: 'Labs',
        active: labsEligible,
      },
      {
        label: 'Phys Therapy',
        active: physicalTherapyEligible,
      },
      {
        label: 'Rx',
        active: rxCardEligible,
      },
    ];

    this.cdr.markForCheck();
  }
}

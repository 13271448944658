import { PatientChartPayload } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';

export function treatmentPlanPatientVitals(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const vitals = chart.patientVitals;

  if (vitals) {
    const fieldsUsed = [
      'temperature',
      'pulse',
      'systolicBloodPressure',
      'diastolicBloodPressure',
      'respiratoryRate',
      'oxygenSaturation',
      'height',
      'weight',
      'bmi',
      'pain',
      'headCircumference',
      'smokingStatus',
      'PHQ9',
      'GAD7',
      'ACT',
      'A1C',
      'note',
    ] as const;

    if (!fieldsUsed.some((field) => pdf.hasContent((vitals as any)[field]))) {
      return;
    }

    pdf.addHeaderContent({ text: 'Patient Vitals' });

    const addUnit = (measurement: string | number | undefined, unit: string) => {
      return pdf.hasContent(measurement) ? `${measurement}${unit}` : '';
    };

    const fields = {
      temperature: addUnit(vitals.temperature, ' F'),
      pulse: addUnit(vitals.pulse, ' bpm'),
      bloodPressure:
        pdf.hasContent(vitals.systolicBloodPressure) && pdf.hasContent(vitals.diastolicBloodPressure)
          ? `${vitals.systolicBloodPressure} / ${vitals.diastolicBloodPressure} mmHg`
          : '',
      respiratoryRate: addUnit(vitals.respiratoryRate, ' Rpm'),
      oxygenSaturation: addUnit(vitals.oxygenSaturation, '%'),
      height: addUnit(vitals.height, ' in'),
      weight: addUnit(vitals.weight, ' lb'),
      BMI: addUnit(vitals.bmi, ' lbs/in2'),
      pain: vitals.pain?.toString() || '',
      headCircumference: addUnit(vitals.headCircumference, ' in'),
      PHQ9: vitals.PHQ9?.toString() || '',
      GAD7: vitals.GAD7?.toString() || '',
      ACT: vitals.ACT?.toString() || '',
      A1C: addUnit(vitals.A1C, '%'),
      note: vitals.note || '',
    };

    pdf.addDetailsSectionFromRecord(fields);
  }
}

import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import { isNil, isObject, startCase } from 'lodash';
import { Margins, PageOrientation } from 'pdfmake/interfaces';

import { deepFlattenCollection } from '../../utility';

import { Alignment, DetailsData, FontSize, pdfStyleDefinitions } from './assets';
import { NicePdfGenerator } from './nice-pdf-generator';
import { TreatmentPlanScope } from '../../enums';
import { TreatmentPlanPayload } from './treatment-plan-content';

export class MentalHealthTreatmentPlan extends NicePdfGenerator {
  public static getTreatmentPlanTimeFormat(timeZone: string): LocaleOptions & DateTimeFormatOptions {
    return {
      timeZone,
      timeZoneName: 'short',
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
  }

  public static getTreatmentPlanFileName({
    patientId,
    appointmentId,
    scheduledAppointmentTime,
    treatmentPlanScope,
    fileExtension = 'pdf',
  }: {
    patientId: string | number | object;
    appointmentId: string;
    scheduledAppointmentTime: Date;
    treatmentPlanScope: TreatmentPlanScope;
    fileExtension?: string;
  }) {
    const timestamp = DateTime.fromJSDate(scheduledAppointmentTime).toUTC().toISO();

    const type = treatmentPlanScope.toLocaleUpperCase().replace(' ', '_');

    return `${patientId}/${type}_${appointmentId}_${timestamp}.${fileExtension}`;
  }

  private isFullTreatmentPlan = false;

  constructor() {
    super();
    this.docConfiguration.pageOrientation = 'portrait' as PageOrientation;
    this.docConfiguration.pageMargins = 36;
  }

  define(data: TreatmentPlanPayload): void {
    // Create details section
    const credentials = data.provider.credentials?.length ? `, ${data.provider.credentials.join(', ')}` : '';
    const providerName = `${data.provider.firstName} ${data.provider.lastName}`;
    const patientName = `${data.patient.firstName} ${data.patient.lastName}`;
    const patientDOB = data.patient.dateOfBirth;

    this.docConfiguration.footer = NicePdfGenerator.getDynamicFooterContent(patientName, patientDOB);

    const visitDate = DateTime.fromJSDate(data.visitDate, {
      zone: data.patientTimezone,
    }).toLocaleString(MentalHealthTreatmentPlan.getTreatmentPlanTimeFormat(data.patientTimezone));

    this.addHeaderContent({
      text: 'Mental Health Treatment Plan',
      alignment: Alignment.Center,
    });

    this.addDetailsContent({
      details: [
        { key: 'Patient:', value: patientName },
        { key: 'Visit Date:', value: visitDate },
        { key: 'Provider:', value: `${providerName}${credentials}` },
      ],
      margin: [0, 0, 0, 8],
    });

    this.isFullTreatmentPlan = data.treatmentPlanScope === TreatmentPlanScope.full;

    this.addHorizontalDivider();

    if (data?.episodeOfCare?.treatmentGoals) {
      this.addHeaderContent({
        text: 'Treatment Goals',
      });

      this.addParagraphContent({
        text: data.episodeOfCare.treatmentGoals,
      });
    }

    if (data?.episodeOfCare?.treatmentObjectives) {
      this.addHeaderContent({
        text: 'Treatment Objectives',
      });

      this.addParagraphContent({
        text: data.episodeOfCare.treatmentObjectives,
      });
    }

    if (data?.episodeOfCare?.plannedInterventions) {
      this.addHeaderContent({
        text: 'Planned Interventions',
      });

      this.addParagraphContent({
        text: data.episodeOfCare.plannedInterventions,
      });
    }

    if (data?.episodeOfCare?.strengths) {
      const strengthsContent: string[] = data?.episodeOfCare?.strengths ? data.episodeOfCare.strengths : [];

      if (strengthsContent.length > 0) {
        this.addHeaderContent({
          text: "Client's Strengths/Resources",
        });
        this.addListContent({
          listItems: strengthsContent,
        });
      }
    }

    this.addParagraphContent({
      text: 'Provider Signature',
      bold: true,
      fontSize: FontSize.Content,
    });

    if (data.signatureBase64) {
      this.addImageContent({
        base64: data.signatureBase64,
        maxHeight: 100,
        maxWidth: 300,
      });
    } else {
      this.addParagraphContent({
        text: '_'.repeat(25), // magic number for roughly the length of the header....
        fontSize: FontSize.Content,
      });
    }

    const prettyprintdate = DateTime.fromJSDate(DateTime.now().toJSDate(), {
      zone: data.patientTimezone,
    }).toLocaleString(MentalHealthTreatmentPlan.getTreatmentPlanTimeFormat(data.patientTimezone));
    this.addMixedStyleParagraphContent(
      [
        { text: 'Date', bold: true, margin: [0, 4] as Margins },
        { text: '    ', margin: [0, 4] as Margins }, // give us some spacing after date before putting a text field...
        { text: prettyprintdate, margin: [0, 4] as Margins },
      ],
      [0, 4] as Margins,
    );
  }

  public addSubSectionHeader(text: string) {
    this.addHeaderContent({
      text,
      fontSize: FontSize.Medium,
      margin: [0, 4],
    });
  }

  public addMedicalHistorySubheading(heading: string, lastUpdatedOn: Date | string) {
    // Add treatment plan header.
    this.addHeaderContent({ text: heading });

    const medicalHistoryLastUpdatedDate = MentalHealthTreatmentPlan.getDateTime(lastUpdatedOn);

    if (medicalHistoryLastUpdatedDate === undefined || medicalHistoryLastUpdatedDate === null) {
      // We don't want to render this with an invalid date.
      throw new Error(`Invalid medical history timestamp provided: ${lastUpdatedOn}`);
    }

    this.addHeaderContent({
      ...pdfStyleDefinitions.label,
      text: `As of ${medicalHistoryLastUpdatedDate.toFormat('MM/dd/yyyy')} at ${medicalHistoryLastUpdatedDate.toFormat(
        'h:mm a',
      )}`,
      style: 'header',
    });
  }

  public addTreatmentPlanSubheading(treatmentDate: Date) {
    // Add treatment plan header.
    this.addHeaderContent({ text: 'Treatment Plan' });

    const treatmentTimestamp = MentalHealthTreatmentPlan.getDateTime(treatmentDate);

    if (treatmentTimestamp === undefined || treatmentTimestamp === null) {
      // We don't want to render this with an invalid date.
      throw new Error(`Invalid treatment date timestamp provided: ${treatmentDate}`);
    }

    this.addHeaderContent({
      ...pdfStyleDefinitions.label,
      text: `Encounter date of ${treatmentTimestamp.toFormat('MM/dd/yyyy')}`,
      style: 'header',
    });
  }

  public addNoteSubsection({ title = 'Note', text }: { title?: string; text: string }) {
    if (this.hasContent(text)) {
      this.addHeaderContent({
        ...pdfStyleDefinitions.label,
        text: title?.toUpperCase(),
        style: 'header',
      });
      this.addParagraphContent({ text, margin: [0, 0, 0, 4] });
    }
  }

  public addGroupNote(title: string, group: { note?: string }) {
    return this.addNoteSubsection({ title, text: group?.note ? group.note : '' });
  }

  public mapOptionsToDetails<O extends object, T>(
    options: O,
    data: any,
    mapFn?: (option: keyof O, data: T) => string,
    filterFn?: (option: keyof O, data: T) => boolean,
  ): DetailsData['details'] {
    return (<[keyof O, string][]>Object.entries(options))
      .filter(([option]) => {
        return filterFn ? filterFn(option, data?.[option]) : !isNil(data?.[option]);
      })
      .map(([option, label]) => ({
        key: label as string,
        value: mapFn ? mapFn(option, data?.[option]) : data?.[option]?.toString(),
      }));
  }

  public mergeTextFields(fields: (string | null)[], joiner = '\n'): string {
    return fields.filter((field) => field?.trim()).join(joiner);
  }

  public hasContent(data: unknown, customFilterFn?: (a: any) => boolean) {
    const filterFn =
      customFilterFn ||
      ((node: unknown) => {
        if (typeof node === 'string') {
          return node.trim().length > 0;
        }
        return !isNil(node);
      });

    if (!isObject(data)) {
      return filterFn(data);
    }

    const nodesWithContent = deepFlattenCollection(data).filter(filterFn);

    return nodesWithContent.length > 0;
  }

  public addDetailsSectionFromRecord(record: Record<string, string>, title?: string) {
    const details = Object.entries(record)
      .map(([key, value]) => ({
        value,
        key: startCase(key),
      }))
      .filter((detail) => this.hasContent(detail.value));

    const noteFieldIndex = details.findIndex((detail) => detail.key === 'Note');

    // If a note field is contained in the record, move it to the end
    if (noteFieldIndex >= 0) {
      details.push(...details.splice(noteFieldIndex, 1));
    }

    this.addDetailsContent({
      details,
      title: title ? { text: title } : undefined,
      style: 'table',
    });
  }

  public addTableSectionFromRecords(tableData: Record<string, string>[], title?: string) {
    if (tableData.length === 0) {
      return;
    }

    const template = tableData[0];
    const columnKeys = Object.keys(template);
    const columnHeaders = columnKeys.map(startCase);

    this.addTableContent({
      title,
      columnKeys,
      columnHeaders,
      tableData: tableData.filter((row) => this.hasContent(row)),
    });
  }

  private static getDateTime(timestamp: Date | string): DateTime | null {
    if (timestamp) {
      if (timestamp instanceof Date) {
        return DateTime.fromJSDate(timestamp);
      }

      return DateTime.fromISO(timestamp);
    }

    return null;
  }
}

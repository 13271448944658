import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ChangeHealthcareSsoService } from '../change-healthcare-sso.service';
import {
  ChangeHealthcareActionCommandOptions,
  ChangeHealthcareSAMLOptions,
  ChangeHealthcareTargetOptions,
} from '@enums';
import { AlertService } from '../../../core/services/alert.service';
import { PatientService } from '../../../core/services/patient.service';
import { AuthService } from '../../../core/services/auth.service';
import { ProviderService } from '../../../core/services/provider.service';
import { filter, take } from 'rxjs/operators';

export interface ChangeHealthcareQueryParams {
  ssoMethod: ChangeHealthcareSAMLOptions;
  target: ChangeHealthcareTargetOptions;
  hdnBusiness: number;
  FromOrder: boolean;
  actionCommand: ChangeHealthcareActionCommandOptions;
  link: boolean;
  loadPatient: false;
  FromRx: boolean;
  ssoToken: string;
  searchaccountId: string;
  showPatientHeader?: boolean;
}

@Component({
  selector: 'app-change-healthcare-sso-button',
  templateUrl: './change-healthcare-sso-button.component.html',
  styleUrls: ['./change-healthcare-sso-button.component.scss'],
})
export class ChangeHealthcareSsoButtonComponent implements OnInit {
  @ViewChild('ssoForm', { read: ElementRef, static: true }) ssoForm: ElementRef;

  @Input() queryParams: ChangeHealthcareQueryParams = {
    ssoMethod: ChangeHealthcareSAMLOptions.SAML2,
    target: ChangeHealthcareTargetOptions.PatientLookup,
    FromOrder: false,
    actionCommand: ChangeHealthcareActionCommandOptions.Search,
    hdnBusiness: environment.changeHealthcareNiceBusinessId,
    link: false,
    loadPatient: false,
    FromRx: true,
    ssoToken: '',
    searchaccountId: '',
  };

  @Input() buttonText: string = 'Send eRx';
  @Input() iconButton = false;
  @Input() tooltipPosition = 'below';
  @Input() patientId: number;
  @Output() complete = new EventEmitter();

  entityEndpoint = environment.changeHealthcareSSOUrl;
  disabled = false;
  isLoading = false;

  constructor(
    private changeHealthcareSsoService: ChangeHealthcareSsoService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private patientService: PatientService,
    private authService: AuthService,
    private providerService: ProviderService
  ) {}

  ngOnInit(): void {
    const patientIdFromRoute = this.route.snapshot.paramMap.get('patientId');
    if (patientIdFromRoute) {
      this.patientId = Number(patientIdFromRoute);
    }
    if (this.patientId) {
      this.queryParams.searchaccountId = this.patientId.toString();
    }
    this.getDisabledStatus();
  }

  async initializeSSO() {
    try {
      // Toggle Loading Indicator
      this.isLoading = true;
      this.alertService.loading('Opening Change Healthcare...');

      // Get our SAML Response and the Change Healthcare PatientID
      const samlResponse = await this.changeHealthcareSsoService.getSAMLResponse(this.patientId);

      // Set the values for the form
      this.queryParams.searchaccountId = this.patientId.toString();
      this.queryParams.ssoToken = samlResponse;

      // Trigger template refresh to ensure our new values are in the HTML form
      this.cd.detectChanges();

      // Submit the HTML Form
      this.ssoForm.nativeElement.submit();

      // Get updated patient
      this.patientService.updateSelectedPatientId(`${this.patientId}`);

      // Toggle Loading Indicator
      this.isLoading = false;
      this.complete.emit();
      this.alertService.dismiss();
    } catch (error) {
      this.isLoading = false;
      this.alertService.error('There was an error when we tried to connect to Change Healthcare.');
    }
  }

  /**
   * Disables the button based on whether the user clicking has a change healthcare user ID or not.
   */
  private getDisabledStatus() {
    this.authService.userState
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe((user) => {
        const provider = this.providerService.getProviderById(user.sub);
        if (provider) {
          this.disabled = !provider.user?.changeHealthcareUserId;
        }
      });
  }
}

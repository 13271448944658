import { PatientChartPayload } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';

export function treatmentPlanProceduresPerformed(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const data = chart.proceduresPerformed?.otherGroup;
  if (data && pdf.hasContent([data.other, data.note])) {
    pdf.addHeaderContent({ text: 'Procedures Performed' });
    pdf.addParagraphContent({
      text: pdf.mergeTextFields([data.other, data.note]),
    });
  }
}

import { Content, DynamicContent, Margins } from 'pdfmake/interfaces';

import { generateTextObjectWithLinks, ParsedLinkText } from '../../utility';
import logo from '../../assets/logo';
import {
  Alignment,
  Color,
  ContentTypes,
  DetailsContent,
  DetailsData,
  DetailsRow,
  DocumentConfiguration,
  DocumentDefinition,
  DocumentInfo,
  FontSize,
  HeaderContent,
  HeaderData,
  ImageContent,
  ImageData,
  ListData,
  OrderedListContent,
  OrderedListType,
  pageBreak,
  ParagraphContent,
  ParagraphData,
  PdfDocument,
  pdfStyleDefinitions,
  Stack,
  TableContent,
  TableData,
  TextArrayObject,
  TextObject,
  TextStyleObject,
  UnorderedListContent,
  UnorderedListType,
} from './assets';

// The margin most elements use if no custom margin is supplied
const defaultMargin = [0, 4] as Margins;

/**
 * Currently supported elements:
 *    - Headers (always bold text, can customize color, size, and alignment)
 *    - Paragraphs (can customize color, size, alignment, bold, and italics, can set title)
 *    - Details (key-value pair list with the key being bold, can set title)
 *    - Lists (can be ordered or unordered, can customize bullet type, can set title)
 *    - Tables (can change text shown for booleans on a per-cell basis, can customize column widths, can set title)
 *    - Images (inserted using base64 strings that include the image content-type header, can customize normal and max width and height, can set alignment)
 *
 * All elements can have a custom margin supplied with an array of numbers:
 *    [ all-sides ]
 *    [ horizontal, vertical ]
 *    [ left, top, right, bottom ]
 */

export abstract class NicePdfGenerator {
  static getDynamicFooterContent(patientName: string, patientDOB: string): DynamicContent {
    return (pageNumber: number, totalPages: number): Content => {
      return {
        columns: [
          {
            text: `${patientName} (${patientDOB})`,
            alignment: 'center',
          },
          {
            text: `Page ${pageNumber} of ${totalPages}`,
            alignment: 'center',
          },
          {
            alignment: 'center',
            columns: [
              {
                text: 'Powered By',
                italics: true,
                alignment: 'right',
                margin: [0, 1, 3, 0],
              },
              {
                image: logo,
                fit: [50, 50],
                alignment: 'left',
              },
            ],
          },
        ],
        fontSize: FontSize.Small,
        margin: [36, 4],
      };
    };
  }

  /**
   * The define method is meant for use by each extension of the generator to actually
   * define the document definition with the section methods in this class.
   */
  abstract define(data: any): void;
  // Holds all of the document content for the doc definition
  private docContent: PdfDocument = [];
  // Where document metadata lives
  private info!: DocumentInfo;

  protected docConfiguration: DocumentConfiguration = {};

  /**
   * Sets the information for the document
   * @param data title, author, subject
   */
  public setInfo(data: DocumentInfo) {
    this.info = data;
  }

  /**
   * Creates and adds a header to the document
   * @param data text: string, fontSize: FontSize, alignment: Alignment, color: Color, margin: number[]
   */
  public addHeaderContent(data: HeaderData) {
    const content = this.makeHeaderContent(data);
    this.addContentToDocument(content);
  }

  /**
   * Creates and adds a horizontal divider into the document.
   */
  public addHorizontalDivider() {
    // No direct way to do a divider, so use a styled table to simulate one.
    const divider: TableContent = {
      style: 'sectionTable',
      layout: {
        fillColor: (i: number) => (i % 2 === 0 ? null : Color.TableBkg),
        vLineWidth: () => 0,
        hLineWidth: (i: number) => (i > 0 ? 1 : 0),
        hLineColor: () => Color.TableLine,
        paddingTop: () => 0,
        paddingBottom: () => 0,
        paddingLeft: () => 0,
        paddingRight: () => 0,
      },
      margin: [0, 20],
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [[{ text: '' }], [{ text: '' }]],
        style: 'sectionTable',
      },
    };

    this.addContentToDocument(divider);
  }

  /**
   * Removes the last document content entry added to the PDF.
   */
  public removeLastContentItemFromDocument() {
    if (this.docContent && this.docContent.length > 0) {
      this.docContent.splice(this.docContent.length - 1, 1);
    }
  }

  // Creates the header content
  private makeHeaderContent(data: HeaderData): HeaderContent {
    return {
      text: data.text,
      font: data.font || 'Nunito',
      color: data.color || Color.Default,
      alignment: data.alignment || Alignment.Left,
      fontSize: data.fontSize || FontSize.Large,
      margin: data.margin || defaultMargin,
      style: data.style || 'header',
    };
  }

  // Creates a header styled to be a section title
  private makeTitleHeader(headerData: TextObject) {
    return this.makeHeaderContent({
      fontSize: headerData.fontSize || FontSize.Medium,
      margin: headerData.margin || [0, 4],
      ...headerData,
    });
  }

  /**
   * Creates and adds a paragraph to the document
   * @param data text: string, fontSize: FontSize, alignment: Alignment, color: Color, margin: number[], bold: boolean, italics: boolean
   */
  public addParagraphContent(data: ParagraphData) {
    data.text = data.text?.trim() || '';
    if (data?.text?.length > 0) {
      // Define the stack with the paragraph content inside
      const stackPayload: Stack = {
        stack: [this.makeParagraphContent(data)],
        margin: data.margin || defaultMargin,
      };

      // Optionally add title to stack
      if (data.title) {
        const titleContent = this.makeTitleHeader({
          text: data.title,
          alignment: data.alignment,
        });
        stackPayload.stack.unshift(titleContent);
      }

      // Insert the stack into the document
      this.addContentToDocument(stackPayload);
    }
  }

  /**
   * Creates and adds a single paragraph of styled TextObjects to the document
   * Does not add clickable links, because the current link parsing function
   * returns an array of separate paragraphs. A new parsing function could be
   * created that returned an array parsed links in a single textArrayObject
   * @param array (string | TextObject)[]
   */
  public addMixedStyleParagraphContent(array: (string | TextObject)[], margin?: Margins, scanForLinks?: false) {
    const defaultParagraphStyle: TextStyleObject = {
      color: Color.Default,
      alignment: Alignment.Left,
      fontSize: FontSize.Default,
      italics: false,
      bold: false,
      margin: [0, 4],
    };
    const stack: TextArrayObject = {
      ...defaultParagraphStyle,
      text: array,
    };

    // Define the stack with the paragraph content inside
    const stackPayload: Stack = {
      stack: [stack],
      margin: margin || defaultMargin,
    };
    // Insert the stack into the document
    this.addContentToDocument(stackPayload);
  }

  // Creates the paragraph content
  private makeParagraphContent(data: ParagraphData): ParagraphContent {
    const parsedText = new ParsedLinkText(data.text).value();
    return {
      text: parsedText,
      color: data.color || Color.Default,
      alignment: data.alignment || Alignment.Left,
      fontSize: data.fontSize || FontSize.Default,
      italics: data.italics || false,
      bold: data.bold || false,
      style: 'paragraph',
      margin: data.margin || [0, 4],
    };
  }

  /**
   * Creates and adds a key-value pair details section to document
   * @param data details: { key: string, value: string}[], title: string, margin: number[]
   */
  public addDetailsContent(data: DetailsData) {
    if (data?.details?.length > 0) {
      // Define the stack with the details content inside
      const stackPayload: Stack = {
        stack: [this.makeDetailsContent(data)],
        margin: data.margin || defaultMargin,
      };

      // Optionally add title to stack
      if (data.title) {
        const titleContent = this.makeTitleHeader(data.title);
        stackPayload.stack.unshift(titleContent);
      }

      // Insert the stack into the document
      this.addContentToDocument(stackPayload);
    }
  }

  /**
   * Insert content to the end of the docContent
   * @param data Either a ContentType or a Stack
   */
  private addContentToDocument(data: ContentTypes | Stack) {
    this.docContent = this.docContent.concat(data);
  }

  // Creates the details content
  private makeDetailsContent(data: DetailsData): DetailsContent {
    const body: DetailsRow[] = data.details.map((detail) => {
      return [
        {
          text: detail.key,
          color: data.keyStyle?.color || Color.Label,
          bold: data.keyStyle?.bold || data.style !== 'table',
          ...data.keyStyle,
        } as TextObject,
        generateTextObjectWithLinks({ text: detail.value, ...data.valueStyle }),
      ];
    });

    const tableDefinition = {
      layout: 'noBorders',
      table: { body },
      style: 'details',
    };

    if (data.style === 'table') {
      // Only show horizontal lines for tables with multiple rows
      const hLineWidth = body.length > 1 ? 1 : 0;
      Object.assign(tableDefinition, {
        style: 'sectionTable',
        layout: {
          fillColor: (i: number) => (i % 2 === 0 ? Color.TableBkg : null),
          vLineWidth: () => 0,
          hLineWidth: (i: number) => (i > 0 ? hLineWidth : 0),
          hLineColor: () => Color.TableLine,
          paddingTop: () => 8,
          paddingBottom: () => 8,
          paddingLeft: () => 16,
          paddingRight: () => 16,
        },
      });

      Object.assign(tableDefinition.table, {
        headerRows: 0,
        widths: ['*', '*'],
        style: 'sectionTable',
      });
    }

    return tableDefinition;
  }

  /**
   * Creates a list and adds it to the document
   * @param data listItems: string[], ordered?: boolean, title?: string, margin?: number[], type?: UnorderedListType | OrderedListType
   */
  public addListContent(data: ListData) {
    if (data?.listItems?.length > 0) {
      // Define the stack with the list content inside
      const content = data.ordered ? this.makeOrderedListContent(data) : this.makeUnorderedListContent(data);
      const stackPayload: Stack = {
        stack: [content],
        margin: data.margin || defaultMargin,
      };

      // Optionally add title to stack
      if (data.title) {
        const titleContent = this.makeTitleHeader({
          text: data.title,
          alignment: Alignment.Left,
        });
        stackPayload.stack.unshift(titleContent);
      }

      // Insert the stack into the document
      this.addContentToDocument(stackPayload);
    }
  }

  // Creates the ordered list content
  private makeOrderedListContent(data: ListData): OrderedListContent {
    return {
      ol: data.listItems.map((item) => {
        return new ParsedLinkText(item).value();
      }),
      type: data.type || OrderedListType.Default,
    };
  }

  // Creates the unordered list content
  private makeUnorderedListContent(data: ListData): UnorderedListContent {
    return {
      ul: data.listItems.map((item) => {
        return new ParsedLinkText(item).value();
      }),
      type: data.type || UnorderedListType.Default,
    };
  }

  /**
   * Creates and adds a table to the document
   *
   * @param data columnHeader: string[], columnKeys: string[], tableData: object[], title?: string,
   * columnWidths?: TableColumnWidthOptions[], margin?: number[], booleanText?: BooleanTextTuple[]
   */
  public addTableContent(data: TableData) {
    if (data?.tableData?.length > 0) {
      // Define the stack with the paragraph content inside
      const stackPayload: Stack = {
        stack: [this.makeTableContent(data)],
        margin: data.margin || defaultMargin,
      };

      // Optionally add title to stack
      if (data.title) {
        const titleContent = this.makeTitleHeader({
          text: data.title,
          alignment: Alignment.Left,
        });
        stackPayload.stack.unshift(titleContent);
      }

      // Insert the stack into the document
      this.addContentToDocument(stackPayload);
    }
  }

  // Creates the table content
  private makeTableContent(data: TableData): TableContent {
    const tableHeaders: TextObject[] = data.columnHeaders.map((value) => {
      return {
        text: value.toUpperCase(),
        color: data.headerStyle?.color || Color.Primary,
        ...data.headerStyle,
      };
    });

    // Collects the text to display in the table for each column by key
    const tableBody = data.tableData.map((row: object) => {
      const collection: TextObject[] = [];
      for (const [i, columnKey] of data.columnKeys.entries()) {
        for (let [key, value] of Object.entries(row)) {
          if (key === columnKey) {
            // Cooerce boolean table value to string if boolean strings are provided
            if (typeof value === 'boolean') {
              if (data.booleanText && data.booleanText[i]) {
                value = value ? data.booleanText[i][0] : data.booleanText[i][1];
              } else {
                // Converts booleans to strings so false doesn't trigger value check
                value = value.toString();
              }
            }
            // Conversion to string accounts for numbers
            const tableCell = {
              text: value ? value.toString() : '',
              ...data.bodyStyle,
            };
            collection.push(tableCell);
          }
        }
      }
      return collection;
    });

    // Define the table body content
    const table: TableContent = {
      style: 'sectionTable',
      layout: {
        fillColor: (i: number) => (i % 2 === 0 ? null : Color.TableBkg),
        vLineWidth: () => 0,
        hLineWidth: (i: number) => (i > 0 ? 1 : 0),
        hLineColor: () => Color.TableLine,
        paddingTop: () => 8,
        paddingBottom: () => 8,
        paddingLeft: () => 16,
        paddingRight: () => 16,
      },
      table: {
        headerRows: 1,
        widths: data.columnWidths || data.columnHeaders.map(() => '*'),
        body: [tableHeaders, ...tableBody],
        style: 'sectionTable',
      },
    };
    return table;
  }

  /**
   * Adds an image to the document from a base64 string
   * @param data The base64 string to display (include content type data to function)
   */
  public addImageContent(data: ImageData) {
    const content = this.makeImageContent(data);
    this.addContentToDocument(content);
  }

  // Creates the image content
  private makeImageContent(data: ImageData): ImageContent {
    return {
      image: data.base64,
      alignment: data.alignment || Alignment.Left,
      width: data.width,
      height: data.height,
      maxWidth: data.maxWidth,
      maxHeight: data.maxHeight,
      margin: data.margin || defaultMargin,
    };
  }

  /**
   * Constructs and returns the document definition.
   */
  public getDocDefinition(): DocumentConfiguration & DocumentDefinition {
    const content = [
      {
        image: logo,
        width: 100,
        margin: [0, 0, 0, 20],
      },
      ...this.docContent,
    ];
    return {
      content,
      info: this.info,
      styles: pdfStyleDefinitions,
      pageBreakBefore: pageBreak,
      defaultStyle: {
        font: 'OpenSans',
      },
      ...this.docConfiguration,
    };
  }
}

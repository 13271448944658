export enum ChatEvents {
  typing = 'typing',
  stopTyping = 'stopTyping',
  message = 'message',
  tag = 'tag',
  untag = 'untag',
  acknowledge = 'acknowledge',
  join = 'userJoin',
  left = 'userLeft',
}

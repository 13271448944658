import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PaginationInformation } from '../../shared/server-side-paginated-table/datasources/generic.datasource';
import { AddressBook, AddressBookMap, FaxInboundDto, FaxOutboundDto, SendFaxPayload } from '@dto';
import { AuthService } from './auth.service';
import { ProviderService } from './provider.service';
import { UtilityService } from './utility.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { PaginatedResponse } from '@models';

type Box = 'inbox' | 'outbox';

@Injectable({
  providedIn: 'root',
})
export class SrfaxService {
  public unreadFaxCount: Observable<number>;
  public addressBookMap: Observable<AddressBookMap>;

  private _unreadFaxCount = new ReplaySubject<number>(1);
  // SRFax address book, organized as an object where key = faxNumber and value = contact name
  private _addressBookMap = new BehaviorSubject<AddressBookMap>(null);

  private base64Cache = {
    inbox: new Map(),
    outbox: new Map(),
  };

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private providerProfileService: ProviderService,
    private utilityService: UtilityService
  ) {
    this.unreadFaxCount = this._unreadFaxCount.asObservable();
    this.addressBookMap = this._addressBookMap.asObservable();

    this.utilityService
      .createNewPollingObservable<number>(this.getUnreadCount.bind(this), environment.unreadFaxPollingRate)
      .subscribe((count) => this._unreadFaxCount.next(count));
    this.getAddressBook();
  }

  async syncInbox() {
    const url = environment.apiV3Url.concat('/srfax/inbox/sync');
    return await this.httpClient.get(url).toPromise();
  }

  getInbox(queryParams: HttpParams, { filters }: PaginationInformation) {
    const url = environment.apiV3Url.concat('/srfax/inbox');
    queryParams = queryParams.append('archived', filters.archived as string);
    queryParams = queryParams.append('deleted', filters.deleted as string);
    return this.httpClient.get<PaginatedResponse<FaxInboundDto>>(url, {
      params: queryParams,
    });
  }

  getUnreadCount() {
    const url = environment.apiV3Url.concat('/srfax/inbox/unreadCount');
    return this.httpClient.get<number>(url);
  }

  getOutbox(queryParams: HttpParams, { filters }: PaginationInformation) {
    const url = environment.apiV3Url.concat('/srfax/outbox');
    queryParams = queryParams.append('sentStatus', filters.sentStatus as string);

    queryParams = queryParams.append('archived', filters.archived as string);
    queryParams = queryParams.append('deleted', filters.deleted as string);
    return this.httpClient.get<PaginatedResponse<FaxOutboundDto>>(url, {
      params: queryParams,
    });
  }

  getFaxStatus(faxId: number | string) {
    const url = environment.apiV3Url.concat('/srfax/outbox/status');
    const params = { id: faxId.toString() };
    return this.httpClient.get(url, { params }).toPromise();
  }

  sendFax(payload: SendFaxPayload) {
    const url = environment.apiV3Url.concat('/srfax/outbox/send');
    return this.httpClient.post(url, { ...payload });
  }

  async refreshFaxStatus() {
    const url = environment.apiV3Url.concat('/srfax/outbox/status/update');
    return this.httpClient.put(url, {}).toPromise();
  }

  assignInboundFaxToPatient(id: number, patientId: number, restricted: boolean) {
    const url = environment.apiV3Url.concat('/srfax/inbox/assign/patient');
    return this.httpClient.post(url, {
      id,
      patientId,
      restricted,
    });
  }

  deleteFaxes(box: Box, ids: number[]) {
    const url = environment.apiV3Url.concat(`/srfax/${box}`);
    return this.httpClient.delete(url, {
      params: { ids: ids.map((id) => id.toString()) },
    });
  }

  updateFaxArchiveStatus(ids: number[], archived: boolean, box: Box) {
    const url = environment.apiV3Url.concat(`/srfax/${box}/archive`);
    return this.httpClient.put(url, { ids, archived });
  }

  markFaxReadStatus(ids: number[], status: boolean) {
    const url = environment.apiV3Url.concat('/srfax/inbox/read');
    return this.httpClient.put(url, { ids, status });
  }

  async getFaxFile(id: number, box: Box) {
    const base64Cached = this.base64Cache[box].get(id);
    if (base64Cached) {
      return Promise.resolve(base64Cached);
    } else {
      const url = environment.apiV3Url.concat(`/srfax/${box}/file/${id}`);
      const base64 = await this.httpClient.get<string>(url).toPromise();
      this.base64Cache[box].set(id, base64);
      return base64;
    }
  }

  sendTaggedMessage(faxId: Number, providerIds: string[], note: string, patientId: number) {
    const url = environment.apiV3Url.concat('/srfax/inbox/message');
    const assignedById = this.providerProfileService.getProviderById(this.authService.currentUserId).id;
    return this.httpClient.post(url, {
      faxId,
      providerIds,
      note: note || '',
      patientId,
      assignedById,
    });
  }

  saveCustomTitle(id: number, customTitle: string) {
    const url = environment.apiV3Url.concat(`/srfax/inbox/save-title/${id}`);
    return this.httpClient.put(url, { customTitle });
  }

  private async getAddressBook(): Promise<void> {
    const addressBookMap: AddressBookMap = {};

    // Calling this endpoint in the lower environments produces an error, likely because the IP addresses are blocked.
    // Since we don't really use the results in those environments (shown on fax inbox "From" column), we can skip the
    // call, so we don't pollute the logs.
    if (
      environment.environmentLabel === 'local' ||
      environment.environmentLabel === 'dev' ||
      environment.environmentLabel === 'local-dev' ||
      environment.environmentLabel === 'staging' ||
      environment.environmentLabel === 'local-staging'
    ) {
      this._addressBookMap.next(addressBookMap);
      return;
    }

    const url = environment.apiV3Url.concat('/srfax/address-book');
    const book = await this.httpClient.get<AddressBook>(url).toPromise();

    Object.values(book).forEach((groupAddresses) => {
      groupAddresses.forEach((address) => {
        addressBookMap[address.faxNumber] = address.name;
      });
    });

    this._addressBookMap.next(addressBookMap);
  }
}

import { Margins } from 'pdfmake/interfaces';
import { Alignment } from './assets';
import { NicePdfGenerator as pdf } from './nice-pdf-generator';

export enum WorkSchool {
  Work,
  School,
  WorkSchool,
}

export interface ReturnToWorkPayload {
  patient: {
    firstName: string;
    lastName: string;
    id?: number | string;
  };
  provider: {
    firstName: string;
    lastName: string;
    phone: string;
    credentials: string[];
    id?: number | string;
  };
  type: WorkSchool;
  timeOff: string;
  todaysDate: string;
  returnDate: string;
  restrictions: string[];
  comments: string;
  signatureBase64?: string;
}

export class ReturnToWorkPdf extends pdf {
  /**
   * Defines the return to work document
   * @param data patient {firstName, lastName}, provider {firstName, lastName, credentials}, signatureBase64, timeOff, todaysDate, returnDate, restrictions, comments
   */
  public define(data: ReturnToWorkPayload): void {
    const patientName = `${data.patient.firstName} ${data.patient.lastName}`;
    const providerName = `${data.provider.firstName} ${data.provider.lastName}`;
    const credentialsString =
      data.provider.credentials && data.provider.credentials.length ? `, ${data.provider.credentials.join(', ')}` : '';
    const providerNameFull = `${providerName}${credentialsString}`;

    let titleText: string;
    let typeText: string;
    let fileNameText: string;
    switch (data.type) {
      case WorkSchool.WorkSchool:
        titleText = 'work or school';
        typeText = 'work/school';
        fileNameText = 'WorkSchool';
        break;
      case WorkSchool.Work:
        titleText = 'work';
        typeText = 'work';
        fileNameText = 'Work';
        break;
      case WorkSchool.School:
        titleText = 'school';
        typeText = 'school';
        fileNameText = 'School';
        break;
    }

    this.setInfo({
      title: `ReturnTo${fileNameText}_${patientName}_${data.todaysDate}`.replace(' ', ''),
      author: providerName,
      subject: `Return to ${titleText}`,
    });

    // Add top details section
    this.addDetailsContent({
      details: [
        { key: 'Patient:', value: patientName },
        { key: 'Provider:', value: providerNameFull },
        { key: 'Phone:', value: '(763) 412-1993' },
      ],
    });

    // Add date line
    this.addParagraphContent({
      text: data.todaysDate,
      italics: true,
    });

    // Add main header
    this.addHeaderContent({
      text: `Return to ${titleText} letter`,
      alignment: Alignment.Center,
    });

    // Create main body paragraph
    this.addParagraphContent({ text: 'To whom it may concern:' });
    this.addParagraphContent({
      text: `${patientName} has been under my care. Listed below are the return to ${typeText} instructions. If you have any questions, please do not hesitate to contact me.`,
    });

    const marginSet = [0, 8, 0, 20] as Margins;

    // Date details
    this.addDetailsContent({
      details: [{ key: `Return to ${typeText} date:`, value: data.returnDate }],
      margin: [0, 4],
    });
    if (data.timeOff) {
      this.addDetailsContent({
        details: [
          {
            key: `Recommended time off from ${typeText}:`,
            value: data.timeOff,
          },
        ],
        margin: [0, 4],
      });
    }

    // Add restrictions list
    this.addListContent({
      title: `Return to ${typeText} restrictions:`,
      listItems: data.restrictions,
      margin: marginSet,
    });

    // Add comments
    if (data.comments) {
      this.addParagraphContent({
        title: 'Additional comments:',
        text: data.comments,
        margin: [0, 10, 0, 30],
      });
    }

    // Add sign off
    this.addParagraphContent({ text: 'Sincerely,' });

    // Add the signature
    if (data.signatureBase64) {
      this.addImageContent({
        base64: data.signatureBase64,
        maxHeight: 100,
        maxWidth: 300,
      });
    }

    // Text of provider name follows signature image
    this.addParagraphContent({ text: providerNameFull });
  }
}

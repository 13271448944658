import { Pipe, PipeTransform } from '@angular/core';
import { ProviderService } from '../../core/services/provider.service';
import { FormatOptions, InvalidOptions, ProviderIdInput, transformUserIds } from '@utility';

@Pipe({
  name: 'providerName',
})
export class ProviderNamePipe implements PipeTransform {
  constructor(private providerService: ProviderService) {}

  transform(
    userId: ProviderIdInput,
    format: FormatOptions = 'noCredentials',
    invalidHandling: InvalidOptions = 'omitInvalid'
  ): (string | number)[] | undefined {
    const lists = {
      userIdList: this.providerService.providerListByUserId.value,
      providerIdList: this.providerService.providerListById.value,
    };
    return transformUserIds(userId, format, invalidHandling, lists);
  }
}

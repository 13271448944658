import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { LegacyAppointmentService } from './core/services/legacy-appointment.service';
import { ChatService } from './core/services/chat.service';
import { Title } from '@angular/platform-browser';
import { map, startWith } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { VersionService } from './core/services/version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isBeta = environment.isBeta;

  constructor(
    private authService: AuthService,
    private appointmentService: LegacyAppointmentService,
    private chatService: ChatService,
    private versionService: VersionService,
    private title: Title
  ) {}

  ngOnInit() {
    this.authService.handleAuthentication().then(() => {
      this.chatService.totalMessageCount
        .pipe(
          map((count) => !!count),
          startWith(false)
        )
        .subscribe((isUnread) => {
          const indicator = isUnread ? '*' : '';
          const text = indicator.concat('Nice Web App');
          this.title.setTitle(text);
        });
    });
  }
}

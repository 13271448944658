import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string | number | Date): string {
    return this.datePipe.transform(value, 'MM-dd-yyyy');
  }
}

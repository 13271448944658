import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  public inVideoCall = false;
  public isIdle = true;

  constructor() {
    document.onclick = this.handleActivity.bind(this);
    document.onmousemove = this.handleActivity.bind(this);
    document.onmousedown = this.handleActivity.bind(this); // touchscreen presses
    document.ontouchstart = this.handleActivity.bind(this);
    document.onclick = this.handleActivity.bind(this); // touchpad clicks
    document.onscroll = this.handleActivity.bind(this); // scrolling with arrow keys
    document.onkeypress = this.handleActivity.bind(this);
  }

  public handleActivity() {
    this.isIdle = false;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentName',
})
export class DocumentNamePipe implements PipeTransform {
  transform(value: string, includePatientId = false): any {
    let friendlyName = value;
    let nameParts: string[];

    if (!includePatientId) {
      // Remove patientId unless otherwise instructed
      nameParts = friendlyName.split('/');
      nameParts.shift();
      friendlyName = nameParts.join('/');
    }

    if (friendlyName.includes('MENTAL_HEALTH_TREATMENT_PLAN')) {
      // Remove id numbers after document name for treatment plans and charts
      nameParts = friendlyName.split('_');
      nameParts.splice(4, 1);
      friendlyName = nameParts.join('_');
    } else if (
      friendlyName.includes('TREATMENT_PLAN') ||
      friendlyName.includes('FULL_CHART')
    ) {
      // Remove id numbers after document name for treatment plans and charts
      nameParts = friendlyName.split('_');
      nameParts.splice(2, 1);
      friendlyName = nameParts.join('_');
    }

    // Check if filename contains valid date string
    const nameSplitOnPeriod = friendlyName.split('.');
    nameSplitOnPeriod.pop();
    const nameMinusFileType = nameSplitOnPeriod.join('.');
    const possibleTimestamp = nameMinusFileType.slice(-24);
    if (
      possibleTimestamp.charAt(10) === 'T' &&
      typeof Date.parse(possibleTimestamp) === 'number'
    ) {
      // Remove time from timestamp (if exists)
      nameParts = friendlyName.split('T');
      nameParts.pop();
      friendlyName = nameParts.join('T');
    }
    return friendlyName;
  }
}

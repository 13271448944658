import { FieldDefinition, inHouseLabsFormConfiguration } from '../../../constants/chart-form-configuration';
import { BloodPressure, ChartInHouseLabFormGroup, PatientChartPayload } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';
import { startCase } from 'lodash';
import { DetailsData } from '../assets';

export function treatmentPlanInHouseLabResults(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const inHouseLabResults = chart.inHouseLabResults;

  const fieldsUsed: (keyof ChartInHouseLabFormGroup)[] = [
    ...inHouseLabsFormConfiguration.map((group) => group.formGroupName),
    'glucoseFastingGroup',
    'vitalsGroup',
  ];

  // If no fields referenced have content, proceed without adding any content
  if (!fieldsUsed.some((field) => pdf.hasContent(inHouseLabResults[field]))) {
    return;
  }

  pdf.addHeaderContent({
    text: 'In House Lab Results',
  });

  pdf.addParagraphContent({
    title: 'Blood Draw',
    text: inHouseLabResults.bloodDrawGroup || '',
  });

  // Button Sections
  for (const section of Object.values(inHouseLabsFormConfiguration)) {
    const sectionData = inHouseLabResults[section.formGroupName];

    if (!pdf.hasContent(sectionData)) {
      continue;
    }

    const details: DetailsData['details'] = section.fields.map((field: FieldDefinition) => {
      const rawValue = section.subGroup
        ? (sectionData as any)[section.subGroup][field.formControlName]
        : (sectionData as any)[field.formControlName];

      let value = '';

      if ('toggleOptions' in field) {
        value = field.toggleOptions.find((option) => option.value === rawValue)?.label || '';
      } else if ('optionsEnum' in field) {
        value = field.optionsEnum[rawValue];
      }

      value = value || rawValue?.toString();

      return {
        value,
        key: field.header,
      };
    });

    if (pdf.hasContent(sectionData.note)) {
      details.push({
        key: 'Note',
        value: sectionData.note || '',
      });
    }

    pdf.addDetailsContent({
      title: {
        text: startCase(section.formGroupName).split(' ').slice(0, -1).join(' '),
      },
      details: details.filter((detail) => pdf.hasContent(detail.value)),
      style: 'table',
    });
  }

  // Glucose Section
  const glucoseFastingGroup = inHouseLabResults.glucoseFastingGroup;
  const glucoseFasting = glucoseFastingGroup?.glucoseFasting?.glucoseFasting;
  const glucoseNonFasting = glucoseFastingGroup?.glucoseFasting?.glucoseNonFasting;

  const glucoseDetails: DetailsData['details'] = [
    {
      key: 'Glucose - Fasting',
      value: glucoseFasting || '',
    },
    {
      key: 'Glucose - Non-Fasting',
      value: glucoseNonFasting || '',
    },
    {
      key: 'Note',
      value: glucoseFastingGroup.note || '',
    },
  ].filter((detail) => pdf.hasContent(detail.value));

  pdf.addDetailsContent({
    title: { text: 'Glucose' },
    details: glucoseDetails,
    style: 'table',
  });

  // Peak Flow Section
  const peakFlowGroup = inHouseLabResults.peakFlowGroup;
  const peakFlowPreRx = peakFlowGroup?.peakFlow?.peakFlowPreRx;
  const peakFlowPostRx = peakFlowGroup?.peakFlow?.peakFlowPostRx;
  const peakFlowDetails: DetailsData['details'] = [
    {
      key: 'Peak Flow - Pre Rx',
      value: peakFlowPreRx || '',
    },
    {
      key: 'Peak Flow - Post Rx',
      value: peakFlowPostRx || '',
    },
    {
      key: 'Notes',
      value: peakFlowGroup.note || '',
    },
  ].filter((detail) => pdf.hasContent(detail.value));

  pdf.addDetailsContent({
    title: { text: 'Peak Flow' },
    details: peakFlowDetails,
    style: 'table',
  });

  // Vitals Section
  const vitalsGroup = inHouseLabResults.vitalsGroup;
  const vitalTypes = ['Sitting', 'Standing', 'Supine'] as const;
  const vitalDetails: DetailsData['details'] = [];
  vitalTypes.forEach((type) => {
    const bloodPressure: BloodPressure | null | undefined = vitalsGroup?.vitals?.[`orthostaticVitals${type}`];
    if (bloodPressure && pdf.hasContent(bloodPressure)) {
      vitalDetails.push({
        key: `Orthostatic Vitals - ${type}`,
        // @ts-ignore
        value: `${bloodPressure.value1} / ${bloodPressure.value2}`,
      });
    }

    vitalDetails.push({
      key: `Pulse - ${type}`,
      // @ts-ignore
      value: vitalsGroup?.vitals?.[`pulse${type}`],
    });
  });

  vitalDetails.push({
    key: 'Notes',
    value: vitalsGroup?.note || '',
  });

  pdf.addDetailsContent({
    title: { text: 'Vitals' },
    details: vitalDetails.filter((node) => pdf.hasContent(node.value)),
    style: 'table',
  });
}

<button
  mat-button
  mat-raised-button
  progress-button
  [ngClass]="{ 'progress-button__loading': submitting }"
  [color]="color"
  [disabled]="disabled || submitting"
  (click)="onSubmit()"
  fxLayoutAlign="center center"
  style="padding: 5px"
>
  <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <mat-icon *ngIf="icon" class="progress-button__icon">{{ icon }}</mat-icon>
    <span class="progress-button__text">{{ text }}</span>
  </div>
  <mat-spinner *ngIf="submitting" diameter="22"></mat-spinner>
</button>

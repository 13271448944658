export enum SRFaxStatus {
  Pending,
  Success,
  Error,
}

export enum SRFaxActions {
  QueueFax = 'Queue_Fax',
  GetFaxStatus = 'Get_FaxStatus',
  GetMultiFaxStatus = 'Get_MultiFaxStatus',
  ForwardFax = 'Forward_Fax',
  GetFaxInbox = 'Get_Fax_Inbox',
  GetFaxOutbox = 'Get_Fax_Outbox',
  RetrieveFax = 'Retrieve_Fax',
  UpdateViewedStatus = 'Update_Viewed_Status',
  DeleteFax = 'Delete_Fax',
  GetFaxUsage = 'Get_Fax_Usage',
  StopFax = 'Stop_Fax',
  GetFaxesQueued = 'Get_Faxes_Queued',
  GetAddressBook = 'Get_Address_Book',
}

export enum SRFaxReadStatus {
  Read = 'READ',
  Unread = 'UNREAD',
}

export enum SRFaxDirection {
  In = 'IN',
  Out = 'OUT',
}

export enum SRFaxAffirm {
  Yes = 'Y',
  No = 'N',
}

export enum SRFaxResponseFormat {
  PDF = 'PDF',
  TIF = 'TIF',
}

export enum SRFaxSentStatus {
  InProgress = 'In Progress',
  Sent = 'Sent',
  Failed = 'Failed',
  SendingEmail = 'Sending Email',
}

export enum SRFaxType {
  Single = 'SINGLE',
  Broadcast = 'BROADCAST',
}

export enum SRFaxCoverPage {
  Basic = 'Basic',
  Standard = 'Standard',
  Company = 'Company',
  Personal = 'Personal',
}

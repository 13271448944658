<div *ngIf="opened">
  <mat-list dense>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Membership Info </mat-panel-title>
      </mat-expansion-panel-header>
      <div>{{ patient.membershipTypeName }}</div>
      <ng-container *ngIf="patient.membership && patient.membership.membershipType; else noMembership">
        <div class="membership" fxLayout="column" fxLayoutGap="8px">
          <div fxLayout="column" fxLayoutGap="8px" fxFlex="100">
            <div class="details">
              <span class="header">Visit Fee: </span>{{ patient.membership.membershipType.copayAmount }}
            </div>
          </div>
          <div fxLayout="row wrap">
            <div
              *ngFor="let eligibility of eligibilityList"
              fxFlex="50"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <mat-icon [class]="eligibility.active ? 'icon-green' : 'icon-warn'">
                {{ eligibility.active === true ? 'check' : eligibility.active === false ? 'close' : null }}
              </mat-icon>
              <span>
                {{ eligibility.label }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noMembership>
        <div>No Membership Type Assigned</div>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Notes: </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field floatLabel="never" *ngIf="patient" appearance="fill">
        <textarea
          matInput
          placeholder="Add new note"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3"
          [(ngModel)]="patient.patientNote"
        ></textarea>
      </mat-form-field>
      <app-progress-button
        class="float-right"
        [submitting]="submitting"
        (submit)="saveNote()"
        [disabled]="patient.patientNote === noteInitialValue"
        text="Save note"
      ></app-progress-button>
    </mat-expansion-panel>
  </mat-list>
</div>

import { Pipe, PipeTransform } from '@angular/core';

interface GenericAddress {
  address1?: string;
  address2?: string;
  state?: string;
  zip?: string;
  city?: string;
}

@Pipe({
  name: 'formatAddress',
})
export class FormatAddressPipe implements PipeTransform {
  transform(address: GenericAddress): string {
    const stringCollection: string[] = [];

    if (address.address1) {
      stringCollection.push(address.address1);
    }

    if (address.address2) {
      stringCollection.push(address.address2);
    }

    // Goal: create a line of 'Minneapolis, MN 55555'
    let finalLine = '';

    if (address.city) {
      finalLine += address.city;
      // Adds a comma and space if city and state are presenet
      if (address.state) {
        finalLine += `, ${address.state}`;
      }
    } else if (address.state) {
      // If only state is present, add just that
      finalLine += address.state;
    }

    // Add a space before the zip if the final line has any other text
    if (address.zip) {
      if (finalLine.length > 0) {
        finalLine += ` ${address.zip}`;
      } else {
        finalLine += address.zip;
      }
    }

    // Add the final line if it has content
    if (finalLine.length > 0) {
      stringCollection.push(finalLine);
    }

    // Return the joined array with each item on a new line
    return stringCollection.join('\n');
  }
}

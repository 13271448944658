import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export class DateUtils {
  /**
   * Hack solution to prevent failures if timestamps contain '+' characters, meaning positive of GMT.
   *
   * @param timestamp the timestamp to parse.
   *
   * @return a clean timestamp string, removing any '+' characters.
   */
  static cleanTimestampString(timestamp: string): string {
    if (timestamp && timestamp.indexOf('+') > 0) {
      return timestamp.substring(0, timestamp.indexOf('+'));
    }
    return timestamp;
  }

  /**
   * Takes a given date and provides a date range of start to end of the day for that date.
   *
   * @param date the date to get the date range for.
   *
   * @return the two {@link Date} objects representing the start and end of the desired day.
   */
  static getStartAndEndOfDay(date: Date): { startOfDay: Date; endOfDay: Date } {
    const targetDate = date ? date : new Date();
    return {
      startOfDay: DateTime.fromJSDate(targetDate).startOf('day').toJSDate(),
      endOfDay: DateTime.fromJSDate(targetDate).endOf('day').toJSDate(),
    };
  }

  static validateDateInputForMaxAndMinDate(minimumDate: string, maximumDate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value !== null) {
        // Convert minimumDate to Date object if it's a string
        const minimumDateObj = typeof minimumDate === 'string' ? new Date(minimumDate) : minimumDate;
        minimumDateObj.setDate(minimumDateObj.getDate());
        // Set time to midnight
        minimumDateObj.setHours(0, 0, 0, 0); // Set time to midnight

        // Convert maximumDate to Date object if it's a string
        const maximumDateObj = typeof maximumDate === 'string' ? new Date(maximumDate) : maximumDate;
        // Set time to just before midnight
        maximumDateObj.setHours(23, 59, 59, 999);

        // Create a new Date object for the value
        const valueDateObj = new Date(value);
        if (isNaN(valueDateObj.getTime()) || valueDateObj > maximumDateObj || valueDateObj < minimumDateObj) {
          return { invalidDateInput: { value: control.value } };
        }
      }
      return null;
    };
  }

  static parseDateForDatePicker(date: Date): Date {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return new Date(`${year}/${month}/${day}`);
  }
}

<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <p>{{ message }}</p>
  <div *ngIf="data.checkbox">
    <mat-checkbox [(ngModel)]="checkboxSelected">{{ checkboxText }}</mat-checkbox>
  </div>
  <div fxLayout="row" fxLayoutGap="8px">
    <button mat-button (click)="onDismiss()">{{ textDismiss }}</button>
    <button mat-raised-button [color]="confirmColor" (click)="onConfirm()">
      {{ textConfirm }}
    </button>
  </div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChangeHealthcareSsoService {
  public syncedPrescriptions: Observable<any>;

  private _syncedPrescriptions = new Subject<any[]>();

  constructor(private httpClient: HttpClient) {
    this.syncedPrescriptions = this._syncedPrescriptions.asObservable();
  }

  getSAMLResponse(patientId: number): Promise<string> {
    const url = environment.apiV3Url.concat('/change-healthcare/sso');
    return this.httpClient
      .get<string>(url, {
        params: new HttpParams({
          fromObject: {
            patientId: patientId.toString(),
          },
        }),
      })
      .toPromise();
  }

  updateSelectedSyncedPrescriptions(prescriptions: any) {
    this._syncedPrescriptions.next(prescriptions);
  }

  getPatientRxPrescriptions(patientId: number) {
    const url = environment.apiV3Url.concat(`/clinician/person-prescriptions/${patientId}`);

    return this.httpClient.get<any>(url).toPromise();
  }
}

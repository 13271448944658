import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { SessionLayoutComponent } from './layout/session-layout/session-layout.component';
import { SharedModule } from './shared/shared.module';
import { httpInterceptorProviders } from './interceptors/interceptors';
import { SessionModule } from './session/session.module';
import { DashboardLayoutModule } from './layout/dashboard-layout.module';
import { DatePipe } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';

@NgModule({
  declarations: [AppComponent, SessionLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    DashboardLayoutModule,
    SessionModule,
    SharedModule,
    HttpClientJsonpModule,
  ],
  providers: [
    httpInterceptorProviders,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

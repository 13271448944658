import { NgModule } from '@angular/core';

import { LayoutContainerComponent } from './layout-container/layout-container.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { NotFoundComponent } from '../session/not-found/not-found.component';
import { PatientLayoutComponent } from './patient-layout/patient-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SessionModule } from '../session/session.module';
import { InactiveNotificationDialogComponent } from './inactive-notification-dialog/inactive-notification-dialog.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'patient',
        loadChildren: () => import('../patient/patient.module').then((m) => m.PatientModule),
      },
      {
        path: 'provider',
        loadChildren: () => import('../provider/provider.module').then((m) => m.ProviderModule),
      },
      {
        path: 'membership',
        loadChildren: () => import('../membership/membership.module').then((m) => m.MembershipModule),
      },
      {
        path: 'company',
        loadChildren: () => import('../company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'schedule',
        loadChildren: () => import('../schedule/schedule.module').then((m) => m.ScheduleModule),
      },
      {
        path: 'billing',
        loadChildren: () => import('../billing/billing.module').then((m) => m.BillingModule),
      },
      {
        path: 'fax',
        loadChildren: () => import('../fax/fax.module').then((m) => m.FaxModule),
      },
      {
        path: 'tasks',
        loadChildren: () => import('../task/task.module').then((m) => m.TaskModule),
      },
      {
        path: 'markets',
        loadChildren: () => import('../markets/markets.module').then((m) => m.MarketsModule),
      },
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: '',
        redirectTo: 'schedule',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'patients',
    component: PatientLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('../patient/patient.module').then((m) => m.PatientModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('../documents/documents.module').then((m) => m.DocumentsModule),
      },
      {
        path: '404',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [
    DashboardLayoutComponent,
    LayoutContainerComponent,
    PatientLayoutComponent,
    InactiveNotificationDialogComponent,
  ],
  imports: [RouterModule.forChild(routes), SharedModule, SessionModule],
  exports: [LayoutContainerComponent],
})
export class DashboardLayoutModule {}

import { MembershipDto } from '../dto/membership';
import { CompanyDto } from '../dto/company';
import { CompanyStatus } from '../enums/company-status';

export class Company {
  public readonly id: number;
  public readonly name: string;
  public readonly effectiveDate: string;
  public readonly terminationDate: string;
  public readonly prescriptionCardGroupId: string;
  public readonly memberships: MembershipDto[];
  public readonly zohoGroupId: string;
  public readonly zohoParentGroupId: string;
  public readonly hintGroupId: string;

  constructor(data: CompanyDto) {
    Object.assign(this, data);
  }

  get isPending() {
    return new Date() < new Date(this.effectiveDate);
  }

  get isTerminated() {
    return new Date() > new Date(this.terminationDate);
  }

  get status(): CompanyStatus {
    return this.isPending
      ? CompanyStatus.pending
      : this.isTerminated
      ? CompanyStatus.terminated
      : CompanyStatus.active;
  }
}

// @todo: money math should be handed off to something like Dinero.js
// Since we aren't doing anything very complicated, this is okay for now

export function dollarStringToNumber(dollarAmount: string) {
  return parseFloat(dollarAmount.substring(1));
}

export function dollarsToCents(dollarAmount: number) {
  return Math.round(dollarAmount * 100);
}

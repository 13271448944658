export enum MarketImportError {
  missingZip = 'Missing ZIP code',
  malformedZip = 'Malformed ZIP code',
  duplicateZip = 'Duplicate ZIP code',
}

export enum MarketUploadType {
  create,
  update,
}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

export interface ConfirmDialogModel {
  /**
   * The text at the top of the dialog
   */
  title: string;
  /**
   * The message within the dialog
   */
  message: string;
  /**
   * The text on the button to confirm
   */
  textConfirm?: string;
  /**
   * The text on the button to dismiss and close the dialog
   */
  textDismiss?: string;
  /**
   * Confirm button default to primary color, set this to true to switch to warn color
   */
  warn?: boolean;
  /**
   * The CSS max-width of the dialog
   */
  maxWidth?: string;
  /**
   * Whether to display a checkbox in dialog
   */
  checkbox?: boolean;
  /*
   * The text on the checkbox on the dialog
   */
  checkboxText?: string;
  checkboxSelected?: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  textConfirm: string;
  textDismiss: string;
  confirmColor = 'primary';
  checkbox: boolean;
  checkboxText: string;
  checkboxSelected: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.textConfirm = data.textConfirm ? data.textConfirm : 'Ok';
    this.textDismiss = data.textDismiss ? data.textDismiss : 'Cancel';
    this.checkbox = data.checkbox ? data.checkbox : false;
    this.checkboxText = data.checkboxText ? data.checkboxText : null;
    this.checkboxSelected = data.checkboxSelected ? data.checkboxSelected : false;
    if (data.warn) {
      this.confirmColor = 'warn';
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

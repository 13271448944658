import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import * as auth0 from 'auth0-js';

import { filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { IdleService } from './idle.service';
import { AlertService } from './alert.service';
import { VersionService } from './version.service';

interface AuthState {
  accessToken: string;
  idToken: string;
  expiresAt: Date;
}

enum Scope {
  IdentityCreate = 'identity:CreateIdentities',
  IdentityRead = 'identity:ReadIdentities',
  IdentityUpdate = 'identity:UpdateIdentities',
  IdentityReadAddress = 'identity:ReadAddress',
  IdentityUpdateAddress = 'identity:UpdateAddress',
  IdentityDeleteAddress = 'identity:DeleteAddress',
  ScheduleCreate = 'schedule:CreateSchedules',
  ScheduleRead = 'schedule:ReadSchedules',
  ScheduleRequestRead = 'schedule:ReadScheduleRequest',
  ScheduleRequestCreate = 'schedule:CreateScheduleRequest',
  ScheduleRequestUpdate = 'schedule:UpdateScheduleRequest',
}

/**
 * Creates a space-delimited string of scopes from our {@link Scope} enum values.
 *
 * @return a space-delimited string of scopes.
 */
function getAllScopes(): string {
  const scopes: string[] = Object.values(Scope);
  // Add default scopes
  scopes.push('openid');
  scopes.push('profile');
  return scopes.join(' ');
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly authState: Observable<AuthState>;
  public readonly userState: Observable<auth0.Auth0UserProfile>;
  public readonly initialized: Observable<boolean>;

  public redirectUrl: string = null;

  public refreshSubscription: any;

  private _auth0Client: auth0.WebAuth; // Must access through getAuth0Client().

  private _clientId: string;
  private _isUsingIdentityService = false;
  private _isProviderReadonly = false;

  private _authState = new BehaviorSubject<AuthState>(null);
  private _userState = new BehaviorSubject<auth0.Auth0UserProfile>(null);
  private authScopes = new BehaviorSubject<string[]>(null);
  private _initialized = new BehaviorSubject<boolean>(null);

  constructor(
    private alertService: AlertService,
    public router: Router,
    private idleService: IdleService,
    private versionService: VersionService
  ) {
    this.authState = this._authState.asObservable();
    this.userState = this._userState.asObservable();

    this.initialized = this._initialized.asObservable().pipe(
      filter((initialized) => !!initialized),
      take(1)
    );

    this.getAuth0Client().then(() => {
      this.authState
        .pipe(
          switchMap((authState) => {
            if (authState === null || authState.accessToken === null || authState.accessToken === undefined) {
              return of(null);
            }
            /**
             * Split access token by .
             * Get second group
             * Convert from b64 to string
             * Convert from string to json
             * Get the json value.scope
             * Split that string on spaces to get individual scopes
             */
            const accessTokenPayload = JSON.parse(atob(authState.accessToken.split('.')[1]));
            if (accessTokenPayload.permissions && accessTokenPayload.permissions.length > 0) {
              // New tenant format
              this.authScopes.next(accessTokenPayload.permissions);
            } else {
              // Legacy tenant format
              this.authScopes.next(accessTokenPayload.scope.split(' '));
            }

            return this.getUserProfile(authState.accessToken);
          })
        )
        .subscribe((userState) => this._userState.next(userState));

      this.checkForExistingSession()
        .then((session) => {
          this.setSession(session);
        })
        .finally(() => {
          this._initialized.next(true);
        });
    });
  }

  get loading() {
    return this._initialized.getValue() === null;
  }

  get currentUser() {
    return this.isAuthenticated() ? this._userState.getValue() : null;
  }

  get currentUserId() {
    return this.isAuthenticated() ? this._userState.getValue()?.sub : null;
  }

  public authHasScope(scope: string) {
    if (!this.authScopes.value) {
      return;
    }

    return this.authScopes.value.includes(scope);
  }

  public isUsingIdentityService(): boolean {
    return this._isUsingIdentityService;
  }

  public isProviderReadonly(): boolean {
    return this._isProviderReadonly;
  }

  public getAuthorizationToken(): string | null {
    return this.isAuthenticated() ? this._authState.getValue().accessToken : null;
  }

  public async handleAuthentication(): Promise<void> {
    // todo: update initialized state here?
    (await this.getAuth0Client()).parseHash((err, authResult) => {
      // TODO This doesn't work.  This always comes back null for err and authResult in normal use cases.
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.router.navigate(['/dashboard'], { queryParams: {} });
      } else if (err) {
        this.router.navigate(['/login']);
        if (err.errorDescription) {
          this.alertService.textbox({
            message: err.errorDescription,
            title: `Error: ${err.error}`,
          });
        }
      }
    });
  }

  public isAuthenticated(): boolean {
    const authState = this._authState.getValue();

    if (authState === null) {
      return false;
    }

    const expiresAt = authState.expiresAt.getTime();
    return new Date().getTime() < expiresAt;
  }

  public async login(): Promise<void> {
    (await this.getAuth0Client()).authorize();
  }

  public async logout(): Promise<void> {
    this._authState.next(null);
    (await this.getAuth0Client()).logout({
      returnTo: this.getBaseRedirectUri(),
      clientID: this._clientId ? this._clientId : undefined,
    });
  }

  private async initializeAuth0Client(): Promise<auth0.WebAuth> {
    if (!this._auth0Client) {
      const clientConfiguration = await this.versionService.getVersionConfig();
      this._isUsingIdentityService = clientConfiguration.isUsingIdentityService;
      this._isProviderReadonly = clientConfiguration.isProviderReadonly;
      this._clientId = clientConfiguration.clientID;
      // Special handling of client ID for the native builds.  The Auth0 interface expects "clientId", not "clientID".
      clientConfiguration.clientId = clientConfiguration.clientID;
      this._auth0Client = new auth0.WebAuth({
        ...clientConfiguration,
        scope: getAllScopes(),
        redirectUri: this.getBaseRedirectUri().concat('/callback'),
      });
    }

    return this._auth0Client;
  }

  private checkForExistingSession(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      (await this.getAuth0Client()).checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(authResult);
        } else if (err) {
          reject(err);
        }
      });
    });
  }

  private async getAuth0Client(): Promise<auth0.WebAuth> {
    if (!this._auth0Client) {
      this._auth0Client = await this.initializeAuth0Client();
    }

    return this._auth0Client;
  }

  private setSession(authResult: auth0.Auth0DecodedHash): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    this._authState.next({
      accessToken: authResult.accessToken,
      idToken: authResult.idToken,
      expiresAt: new Date(parseInt(expiresAt, 10)),
    });
    this._initialized.next(true);
    this.scheduleRenewal();
  }

  private clearAuthTokenAndRedirectToLogin() {
    this._authState.next(null);
    this.router.navigate(['/login']);
  }

  private getUserProfile(accessToken: string): Promise<auth0.Auth0UserProfile> {
    return new Promise<auth0.Auth0UserProfile>(async (resolve, reject) => {
      (await this.getAuth0Client()).client.userInfo(accessToken, function (err, profile) {
        if (err) {
          reject(err);
        }

        resolve(profile);
      });
    });
  }

  /**
   * Handles logic to refresh access token before it expires
   * https://auth0.com/docs/quickstart/spa/angular2/05-token-renewal
   */
  private scheduleRenewal() {
    if (!this.isAuthenticated()) {
      return;
    }
    this.unscheduleRenewal();

    const authState = this._authState.getValue();

    if (authState === null) {
      return null;
    }

    const expiresAt = authState.expiresAt.getTime();

    const expiresIn$ = of(expiresAt).pipe(
      mergeMap((expiresAtTime: number) => {
        const now = Date.now();
        const renewTimerBuffer = 60000;
        const timeUntilRenewal = expiresAtTime - (now + renewTimerBuffer);
        const refreshTimer = timer(Math.max(1, timeUntilRenewal));
        return refreshTimer;
      })
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = expiresIn$.subscribe(async () => {
      if (this.idleService.isIdle && !this.idleService.inVideoCall) {
        this.unscheduleRenewal();
        this.clearAuthTokenAndRedirectToLogin();
      } else {
        this.idleService.isIdle = true;
        await this.renewTokens();
      }
    });
  }

  private unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  private async renewTokens(): Promise<void> {
    (await this.getAuth0Client()).checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        alert('Your session has expired. Please login again.');
        this.unscheduleRenewal();
        this.clearAuthTokenAndRedirectToLogin();
      }
    });
  }

  private getBaseRedirectUri() {
    return `${window.location.protocol}//${window.location.host}`;
  }
}

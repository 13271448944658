import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CapsuleDeliveryEligibilityResponse, MembershipDto } from '@dto';
import { Membership } from '@models';

import { environment } from '../../../environments/environment';
import { PaginationInformation } from '../../shared/server-side-paginated-table/datasources/generic.datasource';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  public readonly memberships: Observable<Membership[]>;
  private _memberships: BehaviorSubject<Membership[]> = new BehaviorSubject<Membership[]>([]);

  constructor(private httpClient: HttpClient) {
    this.memberships = this._memberships.asObservable();
  }

  public getMembership(membershipId: number) {
    const url = environment.apiUrl.concat(`/membership/${membershipId}`);
    return this.httpClient.get<MembershipDto>(url).pipe(map((membership) => new Membership(membership)));
  }

  public getCapsuleEligibility(zipCode: string) {
    const url = environment.apiV3Url.concat(`/memberships/capsule-eligibility/${zipCode}`);
    return this.httpClient.get<CapsuleDeliveryEligibilityResponse>(url);
  }

  getMembershipsPaginated(queryParams: HttpParams, { filters }: PaginationInformation) {
    if (filters.verified === false || filters.verified === true) {
      queryParams = queryParams.append('verified', filters.verified.toString());
    }
    const url = environment.apiV3Url.concat('/memberships');
    return this.httpClient.get<{ totalCount: number; data: MembershipDto[] }>(url, {
      params: queryParams,
    });
  }

  public updateMembership(data: MembershipDto) {
    const url = environment.apiUrl.concat(`/membership/${data.id}`);
    return this.httpClient.put(url, data);
  }

  private getMemberships() {
    const url = environment.apiUrl.concat('/memberships');
    return this.httpClient
      .get<MembershipDto[]>(url)
      .pipe(map((memberships) => memberships.map((membership) => new Membership(membership))));
  }
}

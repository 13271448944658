import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { User as Auth0User } from 'auth0';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { DateTime } from 'luxon';
import { Auth0RoleMap, Auth0UserIds } from '@dto';
import { PaginatedResponse, Patient, User, Provider } from '../../react/api/model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public providers: Observable<Auth0User[]>;

  public currentUserIsCareCoordinator: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentUserIsAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentUserIsScheduling: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentUserIsRegisteredNurse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public userTimezone = DateTime.now().zoneName;
  public currentUserRoles: Observable<Auth0RoleMap>;
  public _userRoleGroups: BehaviorSubject<Auth0UserIds> = new BehaviorSubject<Auth0UserIds>(null);
  public userRoleGroups: Observable<Auth0UserIds> = this._userRoleGroups.asObservable();

  private _providers: BehaviorSubject<Auth0User[]> = new BehaviorSubject<Auth0User[]>([]);
  private _authCareCoordinators: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private _currentUserRoles: BehaviorSubject<Auth0RoleMap> = new BehaviorSubject<Auth0RoleMap>(null);

  constructor(private authService: AuthService, private httpClient: HttpClient) {
    this.providers = this._providers.asObservable();
    this.currentUserRoles = this._currentUserRoles.asObservable().pipe(filter((value) => value !== null));

    this.authService.authState
      .pipe(
        switchMap((authState) => {
          if (authState === null) {
            return of([]);
          }

          return this.getProviderUsers();
        })
      )
      .subscribe((providers) => this._providers.next(providers));

    this.authService.authState
      .pipe(
        filter((v) => !!v),
        switchMap(() => {
          return this.getUserRoles();
        })
      )
      .subscribe((roleMap) => {
        this.currentUserIsCareCoordinator.next(roleMap.careCoordinator);
        this.currentUserIsAdmin.next(roleMap.coordinatorAdmin);
        this.currentUserIsScheduling.next(roleMap.coordinatorScheduling);
        this.currentUserIsRegisteredNurse.next(roleMap.coordinatorRegisteredNurse);

        this._currentUserRoles.next(roleMap);
      });

    this.authService.authState
      .pipe(
        filter((v) => !!v),
        switchMap(() => {
          return this.getUserRoleGroups();
        })
      )
      .subscribe((roleGroups) => {
        this._userRoleGroups.next(roleGroups);
        this._authCareCoordinators.next(roleGroups.careCoordinator);
      });
  }

  get currentProviderList(): Auth0User[] {
    return this._providers.getValue();
  }

  public getProviderById(user_id: string): Auth0User {
    return this.currentProviderList.find((provider) => provider.user_id === user_id);
  }

  public getUserRoles(auth0Id?: string) {
    const url = environment.apiV3Url.concat('/users/roles');
    let params = new HttpParams();
    if (auth0Id) {
      params = params.append('userId', auth0Id);
    }
    return this.httpClient.get<Auth0RoleMap>(url, { params });
  }

  /**
   * Refresh the current list of provider users made available
   * via this.providers Observable property
   */
  public refreshProviderList() {
    return this.getProviderUsers()
      .toPromise()
      .then((providers) => this._providers.next(providers));
  }

  public async getProviderUserById(providerId: number): Promise<Provider> {
    const url = environment.niceServiceUrl.concat('/v2/users');
    const params = new HttpParams().append('providerId', providerId);
    const response = await this.httpClient.get<PaginatedResponse<User>>(url, { params }).toPromise();
    const providers = response.content.map((user: User, index: number) => {
      if (user.type.startsWith('FindUserResponse::Provider')) {
        return response.content[index] as Provider;
      } else {
        throw new Error('Invalid user type: ' + user.type + ' for user: ' + user.userId);
      }
    });

    if (providers.length > 1) {
      throw new Error('Invalid provider list for provider ID: ' + providerId);
    } else if (providers.length === 0) {
      return null;
    } else {
      return providers[0];
    }
  }

  /**
   * Return the user search results for a list of patients.
   */
  public async getPatients(patientIds: number[]): Promise<Patient[]> {
    const patients: Patient[] = [];

    for (let i = 0; i < patientIds.length; i++) {
      patients.push((await this.getPaginatedUsers(patientIds[i]).toPromise()).content[0] as Patient);
    }

    return patients;
  }

  public async getPatientUsers(patientIds: number[]): Promise<Patient[]> {
    const patients: Patient[] = [];

    for (let i = 0; i < patientIds.length; i++) {
      const patient = await this.getPatientUser(patientIds[i]);
      if (patient) {
        patients.push(patient);
      }
    }

    return patients;
  }

  private getPaginatedUsers(
    patientId: number,
    page: number = 0,
    order?: 'ASC' | 'DESC',
    pageSize?: number
  ): Observable<PaginatedResponse<Patient | Provider>> {
    const queryParams = new HttpParams({
      fromObject: {
        type: patientId && patientId !== 0 ? 'PATIENT' : 'PROVIDER',
        patientId: patientId ? patientId : undefined,
        page: page.toString(),
        size: pageSize ? pageSize : 30,
        // sortBy: 'START_DATE',
        order: order ? order : 'ASC',
      },
    });

    const url = environment.niceServiceUrl.concat('/v2/users');
    return this.httpClient
      .get<any>(url, {
        params: queryParams,
      })
      .pipe(
        take(1),
        map((response: PaginatedResponse<Patient | Provider>) => {
          return response;
        })
      );
  }

  private async getPatientUser(patientId: number): Promise<Patient> {
    const url = environment.niceServiceUrl.concat('/v2/users');
    const params = new HttpParams().append('patientId', patientId);
    const response = await this.httpClient.get<PaginatedResponse<User>>(url, { params }).toPromise();

    const patients = response.content.map((user: User, index: number) => {
      if (user.type.startsWith('FindUserResponse::Patient')) {
        return response.content[index] as Patient;
      } else {
        throw new Error('Invalid user type: ' + user.type + ' for user: ' + user.userId);
      }
    });

    if (patients.length > 1) {
      throw new Error('Invalid patient list for patient ID: ' + patientId);
    } else if (patients.length === 0) {
      return null;
    } else {
      return patients[0];
    }
  }

  private getUserRoleGroups() {
    const url = environment.apiV3Url.concat('/users/role-user-ids');
    return this.httpClient.get<Auth0UserIds>(url);
  }

  /**
   * Get provider users from Auth0 Provider Connection
   */
  private getProviderUsers() {
    const url = environment.apiUrl.concat('/providers');
    return this.httpClient.get<Auth0User[]>(url);
  }
}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { version } from '../../../../core/version';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const versionReq = req.clone({
      setHeaders: {
        Version: `${version.current}`,
      },
    });

    // send cloned request with header to the next handler.
    return next.handle(versionReq);
  }
}

import { PatientChartPayload } from '../../../dto';
import { ChartEpisodeOfCareClientResources } from '../../../enums';
import { TreatmentPlan } from '../treatment-plan';
import { sectionHasContent } from './treatment-plan-utility-functions';

export function treatmentPlanEpisodeOfCare(pdf: TreatmentPlan, chart: PatientChartPayload) {
  if (!sectionHasContent(chart, 'episodeOfCare')) {
    return;
  }

  const data = chart.episodeOfCare;
  pdf.addHeaderContent({ text: 'Episode of Care Treatment Plan' });

  if (data.treatmentGoalsGroup) {
    pdf.addParagraphContent({
      title: 'Treatment Goals',
      text: data.treatmentGoalsGroup,
    });
  }

  if (data.treatmentObjectivesGroup) {
    pdf.addParagraphContent({
      title: 'Treatment Objective',
      text: data.treatmentObjectivesGroup,
    });
  }

  if (data.plannedInterventionsGroup) {
    pdf.addParagraphContent({
      title: 'Planned Interventions / Patient Participation',
      text: data.plannedInterventionsGroup,
    });
  }

  if (data.clientResourcesGroup) {
    if (data.clientResourcesGroup.clientResources) {
      const text = Object.entries(data.clientResourcesGroup.clientResources)
        .filter(([key, value]) => value)
        .map(([key, value]) => (ChartEpisodeOfCareClientResources as any)[key])
        .join(', ');

      pdf.addParagraphContent({
        title: "Client's Strength / Resources to address this problem",
        text,
      });
    }

    if (data.clientResourcesGroup.note) {
      pdf.addNoteSubsection({
        title: 'Client Resources Note',
        text: data.clientResourcesGroup.note,
      });
    }
  }

  if (data.specialNeedsGroup) {
    pdf.addParagraphContent({
      title: 'Special Needs',
      text: data.specialNeedsGroup,
    });
  }
}

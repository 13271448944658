import { Margins } from 'pdfmake/interfaces';

export enum Color {
  Default = '#000000',
  Primary = '#4f4898',
  Accent = '#6963a7',
  Affirm = '#b2b916',
  Warn = '#ff6959',
  TableBkg = '#f7f7f7',
  TableLine = '#d4d4d4',
  Label = '#505050',
}

export enum Alignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum FontSize {
  Large = 24,
  Medium = 16,
  Content = 14,
  Label = 12,
  Default = 10,
  Small = 8,
}

export const pdfStyleDefinitions = {
  header: {
    bold: true,
  },
  sectionTable: {
    margin: [0, 0, 0, 4] as Margins,
  },
  space_md: {
    margin: [0, 0, 0, 20] as Margins,
  },
  space_sm: {
    margin: [0, 0, 0, 10] as Margins,
  },
  label: {
    color: Color.Label,
    bold: true,
    fontSize: FontSize.Label,
    margin: [0, 4] as Margins,
  },
};

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-loading-alert',
  templateUrl: './loading-alert.component.html',
  styleUrls: ['./loading-alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingAlertComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}

import { PatientChartPayload } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';
import { sectionHasContent } from './treatment-plan-utility-functions';

export function treatmentPlanPriorMentalHealthTreatment(pdf: TreatmentPlan, chart: PatientChartPayload) {
  if (!sectionHasContent(chart, 'priorMentalHealthTreatment')) {
    return;
  }

  const data = chart.priorMentalHealthTreatment;

  pdf.addHeaderContent({ text: 'Prior Mental Health Treatment' });

  if (data.priorTreatment) {
    pdf.addParagraphContent({
      title: 'Previous Mental Health Treatment, Mental Health Diagnosis or Hospitalizations',
      text: data.priorTreatment,
    });
  }

  if (data.medicationsNote) {
    pdf.addParagraphContent({
      title: 'Note on Current Medications',
      text: data.medicationsNote,
    });
  }

  if (data.responseToTreatment) {
    pdf.addParagraphContent({
      title: 'Response to Treatment(s)',
      text: data.responseToTreatment,
    });
  }

  if (data.familyHistory) {
    pdf.addParagraphContent({
      title: 'Family History of Mental Illness',
      text: data.familyHistory,
    });
  }
}

import { Auth0Role } from '../dto/user';

/**
 * Auth0 authorization extension user roles
 * Fetched from https://auth0.com/docs/api/authorization-extension#get-nested-group-roles
 * In the future these could be fetched dynamically.
 * It probably also makes sense to eventually update to use
 * the newer core Auth0 authz product
 * rather than the older authorization extension.
 */

export const authzRolesDev: Auth0Role[] = [
  {
    id: 'ac74861b-aebc-4285-904c-22681ffd8cbb',
    name: 'Coordinator',
  },
  {
    id: 'dd0b8772-f4aa-4762-b89b-c7d15ed9e4de',
    name: 'Provider',
  },
];

export const authzRolesStaging: Auth0Role[] = [
  {
    id: 'b355f43a-940b-421f-9995-4525104f0519',
    name: 'Coordinator',
  },
  {
    id: '45cbe595-474e-42e0-8916-c4992df2a5ec',
    name: 'Provider',
  },
];

export const authzRolesProduction: Auth0Role[] = [
  {
    id: 'ca8e8f18-17fe-4eaa-98cf-13b04d459251',
    name: 'Coordinator',
  },
  {
    id: 'e45ff202-8447-4089-9817-3823741d9538',
    name: 'Provider',
  },
];

export enum FamilyHistoryEnum {
  healthy = 'Healthy',
  unknown = 'Unknown',
  deceased = 'Deceased',
  highBloodPressure = 'High Blood Pressure',
  alzheimers = 'Alzheimers',
  parkinsons = 'Parkinsons',
  diabetes = 'Diabetes',
  obesity = 'Obesity',
  coronaryArteryDisease = 'Coronary Artery Disease',
  stroke = 'Stroke',
  epilepsy = 'Epilepsy',
  migraine = 'Migraine',
  sickleCell = 'Sickle Cell',
  bleedingDisorders = 'Bleeding Disorders',
  lipidDisorder = 'Lipid Disorder',
  thyroidDisorder = 'Thyroid Disorder',
  mentalIllness = 'Mental Illness',
  asthma = 'Asthma',
  alcoholism = 'Alcoholism',
  anemia = 'Anemia',
  osteoporosis = 'Osteoporosis',
  arthritis = 'Arthritis',
  ADHD = 'ADHD',
  ADD = 'ADD',
  hypertension = 'Hypertension',
  TB = 'TB',
  COPD = 'COPD',
  cancer = 'Cancer',
}

export enum FamilyMembers {
  father = 'Father',
  mother = 'Mother',
  brother = 'Brother',
  sister = 'Sister',
  aunt = 'Aunt',
  uncle = 'Uncle',
  cousin = 'Cousin',
  grandparent = 'Grandparent',
  children = 'Children',
}

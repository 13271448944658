import { MembershipDto } from '../dto/membership';
import { CompanyDto } from '../dto/company';
import { UserDto } from '../dto/user';
import { PatientDto } from '../dto/patient';
import { MembershipTypeDto } from '../dto/membership-type';

export class Membership {
  public readonly id: number;
  public readonly effectiveDate: string;
  public readonly terminationDate: string;
  public readonly expiresOn: string;
  public readonly lastRenewedOn: string;
  public readonly company: CompanyDto;
  public readonly verified: boolean;
  public readonly active: boolean;
  public readonly consent: boolean;
  public readonly users: UserDto[];
  public readonly patients: PatientDto[];
  public readonly primaryAccountHolder: UserDto;
  public readonly membershipType: MembershipTypeDto;
  public readonly signUpDetails: any;
  public readonly squareCustomerId: string | null;

  constructor(data: MembershipDto) {
    Object.assign(this, data);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findOption',
})
export class FindOptionPipe implements PipeTransform {
  transform(key: string | number, options: any[]): any {
    return options.find((option) => option.value === key) || {};
  }
}

import { AllergyReaction, AllergySeverity, ChartTypeEnum, TreatmentPlanScope } from '../../../enums';
import { MedicalHistoryData } from '../../../dto';
import { TableColumnWidthOption } from '../assets';
import { TreatmentPlan } from '../treatment-plan';
import { booleanToString } from '../../../utility';
import { startCase } from 'lodash';

export function treatmentPlanMedicalHistory(
  pdf: TreatmentPlan,
  history: MedicalHistoryData | undefined,
  chartType: ChartTypeEnum | undefined,
  pdfScope: TreatmentPlanScope | undefined,
) {
  // Not every patient has medical history yet
  if (!history) {
    return;
  }

  const isFullScope = pdfScope === TreatmentPlanScope.full;

  if (isFullScope) {
    pdf.addMedicalHistorySubheading('Comprehensive Patient History', history.updatedOn);
  } else {
    pdf.addMedicalHistorySubheading('Medical History', history.updatedOn);
  }

  // Shared pdf sections
  addMedicationsToHistory(pdf, history, true);

  if (isFullScope) {
    addMedicationsToHistory(pdf, history, false);
  }

  // Medical pdf sections
  if (chartType === ChartTypeEnum.medical) {
    addAllergies(pdf, history);

    if (isFullScope) {
      addConditions(pdf, history);
      addFamilyConditions(pdf, history);
      addSurgeryGroup(pdf, history);
      addOtherMedicalHistoryFields(pdf, history);
      addPregnancyGroup(pdf, history);
      addPreventativeScreenings(pdf, history);
      addImmunizations(pdf, history);
      addSocialHistory(pdf, history);
      addSubstanceUse(pdf, history);
    }
  }
}

export function addMedicationsToHistory(pdf: TreatmentPlan, history: MedicalHistoryData, active: boolean) {
  const medications = history.medicationGroup?.medications;

  if (medications) {
    const title = `${active ? 'Current' : 'Former'} Medications`;
    const columnHeaders = ['Medication', 'Refills', 'Start Date', 'End Date', 'SIG'];
    const columnKeys = ['name', 'refills', 'startDate', 'endDate', 'sigNote'];
    const columnWidths: TableColumnWidthOption[] = ['auto', 'auto', 'auto', 'auto', '*'];

    pdf.addTableContent({
      title,
      columnHeaders,
      columnKeys,
      columnWidths,
      tableData: medications
        ? medications
            .map((medication) => ({
              ...medication,
              name: medication.name || '',
              refills: medication.refills || '',
              startDate: medication.startDate || '',
              endDate: medication.endDate || '',
              sigNote: medication.sigNote || '',
              active: medication.active || false,
            }))
            .filter((medication) => {
              return medication.active === active;
            })
        : [],
    });
  }

  if (
    (history.medicationGroup?.currentMedicationNote && active) ||
    (history.medicationGroup?.formerMedicationNote && !active)
  ) {
    pdf.addNoteSubsection({
      title: `${active ? 'Current' : 'Former'} Medications Notes`,
      text: getNotes(active, history),
    });
  }
}

function getNotes(active: boolean, history: MedicalHistoryData): string {
  if (active && history?.medicationGroup?.currentMedicationNote) {
    return history.medicationGroup.currentMedicationNote;
  } else if (!active && history?.medicationGroup?.formerMedicationNote) {
    return history.medicationGroup.formerMedicationNote;
  } else {
    return '';
  }
}

function addAllergies(pdf: TreatmentPlan, history: MedicalHistoryData) {
  pdf.addSubSectionHeader('Allergies');

  const allergies = [
    ...(history?.nonDrugAllergyGroup?.allergies || []),
    ...(history?.drugClassAllergyGroup?.allergies || []),
    ...(history?.specificDrugAllergyGroup?.allergies || []),
  ];

  pdf.addDetailsSectionFromRecord({
    noKnownDrugAllergies: booleanToString(history.noKnownDrugAllergies || null),
    noKnownNonDrugAllergies: booleanToString(history.noKnownNonDrugAllergies || null),
  });

  pdf.addTableContent({
    columnHeaders: ['Allergy', 'Reaction', 'Severity'],
    columnKeys: ['allergySource', 'reaction', 'severity'],
    columnWidths: ['*', 'auto', 'auto'],
    tableData: allergies
      ? allergies.map((allergy) => ({
          allergySource: allergy.allergySource,
          reaction: AllergyReaction[allergy.reaction as keyof typeof AllergyReaction],
          severity: AllergySeverity[allergy.severity as keyof typeof AllergySeverity],
        }))
      : [],
  });

  pdf.addNoteSubsection({
    title: 'Allergies Note',
    text: pdf.mergeTextFields([
      history.specificDrugAllergyGroup?.note,
      history.drugClassAllergyGroup?.note,
      history.nonDrugAllergyGroup?.note,
    ]),
  });
}

export function addConditions(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const data = history.conditionGroup;
  if (!data) {
    return;
  }

  if (data.conditions) {
    pdf.addTableContent({
      title: 'Conditions',
      columnHeaders: ['Active', 'Name', 'ICD10', 'Date of Diagnosis'],
      columnKeys: ['active', 'conditionName', 'icd10', 'dateOfDiagnosis'],
      tableData: data.conditions.filter((condition) => pdf.hasContent(condition)),
      columnWidths: ['auto', '*', 'auto', 'auto'],
      booleanText: [['Yes', 'No']],
    });
  }

  if (data.note) {
    pdf.addNoteSubsection({
      title: 'Conditions Note',
      text: data.note,
    });
  }
}

export function addFamilyConditions(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const familyConditions = history.familyConditionsGroup?.note;
  if (familyConditions) {
    pdf.addParagraphContent({
      title: 'Family History',
      text: familyConditions,
    });
  }
}

export function addSurgeryGroup(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const data = history.surgeryGroup;
  if (!data) {
    return;
  }

  pdf.addSubSectionHeader('Surgical History');

  const surgeryRecords =
    data.surgeryList?.map((surgery) => ({
      surgery: surgery.surgeryName?.trim() || '',
      date: surgery.surgeryDate?.trim() || '',
    })) || [];

  pdf.addTableSectionFromRecords(surgeryRecords);

  if (data.note) {
    pdf.addNoteSubsection({
      title: 'Surgical History Note',
      text: data.note,
    });
  }
}

export function addOtherMedicalHistoryFields(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const lastExam = history.lastExamFormGroup;
  if (!lastExam) {
    return;
  }

  const otherFields = {
    dateOfLastPrimaryExam: lastExam.dateOfLastPrimaryExam || '',
    dateOfLastDentalExam: lastExam.dateOfLastDentalExam || '',
    dateOfLastEyeExam: lastExam.dateOfLastEyeExam || '',
    note: lastExam.note || '',
  };

  pdf.addDetailsSectionFromRecord(otherFields, 'Last Exam');
}

export function addPregnancyGroup(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const data = history.pregnancyGroup;
  if (!data) {
    return;
  }

  pdf.addDetailsSectionFromRecord(
    {
      dateOfLastMenstrualPeriod: data.dateOfLastMenstrualPeriod || '',
      currentlyPregnant: booleanToString(data.currentlyPregnant),
      currentlyBreastfeeding: booleanToString(data.currentlyBreastfeeding),
      chanceOfPregnancy: booleanToString(data.chanceOfPregnancy),
      note: data.note || '',
    },
    'Pregnancy',
  );
}

export function addPreventativeScreenings(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const data = history.preventativeScreeningsGroup;
  if (!data) {
    return;
  }

  pdf.addSubSectionHeader('Preventative Screenings');

  const screeningRecords =
    data.preventativeScreenings?.map((screening) => ({
      screening: screening?.screeningType?.trim() || '',
      date: screening?.dateOfScreening?.trim() || '',
    })) || [];

  pdf.addTableSectionFromRecords(screeningRecords);

  if (data.note) {
    pdf.addNoteSubsection({
      title: 'Preventative Screenings Note',
      text: data.note,
    });
  }
}

export function addImmunizations(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const text = history.immunizations;
  if (text) {
    pdf.addParagraphContent({
      title: 'Immunizations',
      text,
    });
  }
}

export function addSocialHistory(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const socialHistory = history.socialHistoryGroup;
  if (socialHistory) {
    const socialHistoryValues = {
      occupation: socialHistory.occupation || '',
      livingArrangements: socialHistory.livingArrangements || '',
      sexualHistory: socialHistory.sexualHistory || '',
      diet: socialHistory.diet || '',
      exercise: socialHistory.exercise || '',
      maritalStatus: startCase(socialHistory.maritalStatus || ''),
      note: socialHistory.note || '',
    };

    pdf.addDetailsSectionFromRecord(socialHistoryValues, 'Social History');
  }
}

export function addSubstanceUse(pdf: TreatmentPlan, history: MedicalHistoryData) {
  const substanceUse = history.substanceUseGroup;
  if (substanceUse) {
    const substanceUseValues = {
      smokingStatus: startCase(substanceUse.smokingStatus || ''),
      tobaccoProductsUsed: substanceUse.tobaccoProductsUsed?.join(', ') || '',
      secondHandSmokeExposure: booleanToString(substanceUse.secondHandSmokeExposure),
      vaping: booleanToString(substanceUse.vaping),
      alcoholUse: booleanToString(substanceUse.alcoholUse),
      quantityOfTobaccoProductsUsed: substanceUse.quantityOfTobaccoProductsUsed || '',
      smokingStartDate: substanceUse.smokingStartDate || '',
      smokingQuitDate: substanceUse.smokingQuitDate || '',
      otherSubstanceUse: substanceUse.otherSubstanceUse || '',
      note: substanceUse.note || '',
    };

    pdf.addDetailsSectionFromRecord(substanceUseValues, 'Substance Use');
  }
}

export function arrayPushOrSplice<T>(
  array: T[],
  value: T,
  valueShouldExist: boolean,
) {
  if (valueShouldExist) {
    return [...new Set([...array, value])];
  } else {
    return array.filter((v) => v !== value);
  }
}

import React from 'react';
import { Components, Theme } from '@mui/material';
import MessinaSansBold from '../../../assets/fonts/MessinaSans/MessinaSans-Bold.woff2';
import MessinaSansBoldItalic from '../../../assets/fonts/MessinaSans/MessinaSans-BoldItalic.woff2';
import MessinaSansRegular from '../../../assets/fonts/MessinaSans/MessinaSans-Regular.woff2';
import MessinaSansRegularItalic from '../../../assets/fonts/MessinaSans/MessinaSans-RegularItalic.woff2';
import MessinaSansSemiBold from '../../../assets/fonts/MessinaSans/MessinaSans-SemiBold.woff2';
import MessinaSansSemiBoldItalic from '../../../assets/fonts/MessinaSans/MessinaSans-SemiBoldItalic.woff2';
import MessinaSansThin from '../../../assets/fonts/MessinaSans/MessinaSans-Thin.woff2';
import MessinaSansThinItalic from '../../../assets/fonts/MessinaSans/MessinaSans-ThinItalic.woff2';
import SteradianBold from '../../../assets/fonts/Steradian/Steradian-Bold.woff2';
import SteradianBoldItalic from '../../../assets/fonts/Steradian/Steradian-BoldItalic.woff2';
import SteradianRegular from '../../../assets/fonts/Steradian/Steradian-Regular.woff2';
import SteradianRegularItalic from '../../../assets/fonts/Steradian/Steradian-RegularItalic.woff2';
import SteradianSemiBold from '../../../assets/fonts/Steradian/Steradian-SemiBold.woff2';
import SteradianSemiBoldItalic from '../../../assets/fonts/Steradian/Steradian-SemiBoldItalic.woff2';
import { FontFamily, typographyThemeOptions } from './typography';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const defaultIconSize = '24px';
export const defaultBorderRadius = '6px';

const CustomRouterLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref): React.JSX.Element => {
  const { href, ...other } = props;

  return <RouterLink ref={ref} to={href} {...other} />;
});

CustomRouterLink.displayName = 'RouterLink';

export const typographyComponentThemeOptions: Components<Omit<Theme, 'components'>> = {
  MuiAlert: {
    styleOverrides: {
      root: {
        fontFamily: `${FontFamily.Steradian}, sans-serif`,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: CustomRouterLink,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        ...typographyThemeOptions.subtitle1,
        boxShadow: 'none',
        textTransform: 'none',
        ':hover': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      size: defaultIconSize,
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        fontFamily: `${FontFamily.Steradian}, sans-serif`,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        fontFamily: `${FontFamily.Steradian}, sans-serif`,
        fontSize: '1.25rem',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontFamily: `${FontFamily.Steradian}, sans-serif`,
        fontSize: '1.25rem',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize: defaultIconSize,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: `
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 200;
          font-style: normal;
          src: url(${MessinaSansThin}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 200;
          font-style: italic;
          src: url(${MessinaSansThinItalic}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 500;
          font-style: normal;
          font-display: swap;
          src: url(${MessinaSansRegular}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 500;
          font-style: italic;
          src: url(${MessinaSansRegularItalic}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 600;
          font-style: normal;
          src: url(${MessinaSansSemiBold}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 600;
          font-style: italic;
          src: url(${MessinaSansSemiBoldItalic}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 700;
          font-style: normal;
          src: url(${MessinaSansBold}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.MessinaSans};
          font-weight: 700;
          font-style: italic;
          src: url(${MessinaSansBoldItalic}) format('woff2');
        }
        @font-face {
           fontFamily: ${FontFamily.Steradian};
          font-weight: 400;
          font-style: normal;
          src: url(${SteradianRegular}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.Steradian};
          font-weight: 400;
          font-style: italic;
          src: url(${SteradianRegularItalic}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.Steradian};
          font-weight: 500;
          font-style: normal;
          src: url(${SteradianSemiBold}) format('woff2');
        }
        @font-face {
           fontFamily: ${FontFamily.Steradian};
          font-weight: 500;
          font-style: italic;
          src: url(${SteradianSemiBoldItalic}) format('woff2');
        }
        @font-face {
           fontFamily: ${FontFamily.Steradian};
          font-weight: 700;
          font-style: normal;
          src: url(${SteradianBold}) format('woff2');
        }
        @font-face {
          fontFamily: ${FontFamily.Steradian};
          font-weight: 700;
          font-style: italic;
          src: url(${SteradianBoldItalic}) format('woff2');
        }
      `,
  },
};

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public sidenavOpened: Observable<boolean>;
  private _sidenavOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    this.sidenavOpened = this._sidenavOpened.asObservable();
  }

  public toggleSidenav() {
    const newState = !this._sidenavOpened.getValue();
    this._sidenavOpened.next(newState);
  }
}

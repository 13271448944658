import { DateTime } from 'luxon';
import { LabelledResponse } from '../../../dto';
import { DetailsData } from '../assets';
import { TreatmentPlan } from '../treatment-plan';

export function treatmentPlanIntakeForm(pdf: TreatmentPlan, intakeFormResponses: LabelledResponse[] | undefined) {
  if (!intakeFormResponses) {
    return;
  }

  if (!intakeFormResponses.some((response) => pdf.hasContent(response.result))) {
    return;
  }

  pdf.addHeaderContent({ text: 'Intake Form' });

  const prettifyValue = (value: unknown): string => {
    try {
      if (typeof value === 'boolean' || value === 'true' || value === 'false') {
        return value ? 'Yes' : 'No';
      }

      if (value instanceof Date) {
        return DateTime.fromJSDate(value).toISODate() as string;
      }

      if (typeof value === 'string' && DateTime.fromISO(value).isValid) {
        return DateTime.fromISO(value).toISODate() as string;
      }

      return value as string;
    } catch (error) {
      return value as string;
    }
  };

  const details: DetailsData['details'] = intakeFormResponses
    .map((response) => ({
      key: response.label,
      value: prettifyValue(response.result),
    }))
    .filter((detail) => pdf.hasContent(detail.value));

  pdf.addDetailsContent({
    details,
    style: 'table',
  });
}

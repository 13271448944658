import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-layout',
  templateUrl: './session-layout.component.html',
  styleUrls: ['./session-layout.component.scss'],
})
export class SessionLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

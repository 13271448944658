import { PatientChartPayload } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';

export function treatmentPlanSubjective(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const text = chart.subjective?.presentingProblemGroup;
  if (text) {
    pdf.addHeaderContent({ text: 'Subjective' });
    pdf.addParagraphContent({
      title: 'Presenting Problem',
      text,
    });
  }
}

import { booleanToString } from '../../../utility';
import { ReviewOfSystemsFormConfiguration } from '../../../constants';
import { PatientChartPayload, ReviewOfSystemsGroup } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';

export function treatmentPlanReviewOfSystems(pdf: TreatmentPlan, chart: PatientChartPayload) {
  const reviewOfSystems: ReviewOfSystemsGroup = chart.reviewOfSystems;

  const fieldsUsed: (keyof ReviewOfSystemsGroup)[] = [
    'hpiGroup',
    ...ReviewOfSystemsFormConfiguration.map((group) => group.formGroupName),
    'otherGroup',
  ];

  // If no fields referenced have content, proceed without adding any content
  if (!fieldsUsed.some((field) => pdf.hasContent(reviewOfSystems[field]))) {
    return;
  }

  // Header
  pdf.addHeaderContent({
    text: 'Review of Systems',
  });

  // History of present illness section
  pdf.addParagraphContent({
    title: 'HPI',
    text: reviewOfSystems.hpiGroup?.hpi || '',
  });

  // System Specific Groups
  for (const section of ReviewOfSystemsFormConfiguration) {
    const sectionGroup = reviewOfSystems[section.formGroupName];
    if (
      pdf.hasContent([
        // @ts-ignore
        sectionGroup[section.formControlName],
        sectionGroup.note,
      ]) ||
      sectionGroup.isHealthy
    ) {
      const title = section.heading;

      pdf.addSubSectionHeader(title);

      if (sectionGroup.isHealthy) {
        pdf.addParagraphContent({ text: 'Healthy/WNL' });
      } else {
        const details = pdf.mapOptionsToDetails(
          section.options,
          // @ts-ignore
          sectionGroup[section.formControlName],
          (_, data) => booleanToString(data as boolean),
          (_, data) => pdf.hasContent(data),
        );

        pdf.addDetailsContent({
          details,
          style: 'table',
        });
      }

      pdf.addGroupNote(`${section.heading} Notes`, sectionGroup);
    }
  }

  // Other Group
  const otherGroup = reviewOfSystems.otherGroup;
  if (otherGroup?.note || otherGroup?.reviewedAndNegative) {
    const title = 'Other';
    const fields = {
      allOtherSystemsWereReviewedAndNegative: booleanToString(otherGroup?.reviewedAndNegative),
      notes: otherGroup?.note || '',
    };

    pdf.addDetailsSectionFromRecord(fields, title);
  }
}

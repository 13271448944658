import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface TextDialogModel {
  title?: string;
  textDismiss?: string;
  message: string;
  maxWidth?: string;
}

@Component({
  selector: 'app-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.scss'],
})
export class TextDialogComponent {
  public title: string;
  public textDismiss: string;
  public message: string;

  constructor(
    public dialogRef: MatDialogRef<TextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.textDismiss = data.textDismiss ? data.textDismiss : 'Close';
    this.message = data.message;
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

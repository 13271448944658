import { PatientChartPayload } from '../../../dto';
import { TreatmentPlan } from '../treatment-plan';

export function treatmentPlanAssessment(pdf: TreatmentPlan, chart: PatientChartPayload): boolean {
  const assessment = chart.assessment;
  if (assessment) {
    const hasICD10 = assessment?.assessmentGroup?.icd10 ? assessment.assessmentGroup.icd10.length > 0 : false;
    const hasNote = assessment?.assessmentGroup?.note ? assessment.assessmentGroup.note.trim().length > 0 : false;

    // no content in section, return with no action
    if (!hasICD10 && !hasNote) {
      return false;
    }

    pdf.addHeaderContent({
      text: 'Assessment',
    });

    pdf.addTableContent({
      columnHeaders: ['ICD10', 'Description', 'Common Diagnosis Description'],
      columnKeys: ['code', 'description', 'commonDescription'],
      tableData: assessment.assessmentGroup.icd10 || [],
    });

    pdf.addNoteSubsection({
      text: assessment?.assessmentGroup?.note?.trim() || '',
    });

    return true;
  }

  return false;
}

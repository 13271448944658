import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, pairwise, switchMap, tap, throttleTime } from 'rxjs/operators';

import {
  CreateHomeVisitRequestDto,
  HomeVisitRequestCounts,
  HomeVisitRequestDto,
  HomeVisitRequestUpdateDto,
} from '@dto';
import { ProviderProfileWithScheduleDataDto } from '@dto';

import { environment } from '../../../environments/environment';

import { UtilityService } from './utility.service';
import { PaginationInformation } from '../../shared/server-side-paginated-table/datasources/generic.datasource';
import { UserService } from './user.service';
import { PaginatedResponse } from '@models';
import { HomeVisitRequestStatus, VisitPriority } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class HomeVisitRequestService {
  public homeVisitRequestCounts: Observable<HomeVisitRequestCounts>;
  public unviewedHomeVisitRequestCount: Observable<number>;
  public openHomeVisitRequestList: Observable<HomeVisitRequestDto[]>;
  public homeVisitAvailabilityList: Observable<ProviderProfileWithScheduleDataDto[]>;
  public homeVisitRequestsRefresh = new BehaviorSubject<boolean>(false);

  private _homeVisitRequestCounts = new BehaviorSubject<HomeVisitRequestCounts>({
    totalCount: 0,
    userCount: 0,
    assignedCount: 0,
    unassignedCount: 0,
    unviewedCount: 0,
  });
  private _unviewedHomeVisitRequestCount = new BehaviorSubject<number>(0);
  private _openHomeVisitRequestList = new BehaviorSubject<HomeVisitRequestDto[]>(null); // null value used as flag for initial load
  private _homeVisitAvailabilityList = new BehaviorSubject<ProviderProfileWithScheduleDataDto[]>([]);

  constructor(
    private userService: UserService,
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {
    this.homeVisitRequestCounts = this._homeVisitRequestCounts.asObservable();
    this.unviewedHomeVisitRequestCount = this._unviewedHomeVisitRequestCount.asObservable();
    this.openHomeVisitRequestList = this._openHomeVisitRequestList.asObservable();
    this.homeVisitAvailabilityList = this._homeVisitAvailabilityList.asObservable();

    combineLatest([this.userService.currentUserRoles, this.homeVisitRequestsRefresh])
      .pipe(switchMap(([userRoles, refresh]) => this.getOpenHomeVisitRequests()))
      .subscribe((result) => this._openHomeVisitRequestList.next(result));

    this.utilityService
      .createNewPollingObservable<HomeVisitRequestCounts>(
        this.getRequestCounts.bind(this),
        environment.homeVisitRequestPollingRate
      )
      .subscribe((counts) => {
        this._homeVisitRequestCounts.next(counts);
      });

    this.utilityService
      .createNewPollingObservable<number>(this.getUnviewedRequests.bind(this), environment.homeVisitRequestPollingRate)
      .subscribe((requests) => {
        this._unviewedHomeVisitRequestCount.next(requests);
      });

    // If the request count changes, refresh the home visit list
    this.unviewedHomeVisitRequestCount.pipe(throttleTime(2000), pairwise()).subscribe((countPair) => {
      const previousCount = countPair[0];
      const newCount = countPair[1];

      if (previousCount !== newCount) {
        this.refreshHomeVisitList();
      }
    });
  }

  public getHomeVisitRequestsPaginated(queryParams: HttpParams, pagination?: PaginationInformation) {
    const {
      showDenied,
      appointmentType,
      reasonType,
      status,
      visitPriority,
      patientVerificationStatus,
      assignment,
      patientNameSearch,
      patientIdSearch,
      startDate,
      endDate,
      requestedBy,
    } = pagination.filters;

    queryParams = queryParams.append('showDenied', showDenied as string);

    if (appointmentType && appointmentType !== 'SHOW_ALL') {
      queryParams = queryParams.append('appointmentTypeId', appointmentType as string);
    }
    if (visitPriority && visitPriority !== 'SHOW_ALL') {
      queryParams = queryParams.append('visitPriority', visitPriority as keyof typeof VisitPriority);
    }

    if (reasonType && reasonType !== 'SHOW_ALL') {
      queryParams = queryParams.append('reasonType', reasonType as string);
    }

    if (startDate) {
      queryParams = queryParams.append('startDate', startDate as string);
    }

    if (endDate) {
      queryParams = queryParams.append('endDate', endDate as string);
    }

    if (patientNameSearch) {
      const patientNames = patientNameSearch as string[];
      patientNames.forEach((patient: string) => {
        queryParams = queryParams.append('selectedPatientIds', patient);
      });
    }
    if (patientIdSearch && patientIdSearch !== 'SHOW_ALL') {
      queryParams = queryParams.append('selectedPatientIds', patientIdSearch as string);
    }

    if (patientVerificationStatus && patientVerificationStatus !== 'SHOW_ALL') {
      const value = patientVerificationStatus === 'verified' ? 'true' : 'false';
      queryParams = queryParams.append('verifiedStatus', value);
    }

    if (status && status !== 'SHOW_ALL') {
      const value =
        status === 'filled'
          ? HomeVisitRequestStatus.filled
          : status === 'rejected'
          ? HomeVisitRequestStatus.rejected
          : null;
      queryParams = queryParams.append('status', value);
    }

    if (assignment && assignment !== 'SHOW_ALL') {
      queryParams = queryParams.append('assignment', assignment as string);
    }

    if (requestedBy) {
      const requestedProviders = requestedBy as string[];
      requestedProviders.forEach((provider: string) => {
        queryParams = queryParams.append('requestedBy', provider);
      });
    }

    const url = environment.apiV3Url.concat('/home-visit-requests');
    return this.httpClient.get<PaginatedResponse<HomeVisitRequestDto>>(url, {
      params: queryParams,
    });
  }

  public assignToUser(requestId: number, userId: string) {
    const url = environment.apiV3Url.concat(`/home-visit-requests/assign-to-user/${requestId}`);
    return this.httpClient.put(url, { userId });
  }

  public assignToSelf(requestId: number) {
    const url = environment.apiV3Url.concat(`/home-visit-requests/assign-to-self/${requestId}`);
    return this.httpClient.put(url, {});
  }

  public unassign(requestId: number) {
    const url = environment.apiV3Url.concat(`/home-visit-requests/unassign/${requestId}`);
    return this.httpClient.put(url, {});
  }

  /**
   * Get all open home visit requests
   */
  public getOpenHomeVisitRequests() {
    const url = environment.apiUrl.concat('/home-visit-requests');
    return this.httpClient.get<HomeVisitRequestDto[]>(url);
  }

  public getProviderAvailability(id: string | number, dateTime?: string) {
    const url = environment.apiUrl.concat(`/home-visit-requests/${id}/providers`);
    return this.httpClient.get<ProviderProfileWithScheduleDataDto[]>(url, {
      params: { timeSlotOverride: dateTime },
    });
  }

  /**
   * Get a HomeVisitRequest by id
   */
  public getHomeVisitRequest(id: string | number) {
    const url = environment.apiUrl.concat(`/home-visit-requests/${id}`);
    return this.httpClient.get<HomeVisitRequestDto>(url);
  }

  /**
   * Get home visit requests for a chart based on the patient ID and the appointment ID
   */
  public getVisitRequestsForChart(patientId: number, appointmentId: string) {
    const url = environment.apiUrl.concat(`/home-visit-requests/chart/${patientId}/${appointmentId}`);
    return this.httpClient.get<HomeVisitRequestDto[]>(url);
  }

  public refreshHomeVisitList() {
    return this.getOpenHomeVisitRequests()
      .toPromise()
      .then((list) => this._openHomeVisitRequestList.next(list));
  }

  public refreshUnreadHomeVisitList() {
    return this.getUnviewedRequests()
      .toPromise()
      .then((requests) => this._unviewedHomeVisitRequestCount.next(requests));
  }

  /**
   * Create a new home visit request
   */
  public createHomeVisitRequest(request: CreateHomeVisitRequestDto) {
    const url = environment.apiUrl.concat('/home-visit-requests');
    return this.httpClient.post(url, request).toPromise();
  }

  /**
   * Patch a HomeVisitRequest by id
   */
  public patchHomeVisitRequest(id: string | number, update: Partial<HomeVisitRequestDto>) {
    const url = environment.apiUrl.concat(`/home-visit-requests/${id}`);

    return this.httpClient.patch(url, update).pipe(
      tap(() => {
        // updates the alert count and triggers data refresh
        this.refreshUnreadHomeVisitList();
      })
    );
  }

  /**
   * Set request as status.rejected and notify requesting provider
   */
  public async denyRequest(request: HomeVisitRequestDto) {
    const url = environment.apiUrl.concat(`/home-visit-requests/deny/${request.id}`);
    return this.httpClient.put(url, {}).toPromise();
  }

  public updateRequest(id: number, payload: HomeVisitRequestUpdateDto) {
    Object.entries(payload.address).forEach(([key, value]) => {
      if (!value) {
        (payload.address as any)[key] = '';
      }
    });
    const url = environment.apiV3Url.concat(`/home-visit-requests/update/${id}`);
    return this.httpClient.put(url, payload);
  }

  public getDatetimeAvailability(
    datetime: string,
    appointmentTypeId: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zip: string,
    patientIds: string[]
  ) {
    const url = environment.apiV3Url.concat('/home-visit-requests/availability');

    const params = new HttpParams({
      fromObject: {
        datetime,
        appointmentTypeId,
        address1,
        address2,
        city,
        state,
        zip,
        patientIds,
      },
    });

    return this.httpClient.get<ProviderProfileWithScheduleDataDto[]>(url, {
      params,
    });
  }

  private getRequestCounts() {
    const url = environment.apiV3Url.concat('/home-visit-requests/count');
    return this.httpClient.get<HomeVisitRequestCounts>(url);
  }

  /**
   * Get a count of all open and unviewed home visit requests
   */
  private getUnviewedRequests() {
    const url = environment.apiUrl.concat('/home-visit-requests/unviewed');
    return this.httpClient.get<{ count: number }>(url).pipe(map((value) => value.count));
  }
}

<app-progress-button
  *ngIf="!iconButton"
  [disabled]="disabled"
  [text]="buttonText"
  [submitting]="isLoading"
  (submit)="initializeSSO()"
></app-progress-button>
<div *ngIf="iconButton">
  <button
    *ngIf="!isLoading"
    mat-icon-button
    [disabled]="disabled"
    [matTooltip]="buttonText"
    [matTooltipPosition]="tooltipPosition"
    (click)="initializeSSO()"
  >
    <mat-icon>medication</mat-icon>
  </button>
  <mat-spinner *ngIf="isLoading" [diameter]="40"></mat-spinner>
</div>
<form
  id="saml-form"
  method="post"
  target="_blank"
  [action]="entityEndpoint"
  autocomplete="off"
  class="sso-form"
  #ssoForm
>
  <input
    *ngFor="let item of queryParams | keyvalue"
    type="text"
    [id]="item.key"
    [name]="item.key"
    [value]="item.value"
  />
</form>

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Patient } from '@models';

import { PatientService } from '../../core/services/patient.service';
import { NavigationService } from '../../core/services/navigation.service';
import { ChatService } from '../../core/services/chat.service';
import { MembershipService } from '../../core/services/membership.service';
import { AlertService } from '../../core/services/alert.service';
import PatientInfoSummaryWrapper from '../../react/ui/PatientInfoSummary/PatientInfoSummaryWrapper';

@Component({
  selector: 'app-patient-layout',
  templateUrl: './patient-layout.component.html',
  styleUrls: ['./patient-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientLayoutComponent implements OnInit, OnDestroy {
  public opened: Observable<boolean>;
  public patient$: Observable<Patient>;

  public unreadMessageCountFromPatient: Observable<number>;

  public capsuleEligibility$: Observable<boolean>;
  public capsuleEligibilityCheckInProgress = false;
  public capsuleDeliveryAvailable = false;

  protected readonly PatientInfoSummaryWrapper = PatientInfoSummaryWrapper;

  private destroyed: Subject<boolean> = new Subject<boolean>();

  constructor(
    public alertService: AlertService,
    public membershipService: MembershipService,
    public navigationService: NavigationService,
    private patientService: PatientService,
    private chatService: ChatService
  ) {}

  ngOnInit() {
    this.opened = this.navigationService.sidenavOpened;
    this.patient$ = this.patientService.selectedPatient.pipe(filter((v) => !!v));

    this.capsuleEligibility$ = this.patient$.pipe(
      tap(() => {
        this.capsuleEligibilityCheckInProgress = true;
      }),
      switchMap((patient) => {
        if (patient.addresses && patient.addresses.length > 0) {
          return this.membershipService.getCapsuleEligibility(patient.addresses[0].zip).pipe(
            map((v) => {
              this.capsuleDeliveryAvailable = v.isDeliverable;
              this.capsuleEligibilityCheckInProgress = false;
              return v.isDeliverable;
            })
          );
        } else {
          this.capsuleDeliveryAvailable = false;
          this.capsuleEligibilityCheckInProgress = false;

          return of(false);
        }
      }),
      shareReplay(1)
    );

    this.unreadMessageCountFromPatient = this.patient$.pipe(
      switchMap((patient) => {
        return this.chatService.unreadMessages.pipe(
          map((messages) => {
            const unreadMessagesFromPatient = messages.filter((message) => message.patientId === patient.id);
            return unreadMessagesFromPatient.length;
          })
        );
      })
    );

    this.capsuleEligibility$.pipe(takeUntil(this.destroyed)).subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }
}

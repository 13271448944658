import { createPdf } from 'pdfmake/build/pdfmake';
import { Margins } from 'pdfmake/interfaces';
import { nicePdfFonts } from './pdf-fonts';
import { FontSize, Alignment, Color, pdfStyleDefinitions } from './pdf-gen-styles';

export type PdfMakeDocDefinition = Parameters<typeof createPdf>[0];
export type DocumentConfiguration = Omit<PdfMakeDocDefinition, 'content' | 'styles'>;
export interface DocumentDefinition {
  info: DocumentInfo;
  content: object[];
  styles: object;
}

export interface DocumentInfo {
  title?: string;
  author?: string;
  subject?: string;
}

export type PdfDocument = (Stack | ContentTypes)[];

export type StyleName = keyof typeof pdfStyleDefinitions;
export type FontName = keyof typeof nicePdfFonts;

export type ContentTypes =
  | HeaderContent
  | ParagraphContent
  | DetailsContent
  | OrderedListContent
  | UnorderedListContent
  | ImageContent
  | TableContent
  | TextArrayObject;

export interface TextStyleObject {
  alignment?: Alignment;
  color?: Color;
  fontSize?: FontSize;
  bold?: boolean;
  italics?: boolean;
  margin?: Margins;
  style?: StyleName | StyleName[];
  font?: FontName;
  link?: string;
}

export interface TextObject extends TextStyleObject {
  text: string;
}

export interface TextArrayObject extends TextStyleObject {
  text: (string | TextObject)[];
}

export interface Stack {
  stack: ContentTypes[];
  margin: Margins;
  unbreakable?: boolean;
}

// For creating a header
export interface HeaderData {
  text: string;
  font?: FontName;
  fontSize?: FontSize;
  alignment?: Alignment;
  color?: Color;
  margin?: Margins;
  style?: StyleName | StyleName[];
}

// The created header content
export interface HeaderContent {
  text: string;
  alignment: Alignment;
  color: Color;
  font: FontName;
  fontSize: FontSize;
  margin: Margins;
  style: StyleName | StyleName[];
}

export interface ParagraphData {
  text: string;
  fontSize?: FontSize;
  alignment?: Alignment;
  color?: Color;
  margin?: Margins;
  bold?: boolean;
  italics?: boolean;
  title?: string;
}

export interface ParagraphContent {
  text: TextWithLinks;
  alignment: Alignment;
  color: Color;
  fontSize: FontSize;
  bold: boolean;
  margin: Margins;
  italics: boolean;
  style: 'paragraph';
}

export type DetailsRow = [TextObject, TextArrayObject];

export interface DetailsData {
  details: {
    key: string;
    value: string;
  }[];
  title?: TextObject;
  margin?: Margins;
  keyStyle?: TextStyleObject;
  valueStyle?: TextStyleObject;
  style?: 'default' | 'table';
}

export interface DetailsContent {
  layout: string;
  table: {
    body: DetailsRow[];
  };
  style: string;
}

export enum UnorderedListType {
  Default = '',
  Square = 'square',
  Circle = 'circle',
  None = 'none',
}

export enum OrderedListType {
  Default = '',
  LowerAlpha = 'lower-alpha',
  UpperAlpha = 'upper-alpha',
  LowerRoman = 'lower-roman',
  UpperRoman = 'upper-roman',
  None = 'none',
}
export interface ListData {
  listItems: string[];
  ordered?: boolean;
  title?: string;
  margin?: Margins;
  type?: UnorderedListType | OrderedListType;
}

interface ListContent {
  type: UnorderedListType | OrderedListType;
}

export interface OrderedListContent extends ListContent {
  ol: TextWithLinks[];
}

export interface UnorderedListContent extends ListContent {
  ul: TextWithLinks[];
}

export interface ImageData {
  base64: string;
  alignment?: Alignment;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  margin?: Margins;
}

export interface ImageContent {
  image: string;
  alignment: Alignment;
  margin: Margins;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
}

interface TableCellLayout {
  fillColor: Function;
  vLineWidth: Function;
  hLineWidth: Function;
  hLineColor: Function;
  paddingTop: Function;
  paddingBottom: Function;
  paddingLeft: Function;
  paddingRight: Function;
}

export type TableColumnWidthOption = '*' | 'auto' | number;

export interface TableContent {
  style: 'sectionTable';
  layout: TableCellLayout;
  margin?: Margins;
  table: {
    headerRows: 1;
    widths: TableColumnWidthOption[];
    body: TextObject[][];
    style: 'sectionTable';
  };
}

type BooleanTextTuple = [string, string];

export interface TableData {
  columnHeaders: string[];
  columnKeys: string[];
  tableData: object[]; // Can be any source object coming in with keys that coincide with columnKeys
  title?: string;
  columnWidths?: TableColumnWidthOption[];
  margin?: Margins;
  booleanText?: BooleanTextTuple[];
  headerStyle?: TextStyleObject;
  bodyStyle?: TextStyleObject;
}

export type TextWithLinks = (string | { text: string; link: string })[];

import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import { isNil, isObject, startCase } from 'lodash';
import { PageOrientation } from 'pdfmake/interfaces';

import { deepFlattenCollection } from '../../utility';

import { DetailsData, FontSize, pdfStyleDefinitions } from './assets';
import { NicePdfGenerator } from './nice-pdf-generator';
import { ChartTypeEnum, TreatmentPlanScope } from '../../enums';

import { treatmentPlanMedicalHistory } from './treatment-plan-content/treatment-plan-medical-history';
import { treatmentPlanIntakeForm } from './treatment-plan-content/treatment-plan-intake-form';
import { treatmentPlanPatientVitals } from './treatment-plan-content/treatment-plan-patient-vitals';
import { treatmentPlanReviewOfSystems } from './treatment-plan-content/treatment-plan-review-of-systems';
import { treatmentPlanPhysicalExam } from './treatment-plan-content/treatment-plan-physical-exam';
import { treatmentPlanInHouseLabResults } from './treatment-plan-content/treatment-plan-in-house-lab-results';
import { treatmentPlanAssessment } from './treatment-plan-content/treatment-plan-assessment';
import { treatmentPlanPlan } from './treatment-plan-content/treatment-plan-plan';
import { treatmentPlanProceduresPerformed } from './treatment-plan-content/treatment-plan-procedures-performed';
import { TreatmentPlanPayload } from './treatment-plan-content/treatment-plan-types';
import { treatmentPlanSubjective } from './treatment-plan-content/treatment-plan-subjective';
import { treatmentPlanObjective } from './treatment-plan-content/treatment-plan-objective';
import { treatmentPlanEpisodeOfCare } from './treatment-plan-content/treatment-plan-episode-of-care';
import { treatmentPlanMentalStatus } from './treatment-plan-content/treatment-plan-mental-status';
import { treatmentPlanPriorMentalHealthTreatment } from './treatment-plan-content/treatment-plan-prior-mental-health-treatment';
import { GroupWithNote } from '@dto';

export class TreatmentPlan extends NicePdfGenerator {
  public static getTreatmentPlanTimeFormat(timeZone: string): LocaleOptions & DateTimeFormatOptions {
    return {
      timeZone,
      timeZoneName: 'short',
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
  }

  public static getTreatmentPlanFileName({
    patientId,
    appointmentId,
    scheduledAppointmentTime,
    fileExtension = 'pdf',
    isMentalHealthTreatmentPlan,
    treatmentPlanScope,
  }: {
    patientId: string | number | object;
    appointmentId: string;
    scheduledAppointmentTime: Date;
    fileExtension?: string;
    isMentalHealthTreatmentPlan: boolean;
    treatmentPlanScope: TreatmentPlanScope;
  }) {
    const timestamp = DateTime.fromJSDate(scheduledAppointmentTime).toUTC().toISO();

    let type = treatmentPlanScope.toLocaleUpperCase().replace(' ', '_');

    if (isMentalHealthTreatmentPlan) {
      type = `MENTAL_HEALTH_${type}`;
    }

    return `${patientId}/${type}_${appointmentId}_${timestamp}.${fileExtension}`;
  }

  private isFullTreatmentPlan = false;

  constructor() {
    super();
    this.docConfiguration.pageOrientation = 'landscape' as PageOrientation;
    this.docConfiguration.pageMargins = 36;
  }

  define(data: TreatmentPlanPayload): void {
    // Create details section
    const credentials = data.provider.credentials?.length ? `, ${data.provider.credentials.join(', ')}` : '';
    const providerName = `${data.provider.firstName} ${data.provider.lastName}`;
    const patientName = `${data.patient.firstName} ${data.patient.lastName}`;
    const patientDOB = data.patient.dateOfBirth;

    this.docConfiguration.footer = NicePdfGenerator.getDynamicFooterContent(patientName, patientDOB);

    const visitDate = DateTime.fromJSDate(data.visitDate, {
      zone: data.patientTimezone,
    }).toLocaleString(TreatmentPlan.getTreatmentPlanTimeFormat(data.patientTimezone));

    this.addDetailsContent({
      title: { text: 'Visit Details' },
      details: [
        { key: 'Patient:', value: patientName },
        { key: 'Visit Date:', value: visitDate },
        { key: 'Provider:', value: `${providerName}${credentials}` },
      ],
      margin: [0, 0, 0, 8],
    });

    this.isFullTreatmentPlan = data.treatmentPlanScope === TreatmentPlanScope.full;
    const chartType: ChartTypeEnum | undefined = data?.chart?.chartType?.type;

    treatmentPlanMedicalHistory(this, data.patientHistory, chartType, data.treatmentPlanScope);

    this.addHorizontalDivider();
    this.addTreatmentPlanSubheading(data.visitDate);

    let planAssessmentAdded: boolean = false;
    let planPlanAdded: boolean = false;

    if (this.isFullTreatmentPlan) {
      switch (chartType) {
        case ChartTypeEnum.medical:
          treatmentPlanIntakeForm(this, data.intakeForm);
          treatmentPlanPatientVitals(this, data.chart);
          treatmentPlanReviewOfSystems(this, data.chart);
          treatmentPlanPhysicalExam(this, data.chart);
          treatmentPlanProceduresPerformed(this, data.chart);
          treatmentPlanInHouseLabResults(this, data.chart);

          planAssessmentAdded = treatmentPlanAssessment(this, data.chart);
          planPlanAdded = treatmentPlanPlan(this, data.chart, data.treatmentPlanScope);
          break;

        case ChartTypeEnum.mentalHealth:
          treatmentPlanSubjective(this, data.chart);
          treatmentPlanObjective(this, data.chart);

          planAssessmentAdded = treatmentPlanAssessment(this, data.chart);
          planPlanAdded = treatmentPlanPlan(this, data.chart, data.treatmentPlanScope);

          treatmentPlanMentalStatus(this, data.chart);
          treatmentPlanPriorMentalHealthTreatment(this, data.chart);
          treatmentPlanEpisodeOfCare(this, data.chart);
          break;
      }
    } else {
      // Summary PDFs include these only, full charts use their own custom order
      planAssessmentAdded = treatmentPlanAssessment(this, data.chart);
      planPlanAdded = treatmentPlanPlan(this, data.chart, data.treatmentPlanScope);
    }

    // If no treatment plan items were added, we need to revert adding the 'Treatment Plan' header for this section
    // along with the horizontal divider.
    if (!planAssessmentAdded && !planPlanAdded) {
      this.removeLastContentItemFromDocument(); // Remove timestamp.
      this.removeLastContentItemFromDocument(); // Remove header text.
      this.removeLastContentItemFromDocument(); // Remove horizontal divider.
    }
  }

  public addSubSectionHeader(text: string) {
    this.addHeaderContent({
      text,
      fontSize: FontSize.Medium,
      margin: [0, 4],
    });
  }

  public addMedicalHistorySubheading(heading: string, lastUpdatedOn: Date | string) {
    // Add treatment plan header.
    this.addHeaderContent({ text: heading });

    const medicalHistoryLastUpdatedDate = TreatmentPlan.getDateTime(lastUpdatedOn);

    if (medicalHistoryLastUpdatedDate === undefined || medicalHistoryLastUpdatedDate === null) {
      // We don't want to render this with an invalid date.
      throw new Error(`Invalid medical history timestamp provided: ${lastUpdatedOn}`);
    }

    this.addHeaderContent({
      ...pdfStyleDefinitions.label,
      text: `As of ${medicalHistoryLastUpdatedDate.toFormat('MM/dd/yyyy')} at ${medicalHistoryLastUpdatedDate.toFormat(
        'h:mm a',
      )}`,
      style: 'header',
    });
  }

  public addTreatmentPlanSubheading(treatmentDate: Date) {
    // Add treatment plan header.
    this.addHeaderContent({ text: 'Treatment Plan' });

    const treatmentTimestamp = TreatmentPlan.getDateTime(treatmentDate);

    if (treatmentTimestamp === undefined || treatmentTimestamp === null) {
      // We don't want to render this with an invalid date.
      throw new Error(`Invalid treatment date timestamp provided: ${treatmentDate}`);
    }

    this.addHeaderContent({
      ...pdfStyleDefinitions.label,
      text: `Encounter date of ${treatmentTimestamp.toFormat('MM/dd/yyyy')}`,
      style: 'header',
    });
  }

  public addNoteSubsection({ title = 'Note', text }: { title?: string; text: string }) {
    if (this.hasContent(text)) {
      this.addHeaderContent({
        ...pdfStyleDefinitions.label,
        text: title?.toUpperCase(),
        style: 'header',
      });
      this.addParagraphContent({ text, margin: [0, 0, 0, 4] });
    }
  }

  public addGroupNote(title: string, groupWithNote: GroupWithNote) {
    return this.addNoteSubsection({ title, text: groupWithNote?.note ? groupWithNote?.note : '' });
  }

  public mapOptionsToDetails<O extends object, T>(
    options: O,
    data: any,
    mapFn?: (option: keyof O, data: T) => string,
    filterFn?: (option: keyof O, data: T) => boolean,
  ): DetailsData['details'] {
    return (<[keyof O, string][]>Object.entries(options))
      .filter(([option]) => {
        return filterFn ? filterFn(option, data?.[option]) : !isNil(data?.[option]);
      })
      .map(([option, label]) => ({
        key: label as string,
        value: mapFn ? mapFn(option, data?.[option]) : data?.[option]?.toString(),
      }));
  }

  public mergeTextFields(fields: (string | null | undefined)[], joiner = '\n'): string {
    return fields
      .filter((field) => {
        return field?.trim();
      })
      .join(joiner);
  }

  public hasContent(data: unknown, customFilterFn?: (a: any) => boolean) {
    const filterFn =
      customFilterFn ||
      ((node: unknown) => {
        if (typeof node === 'string') {
          return node.trim().length > 0;
        }
        return !isNil(node);
      });

    if (!isObject(data)) {
      return filterFn(data);
    }

    const nodesWithContent = deepFlattenCollection(data).filter(filterFn);

    return nodesWithContent.length > 0;
  }

  public addDetailsSectionFromRecord(record: Record<string, string>, title?: string) {
    const details = Object.entries(record)
      .map(([key, value]) => ({
        value,
        key: startCase(key),
      }))
      .filter((detail) => {
        return this.hasContent(detail.value);
      });

    const noteFieldIndex = details.findIndex((detail) => {
      return detail.key === 'Note';
    });

    // If a note field is contained in the record, move it to the end
    if (noteFieldIndex >= 0) {
      details.push(...details.splice(noteFieldIndex, 1));
    }

    this.addDetailsContent({
      details,
      title: title ? { text: title } : undefined,
      style: 'table',
    });
  }

  public addTableSectionFromRecords(tableData: Record<string, string>[], title?: string) {
    if (tableData.length === 0) {
      return;
    }

    const template = tableData[0];
    const columnKeys = Object.keys(template);
    const columnHeaders = columnKeys.map(startCase);

    this.addTableContent({
      title,
      columnKeys,
      columnHeaders,
      tableData: tableData.filter((row) => {
        return this.hasContent(row);
      }),
    });
  }

  private static getDateTime(timestamp: Date | string): DateTime | null {
    if (timestamp) {
      if (timestamp instanceof Date) {
        return DateTime.fromJSDate(timestamp);
      }

      return DateTime.fromISO(timestamp);
    }

    return null;
  }
}

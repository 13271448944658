import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isObject, isString } from 'lodash';

@Injectable()
export class ReplaceCharactersInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body && !(req.body instanceof FormData)) {
      const values = this.replaceSpecialCharacters(req.body);
      const transformedReq = req.clone({
        body: { ...values },
      });
      return next.handle(transformedReq);
    }
    return next.handle(req);
  }

  replaceSpecialCharacters(form) {
    for (const k in form) {
      if (Object.hasOwn(form, k)) {
        if (isString(form[k])) {
          // list of bullet type characters that need to be converted to a standard bullet point
          const regex = /[○∙‣⁌⁍∙◘◦☙❥❧⦾⦿⁃]/g;
          form[k] = form[k].replace(regex, '•');
          continue;
        }
        if (isObject(form[k])) {
          this.replaceSpecialCharacters(form[k]);
        }
      }
    }
    return form;
  }
}

export enum CommunicationToolsUsed {
  teachBack = 'Teach-Back',
  nicePass = 'NICE-PASS',
  na = 'N/A',
}

export enum EMRImpact {
  helped = 'EMR helped',
  hindered = 'EMR got in the way',
  neither = 'Neither',
}

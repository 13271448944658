import { PatientDto } from '../dto/patient';
import { ChannelType } from '../enums/message';
import { ScheduledMessageDto } from '../dto/scheduled-message';

export class ScheduledMessage {
  public readonly id: number;
  public readonly userId: string;
  public readonly senderName: string;
  public readonly patientId: number;
  public readonly patient: PatientDto;
  public readonly text: string;
  public readonly channel: ChannelType;
  public readonly sent: boolean;
  public readonly scheduledTime: Date;

  constructor(data: ScheduledMessageDto) {
    Object.assign(this, data);
  }
}
